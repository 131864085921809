export const EncodeValue = (value) => {
    return btoa(value)
}

export const generate = (text, publicKey) => {

    var pwd = text;
    var encrypted = publicKey.encrypt(pwd, "RSA-OAEP", {
        md: window.forge.md.sha256.create(),
        mgf1: window.forge.mgf1.create()
    });
    var base64 = window.forge.util.encode64(encrypted);
    return base64;
}

export const isNullOrEmpty = (str) => {
    if ((str == "") || (str == null) || (str == "null") || (str == undefined) || (str == "undefined")) {
        return true;
    }
    return false;
}

export const isNull = (str) => {
    if ((str == null) || (str == undefined)) {
        return true;
    }
    return false;
}

export const toCamelCase = (str) => {
    const regExp = /[-_]\w/ig;
    return str.replace(regExp, (match) => {
        return " " + match[1]
    });
}

export const toFirstCapital = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toAllCapital = (str) => {
    return str.toUpperCase();
}