import React, { useState, useEffect } from "react";
import { primaryButtonTheme, secondaryButtonTheme, fontMedium, fontRegular, fontBold, successMsgStyle, errorMsgStyle } from '../../utils/AppStyle';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { PDFUploadFinish } from "../../utils/NetworkUtil";
import { PopUp } from "../../utils/PopUp";
import { getPdfIconPath, getProcessingDesc, getProcessingTitle } from '../../Config';
import CollapsiblePanel from "../../utils/CollapsiblePanel";
import { btnUploadAnother, btnFinish, listOfFilesTitle, btnConfirm, btnUpload } from "../../utils/Strings";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { saveEvent, screen_names, setScreenName } from "../../utils/Analytics";

const PdfList = () => {
    let history = useHistory();

    let tableData = { "tableHeader": "Missing Dates", "colHeaders": ["From", "To"], "body": [] };

    //let accountInfo = JSON.parse(sessionStorage.getItem("account_info"));

    //console.log("accountInfo:"+JSON.stringify(accountInfo));

    /* let accountInfo = {

        "status": "Success",
        "msg": "You still need to upload this missing dates",
        "account_number": "XXXXXXXX542",
        "files": [
            {
                "file_name": "3_stmt.pdf",
                "start_date": "01-04-2021",
                "end_date": "28-05-2021"
            },
            {
                "file_name": "4_stmt.pdf",
                "start_date": "01-06-2021",
                "end_date": "30-07-2021"
            }
        ],
        "no_of_months_submitted": "3",
        "missing_dates": [{"start_date":"2021-02-01","end_date":"2021-02-28"},{"start_date":"2021-03-01","end_date":"2021-03-28"},{"start_date":"2021-04-01","end_date":"2021-04-28"},{"start_date":"2021-05-01","end_date":"2021-05-28"},{"start_date":"2021-06-01","end_date":"2021-06-28"},{"start_date":"2021-07-01","end_date":"2021-07-28"}],
        "show_finish": "false"
    } */


    const [collapse, setCollapse] = useState(true);
    const [showFileList, setShowFileList] = useState(true)
    const [showProcess, setShowProcess] = useState(false)
    //const [accountInfo, setAccountInfo] = useState({});

    const [missingDates, setMissingDates] = useState([])
    const [msg, setMsg] = useState("")
    const [showFinish, setShowFinish] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [bankStatementInfo, setBankStatementInfo] = useState([]);

    const [showPopup, setShowPopup] = useState(false);

    const [scName, setScName] = useState(screen_names.pdf_list);
    const [multiAccountSupport, setMultiAccountSupport] = useState(localStorage.getItem("multi_account_support_required"));

    let bankName = sessionStorage.getItem('bank_name');
    let startMonth = sessionStorage.getItem('start_month');
    let endMonth = sessionStorage.getItem('end_month');
    let confirmDesc = "Are you sure that you have uploaded all PDF statements of your " + ((multiAccountSupport == "1") ? "bank" : bankName) + " from " + startMonth + " to " + endMonth + " ? If not, please upload the remaining statements.";

    useEffect(() => {

        if (!isNullOrEmpty(sessionStorage.getItem("account_info"))) {
            let accountInfoSession = JSON.parse(sessionStorage.getItem("account_info"));
            //console.log("accountInfoSession:" + JSON.stringify(accountInfoSession));
            if (multiAccountSupport != "1") {
                if ((accountInfoSession != null)) { //&& (accountInfoSession.file_list.length > 0)) {
                    let bankAccInfo = [
                        {
                            "bank_name": accountInfoSession.bank_name
                        }
                    ];
                    if ((accountInfoSession.file_list != null) && (accountInfoSession.file_list.length > 0)) {
                        bankAccInfo[0]["bank_details"] = [{
                            "account_number": accountInfoSession.account_number,
                            "file_list": accountInfoSession.file_list
                        }]
                    }
                    setBankStatementInfo(bankAccInfo);
                }
                else {
                    setBankStatementInfo([]);
                }
            }
            else {
                setBankStatementInfo(accountInfoSession.bank_statement_info);
            }
            setMissingDates(accountInfoSession.missing_dates);
            setMsg(accountInfoSession.msg);
            setShowFinish(accountInfoSession.show_finish);
            setShowUpload(accountInfoSession.show_upload);
        }
        saveAnalyticsEvent("shown", "", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType, toggleType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }
            if (!isNullOrEmpty(toggleType)) {
                analyticsObj.type = toggleType
            }

            saveEvent(analyticsObj);
        }
    }

    const handleUploadAnother = () => {
        handlePopupClose();
        saveAnalyticsEvent('upload_another_clicked', "", "");
        setScreenName(scName);
        sessionStorage.setItem("show_finish", showFinish);
        sessionStorage.setItem("show_view_files", true);
        history.push("/pdfupload")
    }

    const handleSubmit = () => {

        saveAnalyticsEvent('finish_clicked', "", "");

        if ((showFinish == true) && (showUpload == true)) {
            setShowPopup(true);
        }
        else {
            PDFUploadFinish(history, pdfUploadFinishCb);
        }
    }

    const pdfUploadFinishCb = () => {
        setScreenName(scName);
        if (((!isNullOrEmpty(sessionStorage.getItem('user_redirection'))) && (sessionStorage.getItem('user_redirection') == '1'))) {
            sessionStorage.setItem('status', 'in-progress');
            sessionStorage.setItem('token_valid', false);
            sessionStorage.setItem('is_processing', '0');
            history.push("/txncomplete");
        }
        else {
            history.push("/process");
        }
    }

    const handlePopupClose = () => {
        setShowPopup(false);
    }

    const handleConfirm = () => {
        handlePopupClose();
        setShowProcess(true);
        setShowFileList(false);
        saveAnalyticsEvent('confirm_clicked', "", "");
        PDFUploadFinish(history, pdfUploadFinishCb);
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    {
                        (showFileList == true) ?
                            <div>
                                <h4 className="instructionTitle" style={fontMedium}>{listOfFilesTitle}</h4>
                                <div>
                                    <p style={(showFinish == true) ? successMsgStyle : errorMsgStyle} className="file-response-msg">{msg}</p>
                                    {
                                        (!(isNullOrEmpty(missingDates)) && (missingDates.length > 0) && (showFinish != true)) ?
                                            <div style={{ marginBottom: "10px" }}>
                                                {/* <h3 className="card-title" style={fontMedium}>{tableData.tableHeader}</h3> */}
                                                <div>
                                                    <table style={fontRegular}>
                                                        <thead>
                                                            <tr>
                                                                <th style={fontBold} className="table-title">{tableData.colHeaders[0]}</th>
                                                                <th style={fontBold} className="table-title">{tableData.colHeaders[1]}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {missingDates.map((item) => {
                                                                return (
                                                                    <tr>
                                                                        <td style={fontRegular}>{item.start_date}</td>
                                                                        <td style={fontRegular}>{item.end_date}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> :
                                            <></>
                                    }
                                </div>
                                <div className="stmt-upload-details-div" style={{ marginTop: '20px', maxHeight: "370px", overflow: "auto" }}>
                                    {
                                        ((bankStatementInfo != undefined) && (bankStatementInfo.length > 0)) ?
                                            bankStatementInfo.map((item, index) => {
                                                return (
                                                    <>

                                                        <div className={(showFinish == false) ? "info-div" : "success-div"}>

                                                            <div style={{ textAlign: "center" }}>

                                                                <p style={fontBold} className="account-number">{item.bank_name}</p>

                                                                {
                                                                    ((item.bank_details != undefined) && (item.bank_details.length > 0)) ?
                                                                        item.bank_details.map((item1, index1) => {
                                                                            return (
                                                                                <>
                                                                                    <hr style={{ marginTop: "5px" }} />
                                                                                    {
                                                                                        (!isNullOrEmpty(item1.account_number)) ?

                                                                                            <p style={fontBold} className="account-number"> A/c No : {item1.account_number}</p> : <></>
                                                                                    }

                                                                                    {(showFinish == true) ? <p style={fontRegular} className="file-info-msg"> Total Files Accepted : {item1.file_list.length}</p> : null}


                                                                                    <CollapsiblePanel show_title="View Files" hide_title="Hide Files" collapse={collapse}>

                                                                                        {
                                                                                            ((item1.file_list != undefined) && (item1.file_list.length > 0)) ?
                                                                                                item1.file_list.map((item2, index2) => {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            <hr style={{ margin: "0px" }} />
                                                                                                            <div style={{ padding: "7px", width: "100%" }}>
                                                                                                                <img src={getPdfIconPath()} style={{ verticalAlign: "middle", width: '20%' }} className="pdf-icon" />
                                                                                                                <div style={{ display: "inline-block", verticalAlign: "middle", width: '75%' }}>
                                                                                                                    <span className="file-name-title" style={fontRegular}>{item2.file_name}</span>
                                                                                                                    {
                                                                                                                        (!isNullOrEmpty(item2.start_date) && (!isNullOrEmpty(item2.end_date))) ?

                                                                                                                            <div style={fontRegular} className="file-date">
                                                                                                                                <span>{item2.start_date}</span>
                                                                                                                                <span> to </span>
                                                                                                                                <span>{item2.end_date}</span>
                                                                                                                            </div> : <></>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {/* {(index2 != item1.file_list.length - 1) ? <hr style={{ margin: "0px" }} /> : null} */}
                                                                                                        </div>)
                                                                                                }) : null
                                                                                        }


                                                                                    </CollapsiblePanel>

                                                                                    {/* {(index1 != item.bank_details.length - 1) ? <hr style={{ marginTop: "5px" }} /> : null} */}
                                                                                </>
                                                                            )
                                                                        }) : <></>
                                                                }


                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }) : <></>
                                    }
                                </div>

                                <br />
                                <br />
                                <div className='bottomDiv' style={{ marginTop: '50px', width: "100%", textAlign: "center" }}>
                                    {
                                        (showUpload == true) ?
                                            <button className={(showFinish == true) ? "full-error-button" : "full-primary-button"} style={(showFinish == true) ? secondaryButtonTheme : primaryButtonTheme} onClick={handleUploadAnother}>{btnUploadAnother}</button> : null
                                    }
                                    {
                                        (showFinish == true) ?

                                            <button className="full-primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnFinish}</button> : null}

                                </div>
                            </div> : null}
                    {
                        (showProcess == true) ?
                            <div style={{ textAlign: 'center' }}>
                                <Loader loaderStyle={getLoaderStyle()} />
                                <br /><br />
                                <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                                <div className="card-msg" style={fontMedium}>
                                    {getProcessingDesc()}
                                </div>
                            </div> : null}
                    {
                        (showPopup == true) ?
                            <PopUp
                                close={handlePopupClose}
                                onPrimaryBtnClick={handleConfirm}
                                onSecondaryBtnClick={handleUploadAnother}
                                primaryBtnText={btnConfirm}
                                secondaryBtnText={btnUpload}
                                bodyText={confirmDesc} /> : null}
                </div>
            </div>
        </div>
    );
};

export default withRouter(PdfList);