import React, { useEffect } from "react";
//import { loaderTheme, fontMedium } from '../utils/AppStyle';
import { isNullOrEmpty } from "../utils/AppUtil";
import { databaseRef } from '../utils/firebase';

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { getLoaderStyle, getTitle } from "../Config";
import { destinationPdfUpload, destinationNetbanking, destinationEmailStatement, destinationAccountAggregator } from "../utils/Strings";
import { Loader } from "../utils/Loader";

const Home = () => {
    let history = useHistory();
    let txnId = '';
    let destination = '';
    let source = '';
    let aaMobile = '';
    let aaVua = "";

    useEffect(() => {

        let reloaded = sessionStorage.getItem('reloaded');

        /* Taking the parameters from the url */
        let url_string = window.location.href;
        let url = new URL(url_string);
        let params = url.searchParams;
        if (!isNullOrEmpty(params)) {
            localStorage.setItem("sc_name", "invocation_screen");
            let token = params.get("gl", "");
            let institutionId = params.get("institution_id", "");
            let aaId = params.get("aa_id", "");
            let requestId = params.get('request_id', "")
            let showReport = params.get("shw_rpt", "0");
            let noOfMonths = params.get("no_of_months", "6");
            let startMonth = params.get("start_month", "July");
            let endMonth = params.get("end_month", "December");
            let bankName = params.get("bank_name", "");
            let themeId = params.get("theme_id", "");
            let errReportGen = params.get("err_report_gen", "0");
            let userRedirection = params.get("redirection", "0");
            let aaMobileEditable = params.get("aa_mobile_editable", "0");
            let analyticsEnabled = params.get("analytics_enabled", "0");
            let multiAccountSupport = params.get("multi_account_support_required", "0");
            let multiSelectionEnabled = params.get("multi_selection_enabled", "0");
            let compressedSelectionEnabled = params.get("compressed_selection_enabled", "0");
            let bankSelectionEnabled = params.get("bank_selection_enabled", "1");
            let maxAllowedFiles = params.get("max_allowed_files", "10");
            


            source = params.get('source', "0");
            aaMobile = params.get("mobile_num", "");
            aaVua = params.get("vua", "");
            destination = params.get("destination", destinationNetbanking);
            txnId = params.get("txn_id", "");

            sessionStorage.setItem('token_valid', true);
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('txn_id', txnId);
            sessionStorage.setItem('request_id', requestId);
            sessionStorage.setItem("shw_rpt", showReport);
            sessionStorage.setItem("destination", destination);
            sessionStorage.setItem("no_of_months", noOfMonths);
            sessionStorage.setItem("start_month", startMonth);
            sessionStorage.setItem("end_month", endMonth);
            sessionStorage.setItem("bank_name", bankName);
            sessionStorage.setItem("source", source);
            sessionStorage.setItem("aa_mobile_editable", aaMobileEditable);
            sessionStorage.setItem("user_redirection", userRedirection);
            localStorage.setItem("analytics_enabled", analyticsEnabled);
            localStorage.setItem("multi_account_support_required", multiAccountSupport);
            localStorage.setItem("multi_selection_enabled", multiSelectionEnabled);
            localStorage.setItem("compressed_selection_enabled", compressedSelectionEnabled);
            localStorage.setItem("bank_selection_enabled", bankSelectionEnabled);
            localStorage.setItem("max_allowed_files", maxAllowedFiles);

            sessionStorage.setItem("theme_id", themeId);
            sessionStorage.setItem("err_report_gen", errReportGen);

            if (!isNullOrEmpty(aaVua)) {
                let vua = aaVua.split("@");
                sessionStorage.setItem("aa_vua", vua[1]);
                sessionStorage.setItem("aa_mobile", vua[0]);
            }

            if (!isNullOrEmpty(aaMobile)) {
                sessionStorage.setItem("aa_mobile", aaMobile);
            }


            if (!isNullOrEmpty(institutionId) && (institutionId != '-1')) {
                sessionStorage.setItem("institution_id_given", "true");
                sessionStorage.setItem('institution_id', institutionId);
            }
            else {
                sessionStorage.setItem("institution_id_given", "false");
                sessionStorage.setItem('institution_id', "-1");
            }
            if (!isNullOrEmpty(aaId) && (aaId != '-1')) {
                sessionStorage.setItem("aa_id_given", "true");
                sessionStorage.setItem('aa_id', aaId);
            }
            else {
                sessionStorage.setItem("aa_id_given", "false");
                sessionStorage.setItem('aa_id', "-1");
            }
            if (reloaded !== "true") {
                sessionStorage.setItem('reloaded', 'true');
                window.location.reload();
            }
            else {
                //sessionStorage.setItem('reloaded', 'false');
                document.title = getTitle();
                // if (getAnalyticsCallbackRequired() == "true") {
                //     getFirebaseConfigValue("analytics_enabled", getFirebaseConfigValueCb);
                // }
                // else {
                    openNextScreen();
                //}
            }
        }
        else {
            destination = sessionStorage.getItem('destination');
            txnId = sessionStorage.getItem('txn_id');
            source = sessionStorage.getItem('source');
            aaVua = sessionStorage.getItem("aa_vua");
            aaMobile = sessionStorage.getItem("aa_mobile");
            openNextScreen();
        }

        if (!isNullOrEmpty(txnId)) {

            /* Getting the client returnurl from the firebase */
            let returnUrl = '';
            const clientRef = databaseRef.child(txnId);
            var returnUrlObj = clientRef.child("client_data");
            returnUrlObj.on('value', snap => {
                if (snap.val() != null)
                    returnUrl = snap.val().return_url;
                sessionStorage.setItem('return_url', returnUrl);
            });
        }

    }, []);

    // const getFirebaseConfigValueCb = (value) => {
    //     console.log("firebase config value:"+value);
    //     var paramValue = value;
    //     if (isNullOrEmpty(value)) {
    //         paramValue = "1";
    //     }
    //     localStorage.setItem("analytics_enabled", paramValue);
    //     openNextScreen();
    // }


    /* Redirecting to pdfupload page or netbanking page based on the destination parameter in the url */

    const openNextScreen = () => {
        localStorage.setItem("statement_list", "");
        sessionStorage.setItem("proper_redirection", "true");
        if (destination == destinationNetbanking) {
            sessionStorage.setItem('show_try_again', true);
            history.push("/netbankinghome");
        }
        else if (destination == destinationPdfUpload) {
            sessionStorage.setItem('show_try_again', false);
            sessionStorage.setItem("show_finish", false);
            sessionStorage.setItem("show_view_files", false);
            if (source == "1") {
                history.push("/pdfupload");
            }
            else {
                history.push("/stmtuploadhome");
            }
        }
        else if (destination == destinationEmailStatement) {
            sessionStorage.setItem('show_try_again', true);
            history.push("/emailstmthome");
        }
        else if (destination == destinationAccountAggregator) {
            sessionStorage.setItem('show_try_again', false);
            //let themeId = sessionStorage.getItem("theme_id");
            let aaMobileEditable = sessionStorage.getItem("aa_mobile_editable");
            if (!isNullOrEmpty(aaMobileEditable) && (aaMobileEditable == "2") && (!isNullOrEmpty(aaVua))) {
                history.push("/aaprocessing");
            }
            else if (!isNullOrEmpty(aaMobile)) {
                sessionStorage.setItem('aa_mobile', aaMobile);
                history.push("/aamobile");
            }
            else {
                history.push("/aahome");
            }
        }
        else {
            sessionStorage.setItem('show_try_again', true);
            history.push("/choicehome");
        }
    }

    return (
        <div>
            <div className="normal-card">
                {/* <Loader loaderStyle={getLoaderStyle()} /> */}
            </div>
        </div>
    );
};

export default withRouter(Home);