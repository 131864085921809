import { getAnalyticsCallbackRequired, getAnalyticsEnabled } from "../Config";
import { isNullOrEmpty } from "./AppUtil";
import { saveAnalyticsToApi } from "./NetworkUtil";
//import { analyticsRef } from "./firebase";

//screen names
export const screen_names = {
  nb_home: "net_banking_details_screen",
  captcha: "captcha_screen",
  otp: "otp_screen",
  question: "security_question_screen",
  account: "account_selection_screen",
  processing: "transition_screen",
  error: "error_screen",
  user_interaction_completed: "user_interaction_completed_screen",
  pdf_upload_home: "stmt_upload_details_screen",
  pdf_upload: "pdf_upload_screen",
  pdf_upload_loading: "pdf_upload_loading_screen",
  pdf_password: "pdf_password_screen",
  pdf_upload_error: "pdf_upload_error_screen",
  pdf_list: "pdf_list_screen",
  aa_home: "aa_details_screen",
  aa_mobile: "aa_mobile_screen",
  choice_home: "choice_details_screen"
}

export const field_names = {
  bank : "bank",
  username : "username",
  password : "password",
  captcha : "captcha",
  otp : "otp",
  question : "question",
  account : "bank_account",
  file : "file",
  password : "password",
  aa_mobile: "aa_mobile",
  aa_name: "aa_name",
  aa_id : "aa_id",
  destination: "destination"
}

export const setScreenName = (name) => {
  localStorage.setItem("sc_name", name);
}

export const saveEvent = (obj) => {

  var analyticsObj = obj;


  analyticsObj.bank_name = (!isNullOrEmpty(sessionStorage.getItem("bank_name"))) ? sessionStorage.getItem("bank_name") : "";
  analyticsObj.previous_screen_name = localStorage.getItem("sc_name");
  analyticsObj.session_id = sessionStorage.getItem("txn_id");
  analyticsObj.attempt_count = (!isNullOrEmpty(localStorage.getItem("attempt_count"))) ? localStorage.getItem("attempt_count") : "1";

  let analyticsStr = JSON.stringify(analyticsObj);

  if ((getAnalyticsCallbackRequired() == "true") && window.DigitapBs) {
    window.DigitapBs.logEvent(analyticsStr);
  }

  console.log("analyticsStr:" + analyticsStr);

  //analyticsRef.logEvent(obj.action, analyticsObj);

  let analyticsEnabled = localStorage.getItem("analytics_enabled");
  if (!isNullOrEmpty(analyticsEnabled) && (analyticsEnabled == "1")) {

    const data = {
      txn_id: sessionStorage.getItem("txn_id"),
      event: analyticsStr
    }

    saveAnalyticsToApi(data, saveAnalyticsToApiCb);
  }

}

const saveAnalyticsToApiCb = () => {

}

export const saveFieldErrorEvent = (screenName, fieldType, errMsg) => {
  if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
    let analyticsObj = {
      action: "field_error",
      screen_name: screenName,
      error_msg: errMsg,
      field_type: fieldType
    }
    saveEvent(analyticsObj);
  }
}

export const saveProcessingEvent = (screenName, title, desc) => {
  if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
    let analyticsObj = {
      action: "shown",
      screen_name: screenName,
      screen_title: title,
      screen_text: desc
    }

    saveEvent(analyticsObj);
  }
}