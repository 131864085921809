import PropTypes from "prop-types";
import React, { Component } from "react";
import "../radio.css";
import { fontBold, borderTheme, backgroundTheme } from "./AppStyle";
import { getScreenSecondaryTextColor } from "../Config";

const radioBackgroundTheme = {
  background: getScreenSecondaryTextColor()
}

const radioBorderTheme = {
  borderColor: getScreenSecondaryTextColor()
}

export class Radio1 extends Component {
  state = {};

  render() {
    const { selected, onChange, text, value, border } = this.props;
    return (
      <div
        style={((value == selected) && (border == "true")) ? radioBorderTheme : null}
        className={(border == "true") ? "modern-radio-container radio-container-border" : "modern-radio-container"}
        onClick={() => {
          onChange(value);
        }}
      >
        <div className="helper-text" style={fontBold}>{text}</div>
        <div
          style={(value == selected) ? radioBorderTheme : null} className={`radio-outer-circle ${value !== selected && "unselected"}`}
        >
          <div
            style={radioBackgroundTheme} className={`radio-inner-circle ${value !== selected &&
              "unselected-circle"}`}
          />
        </div>
      </div>
    );
  }
}

Radio1.propTypes = {
  text: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};
