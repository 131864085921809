import React, { useState, useEffect } from "react";
import { secondaryButtonTheme, primaryButtonTheme, fontRegular, fontBold, fontMedium, liStyle, errorMsgStyle } from '../../utils/AppStyle';
import { selectBankHint, selectedBankHint, bankErr, termsTitle, statusCodeUserCancelled, btnCancel, btnNext, emailTypeGmailText, emailTypeGmailId } from "../../utils/Strings";
import { getBankIconPath, getDropDownIconPath, getCloseIconPath, getGmailIconPath } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Terms from "../include/Terms";
import { Radio } from "../../utils/Radio"
import { getInstitutionList } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";

const EmailStatementHome = () => {

    let history = useHistory();
    const [institutionId, setInstitutionId] = useState(sessionStorage.getItem('institution_id'));
    const [institutionIdGiven, setInstitutionIdGiven] = useState(sessionStorage.getItem('institution_id_given'));
    const [bankList, setBankList] = useState([]);
    const [bankHint, setBankHint] = useState(selectBankHint);
    const [bankName, setBankName] = useState("primary bank");
    const [showTc, setShowTc] = useState(false);
    const [isBankError, setBankError] = useState(false);
    const [note, setNote] = useState("");

    useEffect(() => {

        if (institutionIdGiven == "true") {
            setBankHint(selectedBankHint);
        }
        else {
            setBankHint(selectBankHint);
        }


        /* Getting the bank list for dropdown */

        const institutionListCb = (response) => {
            let bankData = [];
            let resData = response.data.data;
            for (let i = 0; i < resData.length; i++) {
                bankData.push({
                    "id": resData[i].id,
                    "name": resData[i].name,
                    "username_text": resData[i].username_text,
                    "display_name": resData[i].display_name
                });
            }
            setBankList(bankData);

            bankData.findIndex((item) => {
                if (item.id == institutionId) {
                    setInstitutionId(institutionId);
                    setBankName(item.name);
                    setNote(item.display_name);
                    sessionStorage.setItem("bank_name", item.name);
                }
            })
        }

        getInstitutionList(history, institutionListCb);
    }, []);

    const handleBankSelect = (event) => {
        let institution_id = event.target.value;
        setInstitutionId(institution_id);
        if (institution_id != -1) {
            setBankError(false);
            bankList.findIndex((item) => {
                if (item.id == institution_id) {
                    sessionStorage.setItem('institution_id', institution_id);
                    sessionStorage.setItem("bank_name", item.name);
                    setBankName(item.name);
                    setNote(item.display_name);
                }
            })
        }
        else {
            setNote("");
        }
    }

    const showTcFunc = () => {
        setShowTc(true);
    }

    const hideTcFunc = () => {
        setShowTc(false);
    }

    const handleNext = () => {
        if (institutionId != -1) {
            history.push('/accesstoken')
        }
        else {
            setBankError(true);
        }
    }

    const handleCancel = () => {
        sessionStorage.setItem('status_code', statusCodeUserCancelled);
        sessionStorage.setItem('status', false);
        history.push("/txncomplete");
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    <div>
                        <div>
                            <div className="card-action-title" style={fontBold}>Email Statement</div>
                            <div style={{ paddingTop: '20px' }}>
                                <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                {
                                    (isBankError === true) ?
                                        <span className="homeTextFieldTitleErr" style={fontMedium}>{bankHint}</span> : <span className="homeTextFieldTitle" style={fontMedium}>{bankHint}</span>
                                }
                            </div>
                            <div className={(isBankError === true) ? "input-div-err-style" : "input-div-style"}>
                                <select className={(institutionIdGiven === "true") ? "input-disabled-style" : "input-style"} style={fontMedium} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                    <option value="-1">Select bank</option>
                                    {bankList.map((item) => {
                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                    })}
                                </select>
                                {
                                    (institutionIdGiven === "false") ?
                                        <div className="float-right-icon" style={{ top: '7px', right: '5px', pointerEvents: 'none' }}>
                                            <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                        </div>
                                        : null}
                            </div>
                            {
                                ((!isNullOrEmpty(note)) && (bankName != note)) ?
                                    <div>
                                        <span className="institution_note_heading" style={fontBold}>Note : </span>
                                        <span className="institution_note" style={fontBold}>{note}</span>
                                    </div>
                                    : null
                            }
                            {
                                (isBankError === true) ?
                                    <span className="error-msg" style={errorMsgStyle}>{bankErr}</span> : null
                            }
                        </div>

                        <div style={{ marginTop: '20px' }}>
                            <div>
                                <span className="email-type-title" style={fontMedium}>Choose a Mail account provider</span>
                            </div>
                            <div className="email-type-layout" >
                                <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                                    <Radio
                                        value={emailTypeGmailId}
                                        selected={emailTypeGmailId}
                                        text=""
                                    />
                                </div>
                                <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                                    <img className="email-type-icon" src={getGmailIconPath()} />
                                    <span className="email-type-text" style={fontBold}>{emailTypeGmailText}</span>
                                </div>

                            </div>
                        </div>

                        <div>
                            <div style={{ paddingTop: '20px' }}>
                                <h4 className="instructionTitle" style={fontMedium}>Instructions</h4>
                                <ul className="instructionText" style={fontRegular}>
                                    <li style={liStyle}>Login with the {emailTypeGmailText} account where you receive your {bankName} statements</li>
                                    <li style={liStyle}>Allow access to read your statement mails</li>
                                </ul>
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <span className="termsStyle" style={fontMedium}>By clicking Next you agree to our </span><a onClick={showTcFunc} className="tcStyle" style={fontMedium}>T & C</a>
                            </div>
                            <div style={{ marginTop: "20px" }}>
                                <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                <button className="primary-button" style={primaryButtonTheme} onClick={handleNext}>{btnNext}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    (showTc === true) ?
                        <div className='popup' style={fontRegular}>
                            <div className='popup_inner' style={fontRegular}>
                                <div className="popup_header" style={fontBold}>
                                    <img src={getCloseIconPath()} className="close-icon" onClick={hideTcFunc} alt="close" />
                                    <h5>{termsTitle}</h5>
                                </div>
                                <Terms />
                            </div>
                        </div> : null
                }
            </div>
        </div>
    );
};

export default withRouter(EmailStatementHome);