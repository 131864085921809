import React, { useState, useEffect } from "react";
import { secondaryButtonTheme, primaryButtonTheme, fontRegular, fontBold, fontMedium, liStyle, bottomDivStyle, errorMsgStyle, divBgStyle, inputStyle, popupStyle } from '../../utils/AppStyle';
import { selectBankHint, selectedBankHint, bankErr, termsTitle, statusCodeUserCancelled, btnCancel } from "../../utils/Strings";
import { getBankIconPath, getDropDownIconPath, getCloseIconPath, getUserActionBtnStyle, userActionBtnStyleText, homeInputStyleIcon, getHomeInputStyle, getHomePrimaryBtnText, getTCText, getBankLogo, getAnalyticsEnabled, getAnalyticsCallbackRequired } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import Terms from "../include/Terms";
import { getInstitutionList } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { PopUp } from "../../utils/PopUp";
import ReactHtmlParser from 'react-html-parser';
import { field_names, saveEvent, saveFieldErrorEvent, screen_names, setScreenName } from "../../utils/Analytics";


const StmtUploadHome = () => {

    let history = useHistory();
    const [institutionId, setInstitutionId] = useState(sessionStorage.getItem('institution_id'));
    const [institutionIdGiven, setInstitutionIdGiven] = useState(sessionStorage.getItem('institution_id_given'));
    const [bankList, setBankList] = useState([]);
    const [txnId, setTxnId] = useState(sessionStorage.getItem('txn_id'));
    const [bankHint, setBankHint] = useState(selectBankHint);
    const [bankName, setBankName] = useState("primary bank");
    const [bankCode, setBankCode] = useState("");
    const [showTc, setShowTc] = useState(false);
    const [isBankError, setBankError] = useState(false);
    const [noOfMonths, setNoOfMonths] = useState(sessionStorage.getItem('no_of_months'));
    const [startMonth, setStartMonth] = useState(sessionStorage.getItem('start_month'));
    const [endMonth, setEndMonth] = useState(sessionStorage.getItem('end_month'));
    const [note, setNote] = useState("");
    const [msgText, setMsgText] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [pdfApplicable, setPdfApplicable] = useState(false);

    const [scName, setScName] = useState(screen_names.pdf_upload_home);

    useEffect(() => {

        if (institutionIdGiven == "true") {
            setBankHint(selectedBankHint);
        }
        else {
            setBankHint(selectBankHint);
        }

        /* Getting the bank list for dropdown */

        const institutionListCb = (response) => {
            let bankData = [];
            let resData = response.data.data;
            for (let i = 0; i < resData.length; i++) {
                bankData.push({
                    "id": resData[i].id,
                    "name": resData[i].name,
                    "username_text": resData[i].username_text,
                    "display_name": resData[i].display_name,
                    "msg_text": resData[i].msg_text,
                    "is_pdf_applicable": resData[i].is_pdf_applicable,
                    "code": resData[i].code
                });
            }
            setBankList(bankData);

            bankData.findIndex((item) => {
                if (item.id == institutionId) {
                    setInstitutionId(institutionId);
                    setBankName(item.name);
                    setBankCode(item.code);
                    setNote(item.display_name);
                    setMsgText(item.msg_text);
                    setPdfApplicable(item.is_pdf_applicable);
                    sessionStorage.setItem("bank_name", item.name);
                }
            })

            saveAnalyticsEvent("shown", "");
        }

        getInstitutionList(history, institutionListCb);
    }, []);

    const handleBankSelect = (event) => {
        let institution_id = event.target.value;
        setInstitutionId(institution_id);
        if (institution_id != -1) {
            setBankError(false);
            bankList.findIndex((item) => {
                if (item.id == institution_id) {
                    sessionStorage.setItem('institution_id', institution_id);
                    sessionStorage.setItem("bank_name", item.name);
                    setBankName(item.name);
                    setBankCode(item.code);
                    setNote(item.display_name);
                    setMsgText(item.msg_text);
                    setPdfApplicable(item.is_pdf_applicable);
                }
            })
            saveAnalyticsEvent('field_selected', field_names.bank);
        }
        else {
            setNote("");
        }
    }

    const showTcFunc = () => {
        setShowTc(true);
    }

    const hideTcFunc = () => {
        setShowTc(false);
    }

    const handlePopupClose = () => {
        setShowPopup(false);
    }

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    const handleNext = () => {
        if (institutionId != -1) {
            if ((!isNullOrEmpty(msgText)) && (pdfApplicable == true)) {
                setShowPopup(true);
            }
            else {
                saveAnalyticsEvent('continue_clicked', "");
                setScreenName(scName);
                history.push('/pdfupload');
            }
        }
        else {
            setBankError(true);
            saveFieldErrorEvent(scName, field_names.bank, bankErr);
        }
    }

    const handleConfirm = () => {
        saveAnalyticsEvent('continue_clicked', "");
        localStorage.setItem("statement_list", "");
        history.push('/pdfupload');
    }

    const handleCancel = () => {
        saveAnalyticsEvent('cancel_clicked', "");
        sessionStorage.setItem('status_code', statusCodeUserCancelled);
        sessionStorage.setItem('status', false);
        setScreenName(scName);
        history.push("/txncomplete");
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    <div>
                        <div>
                            <div className="card-action-title" style={fontBold}>Upload Bank PDF Statement</div>
                            <div style={{ paddingTop: '10px' }}>
                                {
                                    (institutionIdGiven === "true") ? 
                                        <div style={divBgStyle} className="bank-name-div">
                                            <img className="bank-logo" src={getBankLogo(bankCode)} />
                                            <span className="bank-name" style={fontMedium}>{bankName}</span>
                                        </div>
                                        :
                                        (getHomeInputStyle() == homeInputStyleIcon) ?
                                            <div>
                                                <div>
                                                    <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                                    <span className="homeTextFieldTitle" style={(isBankError === true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                </div>
                                                <div className={(isBankError === true) ? "input-div-err-style" : "input-div-style"}>
                                                    <select className="input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                        <option value="-1">Select bank</option>
                                                        {bankList.map((item) => {
                                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                                        })}
                                                    </select>
                                                    <div className="float-right-icon">
                                                        <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                    </div>
                                                </div>
                                            </div> :
                                            <div>
                                                <div style={{marginBottom:'10px'}}>
                                                    <span className="homeTextFieldTitle" style={(isBankError === true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                </div>
                                                <div style={{ position: 'relative' }}>
                                                    <select className="action-input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                        <option value="-1">Select bank</option>
                                                        {bankList.map((item) => {
                                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                                        })}
                                                    </select>
                                                    <div className="float-right-icon">
                                                        <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                    </div>
                                                </div>
                                            </div>
                                }
                            </div>
                            {
                                ((!isNullOrEmpty(note)) && (bankName != note)) ?
                                    <div>
                                        <span className="institution_note_heading" style={fontBold}>Note : </span>
                                        <span className="institution_note" style={fontBold}>{note}</span>
                                    </div>
                                    : null
                            }
                            {
                                (isBankError === true) ?
                                    <span className="error-msg" style={errorMsgStyle}>{bankErr}</span> : null
                            }
                        </div>


                        <div>

                            <div style={{ paddingTop: '20px' }}>
                                <h4 className="instructionTitle" style={fontMedium}>Instructions</h4>
                                <ul className="instructionText" style={fontRegular}>
                                    <li style={liStyle}><span>Please upload the full months Statement of your {bankName} account </span>
                                        {
                                            (startMonth == endMonth) ? <span> for the month of {startMonth}</span> : <span> from {startMonth} to {endMonth}</span>
                                        }
                                        , Please ensure you have the same before proceeding</li>
                                    <li style={liStyle}>Upload the statements sent to you by the bank as it is</li>
                                    <li style={liStyle}>Statements should be in pdf format</li>
                                    <li style={liStyle}>Do NOT Create PDF statements from photos of Bank Statements</li>
                                </ul>
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <span className="termsStyle" style={fontMedium}>{getTCText()}</span><span onClick={showTcFunc} className="tcStyle" style={fontMedium}>T & C</span>
                            </div>
                            <br />
                            {
                                (getUserActionBtnStyle() == userActionBtnStyleText) ?
                                    <div className="bottomTextDiv" style={bottomDivStyle}>
                                        <button className='full-primary-button' style={primaryButtonTheme} onClick={handleNext}>{getHomePrimaryBtnText()}</button>
                                        <div className="cancel-btn-div">
                                            <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleNext}>{getHomePrimaryBtnText()}</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {
                    (showTc === true) ?
                        <div className='popup' style={fontRegular}>
                            <div className='popup_inner' style={popupStyle}>
                                <div className="popup_header" style={popupStyle}>
                                    <img src={getCloseIconPath()} className="close-icon" onClick={hideTcFunc} alt="close" />
                                    <h5 style={fontBold}>{termsTitle}</h5>
                                </div>
                                <Terms />
                            </div>
                        </div> : null
                }
                {
                    (showPopup == true) ?
                        <PopUp
                            onPrimaryBtnClick={handleConfirm}
                            primaryBtnText="Ok"
                            titleText="Note"
                            bodyText={ReactHtmlParser(msgText)} /> : null}
            </div>
        </div>
    );
};

export default withRouter(StmtUploadHome);