import React, { useState, useEffect } from "react";
import { primaryButtonTheme, secondaryButtonTheme, fontBold, fontMedium, fontRegular, loaderTheme, textColorTheme, errorMsgStyle, inputStyle } from '../../utils/AppStyle';
import { getBankIconPath, getDropDownIconPath, getErrorIconPath, getHomeInputStyle, getProcessingDesc, getUploadFileIconPath, homeInputStyleIcon, maxPdfSize, minPdfSize } from '../../Config';
import { btnUpload, btnTryAnother, processUploading, statusCodeNoFilePassword, destinationPdfUpload, btnFinish, btnConfirm, pdfSupportError, pdfSizeError, pdfChooseError, pdfSizeMaxError, pdfSizeMinError, pdfLocationError, selectBankHint, bankErr } from '../../utils/Strings';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { PDFUploadFinish, getInstitutionList, uploadUiStmt } from "../../utils/NetworkUtil";
import { PopUp } from "../../utils/PopUp";
import { storageRef } from "../../utils/firebase";
import FileBase64 from "../../utils/FileBase64";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle } from "../../Config";
import { isNullOrEmpty } from "../../utils/AppUtil";

const PdfUpload = ({ setShowCancel }) => {
    let history = useHistory();

    const [errorTitle, setErrorTitle] = useState("Error")
    const [errorMsg, setErrorMsg] = useState("Error")
    const [showUpload, setShowUpload] = useState(true)
    const [showProcess, setShowProcess] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showLoader, setShowLoader] = useState(false)
    const [selectedFile, setSelectedFile] = useState("")
    const [isFileError, setIsFileError] = useState(false)
    const [fileErrMsg, setFileErrMsg] = useState("")
    const [showFinish, setShowFinish] = useState(sessionStorage.getItem("show_finish"));
    const [showViewFiles, setShowViewFiles] = useState(sessionStorage.getItem("show_view_files"));
    const [file, setFile] = useState({})
    const [showPopup, setShowPopup] = useState(false)
    const [progressPercent, setShowProgressPercent] = useState("");

    const [institutionId, setInstitutionId] = useState(sessionStorage.getItem('institution_id'));
    const [bankName, setBankName] = useState(sessionStorage.getItem('bank_name'));
    const [bankList, setBankList] = useState([]);
    const [bankHint, setBankHint] = useState(selectBankHint);
    const [bankCode, setBankCode] = useState("");
    const [isBankError, setBankError] = useState(false);
    const [multiAccountSupport, setMultiAccountSupport] = useState(localStorage.getItem("multi_account_support_required"));

    let txnId = sessionStorage.getItem('txn_id');
    let noOfMonths = sessionStorage.getItem('no_of_months');
    let startMonth = sessionStorage.getItem('start_month');
    let endMonth = sessionStorage.getItem('end_month');

    let confirmDesc = "Are you sure that you have uploaded all PDF statements of your " + ((multiAccountSupport == "1") ? "bank" : bankName) + " from " + startMonth + " to " + endMonth + " ? If not, please upload the remaining statements.";

    useEffect(() => {
        setShowCancel("true");
        const institutionListCb = (response) => {
            let bankData = [];
            let resData = response.data.data;
            for (let i = 0; i < resData.length; i++) {
                bankData.push({
                    "id": resData[i].id,
                    "name": resData[i].name,
                    "username_text": resData[i].username_text,
                    "display_name": resData[i].display_name,
                    "msg_text": resData[i].msg_text,
                    "is_pdf_applicable": resData[i].is_pdf_applicable,
                    "code": resData[i].code
                });
            }
            setBankList(bankData);

            bankData.findIndex((item) => {
                if (item.id == institutionId) {
                    setInstitutionId(institutionId);
                    setBankName(item.name);
                    setBankCode(item.code);
                    sessionStorage.setItem("bank_name", item.name);
                }
            })
        }

        getInstitutionList(history, institutionListCb);
    }, []);
    const handleBankSelect = (event) => {
        let institution_id = event.target.value;
        setInstitutionId(institution_id);
        if (institution_id != -1) {
            setBankError(false);
            bankList.findIndex((item) => {
                if (item.id == institution_id) {
                    sessionStorage.setItem('institution_id', institution_id);
                    sessionStorage.setItem("bank_name", item.name);
                    setBankName(item.name);
                    setBankCode(item.code);
                    setInstitutionId(institution_id);
                }
            })
        }
    }

    const handleFileChange = (file) => {

        setIsFileError(false);
        setShowLoader(false);
        if (file != null) {
            if (file.type != "application/pdf") {
                setIsFileError(true);
                setFileErrMsg(pdfSupportError);
                setSelectedFile("");
                setFile({});
                //document.getElementById("browseBtn").value = "";
            }
            else if (file.size > maxPdfSize) {
                setIsFileError(true);
                setFileErrMsg(pdfSizeMaxError);
                setSelectedFile("");
                setFile({});
                //document.getElementById("browseBtn").value = "";
            }
            else if (file.size < minPdfSize) {
                setIsFileError(true);
                setFileErrMsg(pdfSizeMinError);
                setSelectedFile("");
                setFile({});
                //document.getElementById("browseBtn").value = "";
            }
            else {
                setSelectedFile(file.name);
                sessionStorage.setItem("file_name", file.name);
                setFile(file);
            }
        }
        else {
            setFile({})
            setSelectedFile("");
        }
    };

    const handleFileError = (error) => {
        setIsFileError(true);
        setFileErrMsg(error);
        setSelectedFile("");
        setFile({});
    }

    const showChooseFileLoader = () => {
        setShowLoader(true);
    }

    const chooseFile = () => {

        if (showLoader) {
            return;
        }

        var nodeList = document.getElementsByTagName("input");
        for (var item in nodeList) {
            if (nodeList[item].getAttribute("type") == "file") {
                nodeList[item].click();
                break;
            }
        };
    }

    const submitFile = () => {

        if (showLoader) {
            return;
        }

        if (institutionId == "-1") {
            setBankError(true);
            return;
        }

        if ((Object.keys(file).length === 0) && (file.constructor === Object)) {
            setIsFileError(true);
            setFileErrMsg(pdfChooseError);
            return;
        }

        setShowUpload(false);
        setShowProcess(true);

        const uploadUiStmtCb = (res) => {
            setFile({});
            sessionStorage.setItem("statement_id", res.data.statement_id);
            if (res.data.status == "success") {
                sessionStorage.setItem("account_info", JSON.stringify(res.data));
                history.push("/pdflist");
            }
            else {
                if (res.data.code == statusCodeNoFilePassword) {
                    history.push('/pdfpassword');
                }
                else {
                    setErrorTitle(res.data.title);
                    setErrorMsg(res.data.msg);
                    setShowProcess(false);
                    setShowError(true);
                }
            }
        }

        const uploadToApi = () => {

            let formData = new FormData();


            formData.append('file', file);
            formData.append('file_password_b16', '');
            formData.append('execution_type', 'file_submit');
            formData.append('txn_id', txnId);
            formData.append('destination', destinationPdfUpload);
            formData.append('institution_id', institutionId);
            /* 
                    for (var key of formData.entries()) {
                        console.log(key[0] + ', ' + key[1]);
                    }
             */

            uploadUiStmt(formData, history, uploadUiStmtCb);
        }

        const fileToBase64 = () => {
            var reader = new FileReader();
            reader.onload = () => {
                uploadToFirebase(reader.result);
            }

            reader.onerror = function (event) {
                console.log("reader.eror: " + reader.error);
                showError();
            };
            reader.readAsArrayBuffer(file);
        }

        const showError = () => {
            setErrorTitle("Error");
            setErrorMsg(pdfLocationError);
            setShowProcess(false);
            setShowError(true);
        }


        const uploadToFirebase = (input) => {

            let firebaseUrl = `statements/${txnId}/${file.name}`;

            const data = {
                txn_id: txnId,
                file_password_b16: '',
                file_firebase_url: firebaseUrl,
                execution_type: "file_submit",
                destination: destinationPdfUpload,
                institution_id: institutionId
            }

            var uploadTask = storageRef.ref("/" + firebaseUrl).putString(input, 'data_url')

            uploadTask.on("state_changed",
                (snapshot) => {
                    var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setShowProgressPercent(progress);
                }, (error) => {
                    setErrorTitle(error.code);
                    setErrorMsg(error.message);
                    setShowProcess(false);
                    setShowError(true);
                }, () => {
                    uploadUiStmt(data, history, uploadUiStmtCb);
                }
            )
        }
        //fileToByteArray(file);
        //fileToBlob();
        //uploadToApi();
        uploadToFirebase(file.base64);
        //fileToBase64();
    }

    const handleTryAgain = () => {
        setSelectedFile("")
        setShowUpload(true)
        setShowError(false)
        setShowProcess(false)
    }

    const handleFinish = () => {
        setShowPopup(true);
    }

    const handlePopupClose = () => {
        setShowPopup(false);
    }

    const handleConfirm = () => {
        handlePopupClose();
        setShowUpload(false);
        setShowError(false);
        PDFUploadFinish(history, pdfUploadFinishCb);
    }

    const pdfUploadFinishCb = () => {
        history.push("/process");
    }

    const handleUploadAnother = () => {
        handlePopupClose();
    }

    const handleViewFiles = () => {
        history.push("/pdflist");
    }

    return (
        <div>
            <div className="normal-card">
                <div>
                    {
                        (showUpload == true) ?
                            <div>
                                {
                                    (multiAccountSupport == "1") ?
                                        <div style={{ textAlign: 'left' }}>
                                            <div className="card-action-title" style={fontBold}>Upload Bank PDF Statement</div>
                                            <div style={{ paddingTop: '10px' }}>
                                                {
                                                    (getHomeInputStyle() == homeInputStyleIcon) ?
                                                        <div>
                                                            <div>
                                                                <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                                                <span className="homeTextFieldTitle" style={(isBankError === true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                            </div>
                                                            <div className={(isBankError === true) ? "input-div-err-style" : "input-div-style"}>
                                                                <select className="input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                                    <option value="-1">Select bank</option>
                                                                    {bankList.map((item) => {
                                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                                    })}
                                                                </select>
                                                                <div className="float-right-icon">
                                                                    <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                                </div>
                                                            </div>
                                                        </div> :
                                                        <div>
                                                            <div style={{ marginBottom: '10px' }}>
                                                                <span className="homeTextFieldTitle" style={(isBankError === true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                            </div>
                                                            <div style={{ position: 'relative' }}>
                                                                <select className="action-input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                                    <option value="-1">Select bank</option>
                                                                    {bankList.map((item) => {
                                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                                    })}
                                                                </select>
                                                                <div className="float-right-icon">
                                                                    <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                                </div>
                                                            </div>
                                                        </div>}
                                            </div>
                                            {
                                                (isBankError === true) ?
                                                    <span className="error-msg" style={errorMsgStyle}>{bankErr}</span> : null
                                            }
                                            <br />
                                            <br />
                                        </div>
                                        : <></>}
                                {(showViewFiles == "true") ?
                                    <u><span className="view-files-text" style={textColorTheme} onClick={handleViewFiles}>Uploaded Files</span></u> : null}
                                <div style={{ clear: "both" }}></div>
                                <div style={{ marginTop: '20px' }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <img src={getUploadFileIconPath()} style={{ cursor: 'pointer' }} className="respImgStyle" onClick={chooseFile} />
                                        {
                                            (showLoader === true) ?

                                                <div style={{ marginBottom: "10px" }}>
                                                    <div className="sc-bdVaJa bViQzk" style={loaderTheme}></div>
                                                    <div className="sc-bwzfXH bLLmrZ" style={loaderTheme}></div>
                                                    <div className="sc-htpNat ebGjrP" style={loaderTheme}></div>
                                                </div> :

                                                (isFileError === true) ?
                                                    <p className="error-msg" style={errorMsgStyle}>{fileErrMsg}</p> : <p className="instructionText" style={fontMedium}>{selectedFile}</p>

                                        }
                                        <h4 className="instructionTitle" style={fontMedium}>Upload Bank PDF Statements</h4>
                                        <p className="instructionText" style={fontRegular}>Upload PDF statements of {bankName} from {startMonth} to {endMonth}</p>
                                        <div id="uploadBlock" style={{ display: "none" }}>
                                            <FileBase64
                                                id="browseBtn"
                                                style={{ display: 'none' }}
                                                multiple={false}
                                                onSelect={showChooseFileLoader}
                                                onDone={handleFileChange.bind(this)}
                                            />
                                            {/* <input id='browseBtn' type="file" style={{ display: 'none' }} className="form-control" name="file" accept="application/pdf" onChange={handleFileChange} />
                                        */} </div>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <div className='bottomDiv' style={{ marginTop: '50px' }}>
                                        {
                                            (showFinish == "true") ? <button className='full-error-button' style={secondaryButtonTheme} onClick={handleFinish}>{btnFinish}</button> : null
                                        }
                                        <button className="full-primary-button" style={primaryButtonTheme} onClick={submitFile}>{btnUpload}</button>
                                    </div>
                                </div>
                            </div> : null}

                    {
                        (showProcess == true) ?
                            <div>
                                <Loader loaderStyle={getLoaderStyle()} />
                                <br /><br />
                                <div className="card-title" style={fontMedium}>{processUploading}  ({progressPercent}%)</div><br />
                                <div className="card-msg" style={fontRegular}>
                                    {getProcessingDesc()}
                                </div>
                            </div> : null}

                    {
                        (showError == true) ?
                            <div>
                                <div style={{ marginTop: '30px' }}>
                                    <img src={getErrorIconPath()} className="respImgStyle" />
                                    <br /><br />
                                    <div className="card-title" style={fontMedium}>{errorTitle}</div><br />
                                    <div className="card-msg" style={fontRegular}>
                                        {errorMsg}
                                    </div>
                                </div>
                                <div className='bottomDiv' style={{ marginTop: '50px' }}>
                                    <button className="full-primary-button" style={primaryButtonTheme} onClick={handleTryAgain}>{btnTryAnother}</button>
                                </div>
                            </div> : null}

                    {
                        (showPopup == true) ?
                            <PopUp
                                close={handlePopupClose}
                                onPrimaryBtnClick={handleConfirm}
                                onSecondaryBtnClick={handleUploadAnother}
                                primaryBtnText={btnConfirm}
                                secondaryBtnText={btnUpload}
                                bodyText={confirmDesc} /> : null}
                </div>
            </div>
        </div>
    );
};

export default withRouter(PdfUpload);