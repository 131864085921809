import React from "react";

const Terms = () => {
    return (
        <div className="popup_content">

            <strong>What is an Account Aggregator (or AA)?</strong>
            <p>An Account Aggregator (or AA) entity is authorized by the RBI to safely fetch your banking data without sharing your account login.</p>

            <strong>Why AA?</strong>
            <p>Share your bank statements with an Account Aggregator for</p>
            <ul style={{paddingLeft:"40px"}}>
                <li> Faster - Multiple bank accounts can be linked using a single AA handle</li>
                <li> Secure - Control what data to share and for what duration</li>
                <li> Hassle Free - No Multiple logins</li>
            </ul>
            <strong>How can I register with an AA?</strong>
            <ul style={{paddingLeft:"40px"}}>
                <li> Step1 : Choose one of the available options of AA</li>
                <li> Step2 : Go to the AA's website and click on register</li>
                <li> Step3 : Follow the steps and link your desired accounts with your preferred Account Aggregator</li>
            </ul>
        </div>
    );
};

export default Terms;