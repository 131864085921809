import React, { useState, useEffect } from "react";
import { getAAErrorSecondaryBtnText, getAnalyticsEnabled, getAnalyticsCallbackRequired, getErrorIconPath, getErrorPrimaryBtnText, getErrorSecondaryBtnText, getUserActionBtnStyle } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontMedium, fontRegular, fontBold, bottomDivStyle } from "../../utils/AppStyle";
import { errMsg1, errMsg2, errMsg3, statusCodeTxnAbort, destinationPdfUpload, internalErrorTitle, networkErrorCode, internalErrorCode, destinationAccountAggregator, errMsg4 } from "../../utils/Strings";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { screen_names, saveEvent, setScreenName } from "../../utils/Analytics";

const ErrorComponent = ({ setShowCancel }) => {
    let history = useHistory();

    let errorTitle1 = sessionStorage.getItem('response_title');
    let errorMsg1 = sessionStorage.getItem('response_msg');

    let tokenValid = sessionStorage.getItem('token_valid');

    let showTable = sessionStorage.getItem('show_table');

    let tableData = JSON.parse(sessionStorage.getItem("table_data")); //{"tableHeader":"Missing Dates", "colHeaders":["From","To"], "body":[["2020-05-06","2020-05-07"],["2020-05-08","2020-05-09"],["2020-05-10","2020-05-11"],["2020-05-12","2020-05-13"],["2020-05-10","2020-05-11"],["2020-05-12","2020-05-13"],["2020-05-10","2020-05-11"],["2020-05-12","2020-05-13"]]};

    const [showButtons, setShowButtons] = useState(true);

    const [destination, setDestination] = useState(sessionStorage.getItem('destination'));

    const [showTryAgain, setShowTryAgain] = useState(sessionStorage.getItem('show_try_again'));

    const [errorMsg, setErrorMsg] = useState(errorMsg1);

    const [errorTitle, setErrorTitle] = useState(errorTitle1);

    const [errorCode, setErrorCode] = useState(sessionStorage.getItem('response_code'));

    const [btnText, setBtnText] = useState(getErrorSecondaryBtnText());

    const [scName, setScName] = useState(screen_names.error);

    useEffect(() => {

        setShowCancel("false");

        if (tokenValid === "false") {
            setShowTryAgain("false");
        }

        saveAnalyticsEvent("shown");

        let url_string = window.location.href;
        let url = new URL(url_string);
        let params = url.searchParams;
        if (!isNullOrEmpty(params)) {
            setErrorTitle(internalErrorTitle);
            setShowButtons(false);
            let msgId = params.get("msg_id", "");
            if (msgId == 1) {
                setErrorMsg(errMsg1);
            }
            else if (msgId == 2) {
                setErrorMsg(errMsg2);
            }
            else if (msgId == 3) {
                setErrorMsg(errMsg3);
            }
            else {
                setErrorMsg(errMsg4);
            }
        }
        if (destination == destinationAccountAggregator) {
            setBtnText(getAAErrorSecondaryBtnText());
        }
    }, []);

    const saveAnalyticsEvent = (action) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName,
                error_title: errorTitle1,
                error_msg: errorMsg1,
                try_again_CTA_enabled: showTryAgain
            }

            saveEvent(analyticsObj);
        }
    }

    const handleRetry = () => {
        saveAnalyticsEvent("try_again_clicked");
        let attemptCount = (!isNullOrEmpty(localStorage.getItem("attempt_count"))) ? localStorage.getItem("attempt_count") : "1";
        localStorage.setItem("attempt_count", parseInt(attemptCount) + 1);
        setScreenName(scName);
        history.push("/")
    }

    const handleTakeMeBack = () => {
        saveAnalyticsEvent("try_other_methods_clicked");

        if ((destination == destinationPdfUpload) || (errorCode == networkErrorCode) || (errorCode == internalErrorCode)) {
            sessionStorage.setItem('status_code', errorCode);
        }
        else {
            sessionStorage.setItem('status_code', statusCodeTxnAbort);
        }
        sessionStorage.setItem('status', false);

        setScreenName(scName);
        history.push("/txncomplete");
    }
    return (
        <div>
            <div className="normal-card">
                <div style={{ marginTop: '30px' }}>
                    <img src={getErrorIconPath()} className="respImgStyle" alt="error" />
                    <br /><br />
                    <div className="card-title" style={fontBold}>{errorTitle}</div><br />
                    <div className="card-msg" style={fontRegular}>
                        {errorMsg}
                    </div>
                    <br />
                    {
                        (showTable == "true") ?
                            <div>
                                <h3 className="card-title" style={fontMedium}>{tableData.tableHeader}</h3>
                                <div style={{ maxHeight: "180px", overflow: "auto" }}>
                                    <table style={fontRegular}>
                                        <thead>
                                            <tr>
                                                <th className="card-title">{tableData.colHeaders[0]}</th>
                                                <th className="card-title">{tableData.colHeaders[1]}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.body.map((item) => {
                                                return (
                                                    <tr>
                                                        <td>{item[0]}</td>
                                                        <td>{item[1]}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            : null
                    }
                    <br /><br /><br /><br />
                    {
                        (showButtons === true) ?
                            (getUserActionBtnStyle() == 'text') ?
                                <div className="bottomTextDiv" style={bottomDivStyle}>
                                    {
                                        (showTryAgain === "true") ? <>
                                            <button className='full-primary-button' style={primaryButtonTheme} onClick={handleRetry}>{getErrorPrimaryBtnText()}</button>
                                            <div className="cancel-btn-div">
                                                <span className="cancel-btn-text" style={fontMedium} onClick={handleTakeMeBack}>{btnText}</span>
                                            </div>
                                        </> :
                                            <button className='full-primary-button' style={primaryButtonTheme} onClick={handleTakeMeBack}>{btnText}</button>
                                    }
                                </div>
                                :
                                <div className='bottomDiv'>
                                    <button className={(showTryAgain === "true") ? "full-error-button" : "full-primary-button"} style={(showTryAgain === "true") ? secondaryButtonTheme : primaryButtonTheme} onClick={handleTakeMeBack}>{btnText}</button>
                                    {
                                        (showTryAgain === "true") ? <button className='full-primary-button' style={primaryButtonTheme} onClick={handleRetry}>{getErrorPrimaryBtnText()}</button> : null
                                    }
                                </div> :
                            null
                    }
                </div>
            </div>
        </div>
    );
};

export default withRouter(ErrorComponent);
