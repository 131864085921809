import { getPrimaryBtnBgColor, getFontBold, getFontMedium, getFontRegular, getPrimaryBtnTextColor, getLoaderColor1, getInfoTitleTextColor, getBulletIconPath, getSecondaryBtnBorderColor, getSecondaryBtnBgColor, getSecondaryBtnTextColor, getScreenBgColor, getScreenPrimaryTextColor, getScreenSecondaryTextColor, getInputFieldBgColor, getInputFieldTextColor, getInputFieldBorderColor, getHeaderRequired, getPoweredByRequired, getDivBgColor, getBtnHeight, getInputFieldHeight, getInputFieldFontSize, getBtnFontSize } from '../Config';

export const contentStyles = {
  white: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  black: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  topBarHeight: 30,
  footerMenuHeight: 50
};

export const appBody = {
  backgroundColor: getScreenBgColor(),
  minHeight: "100vh",
  position: "relative"
}

export const hideContent = { display: 'none' };
export const dispContent = { display: 'block' };

export const eyeOffStyle = { display: 'none', color: getInputFieldTextColor() };
export const eyeOnStyle = { display: 'block', color: getInputFieldTextColor() };

export const centerContent = {
  paddingTop: (getHeaderRequired() == 'true') ? '70px' : '20px',
  paddingRight: '20px',
  paddingBottom: (getPoweredByRequired() == 'true') ? '30px' : '10px',
  paddingLeft: '20px',
  alignItems: 'center'
}

export const bottomDivStyle = {
  bottom: (getPoweredByRequired() == 'true') ? '20px' : '5px',
  textAlign: 'center'
}

export const divBgStyle = {
  backgroundColor: getDivBgColor()
}

export const inputStyle = {
  // '::placeholder': {
  //   color: getInputFieldTextColor(),
  // },
  backgroundColor: getInputFieldBgColor(),
  color: getInputFieldTextColor(),
  borderColor: getInputFieldBorderColor(),
  fontFamily: getFontMedium(),
  height: getInputFieldHeight(),
  fontSize: getInputFieldFontSize()
}

export const otpInputStyle = {
  color: getInputFieldTextColor(),
  borderColor: getInputFieldBorderColor(),
  fontFamily: getFontMedium()
}

export const errorMsgStyle = {
  color: '#de0808',
  fontFamily: getFontMedium(),
  fontSize: '12px'
}

export const successMsgStyle = {
  color: '#639665',
  fontFamily: getFontMedium(),
  fontSize: '12px'
}

export const loaderTheme = {
  background: getLoaderColor1()
}

export const backgroundTheme = {
  background: getPrimaryBtnBgColor()
}

export const borderTheme = {
  borderColor: getPrimaryBtnBgColor(),
}

export const textColorBlack = {
  color: getPrimaryBtnTextColor()
}

export const textColorTheme = {
  color: getInfoTitleTextColor(),
  fontFamily: getFontBold()
}

export const secondaryButtonTheme = {
  borderColor: getSecondaryBtnBorderColor(),
  background: getSecondaryBtnBgColor(),
  color: getSecondaryBtnTextColor(),
  fontFamily: getFontBold(),
  height: getBtnHeight(),
  fontSize: getBtnFontSize()
}

export const primaryButtonTheme = {
  background: getPrimaryBtnBgColor(),
  borderColor: getPrimaryBtnBgColor(),
  color: getPrimaryBtnTextColor(),
  fontFamily: getFontBold(),
  height: getBtnHeight(),
  fontSize: getBtnFontSize()
}

export const smallButtonTheme = {
  background: getPrimaryBtnBgColor(),
  borderColor: getPrimaryBtnBgColor(),
  color: getPrimaryBtnTextColor(),
  fontFamily: getFontBold()
}

export const fontBold = {
  fontFamily: getFontBold(),
  color: getScreenPrimaryTextColor()
}

export const fontMedium = {
  fontFamily: getFontMedium(),
  color: getScreenPrimaryTextColor()
}

export const fontRegular = {
  fontFamily: getFontRegular(),
  color: getScreenSecondaryTextColor()
}

export const liStyle = {
  margin: '10px',
  padding: '0px 0 0px 24px',
  listStyle: 'none',
  backgroundImage: "url(" + getBulletIconPath() + ")",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left center',
  backgroundSize: '10px',
  fontSize: '14px',
  color: getScreenSecondaryTextColor(),
  fontFamily: getFontRegular()
}

export const lineStyle = {
  backgroundColor: getScreenSecondaryTextColor(),
  height: '0.1px',
  border: 'none',
  margin: '10px 0',
};

export const popupStyle = {
  backgroundColor: getScreenBgColor()
}
