import React, { useEffect } from "react";
import { destinationEmailStatement } from '../../utils/Strings';
import { databaseRef } from '../../utils/firebase';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { revokeAccess } from "../../utils/EmailUtil";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle } from "../../Config";

const RedirectToClient = ({ setShowCancel }) => {
  let history = useHistory();
  const returnUrl = sessionStorage.getItem('return_url');
  const txnId = sessionStorage.getItem('txn_id');
  const requestId = sessionStorage.getItem('request_id');
  const status = "false";

  const getFormattedReturnUrl = (returnUrl) => {
    if (!isNullOrEmpty(returnUrl)) {

      var count = (returnUrl.match(/%s/g) || []).length;

      if (count === 4) {
        returnUrl = returnUrl.replace("%s", requestId);
        returnUrl = returnUrl.replace("%s", txnId);
        returnUrl = returnUrl.replace("%s", status);
        returnUrl = returnUrl.replace("%s", '1'); //isProcessing
      }
      else if (count === 3) {
        returnUrl = returnUrl.replace("%s", requestId);
        returnUrl = returnUrl.replace("%s", txnId);
        returnUrl = returnUrl.replace("%s", status);
      }
      else if (count === 2) {
        returnUrl = returnUrl.replace("%s", txnId);
        returnUrl = returnUrl.replace("%s", status);
      }
      return returnUrl;
    }

    return null;
  }

  const exitFromFlow = () => {
    let destination = sessionStorage.getItem("destination");
    let accesstoken = sessionStorage.getItem("access_token");
    if ((destination == destinationEmailStatement) && (!isNullOrEmpty(accesstoken))) {
      revokeAccess(accesstoken, exit);
    }
    else {
      exit();
    }
  }

  const exit = () => {
    /* Checking Android JSInterface method present or not */
    if (window.DigitapBs) {
      window.DigitapBs.onFinish(requestId, txnId, status);
    }
    /* Checking iOS Message handler present or not */
    else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.DigitapBs) {
      window.webkit.messageHandlers.DigitapBs.postMessage({
        "request_id": requestId,
        "txn_id": txnId,
        "status": status
      });
    }
    else {
      /* Checking returnUrl fetched already from Firebase */
      if (!isNullOrEmpty(returnUrl)) {
        let url = getFormattedReturnUrl(returnUrl);
        if (url != null) {
          window.location.href = url;      //Redirecting to the return url page
          sessionStorage.clear()
        }
        else {
          history.push("/wrongurl");      //Redirecting to PageNotFound page
        }
      }
      else {
        /* Getting returnUrl value from Firebase */
        var clientRef = databaseRef.child(txnId);
        var returnUrlObj = clientRef.child("client_data");
        var rUrl = "";
        returnUrlObj.on('value', snap => {
          if (snap.val() != null)
            rUrl = snap.val().return_url;

          sessionStorage.setItem('return_url', rUrl);

          let url = getFormattedReturnUrl(rUrl);
          if (url != null) {
            window.location.href = url;     //Redirecting to the return url page
          }
          else {
            history.push("/wrongurl");   //Redirecting to PageNotFound page
          }
        });
      }
    }
  }

  useEffect(() => {
    setShowCancel("false");
    exitFromFlow();
  }, []);
  return (
    <div>
      <div className="normal-card">
        <Loader loaderStyle={getLoaderStyle()} />
      </div>
    </div>
  );
};

export default withRouter(RedirectToClient);
