import React, { useState, useEffect } from "react";

import Terms from "../include/Terms";
import { Radio } from "../../utils/Radio";
import AAInstructions from "./AAInstructions";
//import { GAEvent } from '../../utils/ganalytcs';
import { secondaryButtonTheme, primaryButtonTheme, fontRegular, fontBold, fontMedium, hideContent, dispContent, errorMsgStyle, inputStyle, popupStyle } from '../../utils/AppStyle';
import { isNullOrEmpty } from "../../utils/AppUtil";
import { getAAList, initiateAAConsent } from "../../utils/NetworkUtil";

import { getBankIconPath, getDropDownIconPath, getAaUpiIdIconPath, getCloseIconPath, getProcessingDesc, getHomePrimaryBtnText, getProcessingTitle, getTCText } from '../../Config';

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { aaUpiIdErr, aaUpiIdHint, aaUpiIdHint1, aaUpiIdHint1NewUser, aaUpiIdHintNewUser, selectAAHint, selectedAAHint, aaErr, termsTitle, aaInfoTitle, statusCodeUserCancelled, btnCancel, btnYes, btnNo } from "../../utils/Strings";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { field_names, saveEvent, saveFieldErrorEvent, screen_names, setScreenName } from "../../utils/Analytics";

const AAHome = ({ setShowCancel }) => {
    let history = useHistory();
    const [aaUpiIdText, setAaUpiIdText] = useState(aaUpiIdHint);
    const [aaUpiIdErrMsg, setAaUpiIdErrMsg] = useState(aaUpiIdErr);
    const [aaHint, setAAHint] = useState(selectAAHint);
    const [showTc, setShowTc] = useState(false);
    const [showAAInfo, setShowAAInfo] = useState(false);
    const [aaUpiId, setAaUpiId] = useState('');
    const [isAaUpiIdError, setAaUpiIdError] = useState(false);
    const [aaIdDivDisp, setAAIdDivDisp] = useState(hideContent);
    const [isAAError, setAAError] = useState(false);
    const [aaId, setAAId] = useState(-1);
    const [aaIdGiven, setAAIdGiven] = useState(sessionStorage.getItem('aa_id_given'));
    const [aaList, setAAList] = useState([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [aaName, setAAName] = useState("");
    const [aaHandle, setAAHandle] = useState("");
    const [isAAUser, setAAUser] = useState("no");
    const [scName, setScName] = useState(screen_names.aa_home);

    useEffect(() => {
        setShowCancel("false");
        let txnId = '';
        let token = '';
        let aaId = '';
        token = sessionStorage.getItem('token');
        txnId = sessionStorage.getItem('txn_id');
        aaId = sessionStorage.getItem('aa_id');

        if (aaIdGiven == "true") {
            setAAHint(selectedAAHint);
        }
        else {
            setAAHint(selectAAHint);
        }

        /* Getting the AA list for dropdown */

        const aaListCb = (response) => {
            let aaData = [];
            let resData = response.data.data.aggregators;
            for (let i = 0; i < resData.length; i++) {
                aaData.push({
                    "id": resData[i].id,
                    "name": resData[i].name,
                    "handle": resData[i].handle
                });
            }
            setAAList(aaData);

            /* Selecting the aa automatically based on the account aggregator id in the URL */
            aaData.findIndex((item) => {
                if (item.id == aaId) {
                    setAAIdDivDisp(dispContent);
                    setAAError(false);
                    setAAId(aaId);
                    sessionStorage.setItem("aa_name", item.name);
                    setAaUpiIdText(item.username_text);
                    setAAName(item.name);
                    setAAHandle(item.handle);
                }
            })

            saveAnalyticsEvent("shown", "");
        }

        getAAList(history, aaListCb);

    }, []);

    /* When Choose the aa, aa upi id changing based on the selected aa */
    const handleAASelect = (event) => {
        let aa_id = event.target.value;
        setAAId(aa_id);
        setAaUpiId("");
        setAaUpiIdError(false);
        if (aa_id != -1) {
            setAAIdDivDisp(dispContent);
            setAAError(false);
            aaList.findIndex((item) => {
                if (item.id == aa_id) {
                    sessionStorage.setItem('aa_id', aa_id);
                    sessionStorage.setItem("aa_name", item.name);
                    setAaUpiIdText(item.username_text);
                    setAAName(item.name);
                    setAAHandle(item.handle);
                }
            })
            saveAnalyticsEvent('field_selected', field_names.aa_name);
        }
        else {
            setAAIdDivDisp(hideContent);
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setAaUpiId') {
            setAaUpiId(value);
        }
    }

    /* When pressed enter automatically Submit */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    /* When clicked on Submit */
    const handleSubmit = () => {

        //GAEvent("User","button clicked","Submit");

        if (!isSubmitted) {

            saveAnalyticsEvent('continue_clicked', "");
            var valid = true;
            /* Checking all required values entered or not */

            if (isNullOrEmpty(aaUpiId)) {
                setAaUpiIdError(true)
                setAaUpiIdErrMsg("Please enter your " + (isAAUser == "yes" ? aaUpiIdHint : aaUpiIdHintNewUser));
                saveFieldErrorEvent(scName, (isAAUser == "yes" ? field_names.aa_id : field_names.aa_mobile), "Please enter your " + (isAAUser == "yes" ? aaUpiIdHint : aaUpiIdHintNewUser));
                valid = false;
            }
            else if ((/^\d{10}$/.test(aaUpiId) == false)) {
                setAaUpiIdError(true)
                setAaUpiIdErrMsg("Please enter valid " + (isAAUser == "yes" ? aaUpiIdHint : aaUpiIdHintNewUser));
                saveFieldErrorEvent(scName, (isAAUser == "yes" ? field_names.aa_id : field_names.aa_mobile), "Please enter valid " + (isAAUser == "yes" ? aaUpiIdHint : aaUpiIdHintNewUser));
                valid = false;
            }
            else {
                setAaUpiIdError(false)
            }
            if (aaId == -1) {
                setAAError(true)
                saveFieldErrorEvent(scName, field_names.aa_name, aaErr);
                valid = false;
            }
            else {
                setAAError(false)
            }
            if (valid) {
                setSubmitted(true);
                handleNext();
            }
        }
    }

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    const handleFocus = (e) => {
        let nt = e.target.name;
        if (nt === 'setAaUpiId') {
            saveAnalyticsEvent('field_selected', (isAAUser === "no") ? field_names.aa_mobile : field_names.aa_id);
        }
    }

    const handleBlur = (e) => {
        let nt = e.target.name;
        if (nt === 'setAaUpiId') {
            if (aaUpiId.trim().length > 0) {
                saveAnalyticsEvent('field_entered', (isAAUser === "no") ? field_names.aa_mobile : field_names.aa_id);
            }
        }
    }

    const handleNext = () => {
        var txnId = sessionStorage.getItem("txn_id");
        setAaUpiIdError(false)
        setAAError(false)
        initiateAAConsent(aaId, aaUpiId + aaHandle, txnId, history, handleNextCb);
    }

    const handleNextCb = (response) => {
        setScreenName(scName);
        window.location.href = response.data.data.redirection_url;
    }

    /* Showing Terms and conditions popup */
    const showTcFunc = () => {
        setShowTc(true);
    }

    /* Hiding Terms and conditions popup */
    const hideTcFunc = () => {
        setShowTc(false);
    }

    /* Showing Terms and conditions popup */
    const showAAInfoFunc = () => {
        setShowAAInfo(true);
    }

    /* Hiding AA Info popup */
    const hideAAInfoFunc = () => {
        setShowAAInfo(false);
    }

    /*const setNewUserTrue = () => {
        setNewUser(true);
    }
    const setNewUserFalse = () => {
        setNewUser(false);
    }*/

    const handleNewUser = (value) => {
        setAAUser(value);
        setAaUpiIdError(false);
    }

    /* When cancel button clicked */
    const handleCancel = () => {

        if (!isCancelled) {
            setCancelled(true);

            saveAnalyticsEvent('cancel_clicked', "");

            sessionStorage.setItem('status_code', statusCodeUserCancelled);

            sessionStorage.setItem('status', false);
            setScreenName(scName);
            history.push("/txncomplete");
        }
    }

    return (
        <div>
            {
                (isSubmitted === true) ?
                    <div className="normal-card">
                        <Loader loaderStyle={getLoaderStyle()} />
                        <br /><br />
                        <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                        <div className="card-msg" style={fontRegular}>
                            {getProcessingDesc()}
                        </div>
                    </div>

                    :

                    <div className="action-card">
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div className="card-action-title" style={fontBold}>Account Aggregator</div>

                                        <br />
                                        <div className="card-msg-black" style={fontMedium}>
                                            Do you have a registered AA Account ?
                                        </div>

                                        <div>
                                            <Radio
                                                value="yes"
                                                selected={isAAUser}
                                                text={btnYes}
                                                onChange={handleNewUser}
                                                border="false"
                                            />
                                            <Radio
                                                value="no"
                                                selected={isAAUser}
                                                text={btnNo}
                                                onChange={handleNewUser}
                                                border="false"
                                            />
                                        </div>
                                        <br />

                                        <div className="card-action-subtitle" style={fontMedium}>

                                            {
                                                (isAAUser === "no") ?
                                                    "New User" : "Registered User"
                                            }
                                        </div>
                                        <div className="card-msg-black" style={fontMedium}>
                                            {
                                                (isAAUser === "no") ? "Select your preferred account aggregator to create your AA ID"
                                                    : "Enter your AA id and click on 'Next'"
                                            }
                                        </div>

                                        <div>
                                            <div style={{ paddingTop: '10px' }}>
                                                <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                                <span className="homeTextFieldTitle" style={(isAAError === true) ? errorMsgStyle : fontMedium}>{aaHint}</span>
                                            </div>
                                            <div className={(isAAError === true) ? "input-div-err-style" : "input-div-style"}>
                                                <select className={(aaIdGiven === "true") ? "input-disabled-style" : "input-style"} style={inputStyle} value={aaId} name='setUserName' onChange={handleAASelect} required>
                                                    <option value="-1">Select Account Aggregator</option>
                                                    {aaList.map((item) => {
                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                {
                                                    (aaIdGiven === "false") ?
                                                        <div className="float-right-icon">
                                                            <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                        </div>
                                                        : null}
                                            </div>
                                            {
                                                (isAAError === true) ?
                                                    <span className="error-msg" style={errorMsgStyle}>{aaErr}</span> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ paddingTop: '20px' }}>
                                        <div>
                                            <img src={getAaUpiIdIconPath()} className="homeIcon" alt="aaupiid" />

                                            <span className="homeTextFieldTitle" style={(isAaUpiIdError === true) ? errorMsgStyle : fontMedium}>

                                                {
                                                    (isAAUser === "no") ? aaUpiIdHintNewUser : aaUpiIdHint
                                                }

                                            </span>

                                        </div>
                                        <div className={(isAaUpiIdError === true) ? "input-div-err-style" : "input-div-style"}>
                                            <input type="text" className="input-style" autoComplete="off" autoCorrect="off" spellCheck="false" placeholder={(isAAUser === "no") ? aaUpiIdHint1NewUser : aaUpiIdHint1} style={inputStyle} value={aaUpiId} name='setAaUpiId' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                            <div className="float-right-aa-text" style={aaIdDivDisp}>
                                                <span style={{ color: '#6b6f86', fontSize: '12px' }}>{aaHandle}</span>
                                            </div>
                                        </div>
                                        {
                                            (isAaUpiIdError === true) ?
                                                <span className="error-msg" style={errorMsgStyle}>{aaUpiIdErrMsg}</span> : null
                                        }
                                    </div>
                                    <div style={{ paddingTop: '20px' }}>
                                        <span className="termsStyle" style={fontMedium}>{getTCText()}</span><span onClick={showTcFunc} className="tcStyle" style={fontMedium}>T & C</span>
                                    </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{getHomePrimaryBtnText()}</button>
                                    </div>
                                </div>
                                {/*<div>
                                        {
                                            (isAAUser === "no") ?
                                                <center><div className="termsStyle" style={fontMedium}> Already have AA ID <u className="hyperLinkStyle" onClick={setNewUserFalse}>Click Here</u> </div></center>
                                                : <center><div className="termsStyle" style={fontMedium}><u className="hyperLinkStyle" onClick={setNewUserTrue}>Click Here</u> to create a new AA ID</div></center>
                                        }
                                    </div>*/}
                                <div>
                                    <center><div className="termsStyle" style={fontMedium}>Want to know more about AA? <u className="hyperLinkStyle" onClick={showAAInfoFunc}>Click here</u></div></center>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                (showTc === true) ?
                    <div className='popup' style={fontRegular}>
                        <div className='popup_inner' style={popupStyle}>
                            <div className="popup_header" style={popupStyle}>
                                <img src={getCloseIconPath()} className="close-icon" onClick={hideTcFunc} alt="close" />
                                <h5 style={fontBold}>{termsTitle}</h5>
                            </div>
                            <Terms />
                        </div>
                    </div> : null
            }
            {
                (showAAInfo === true) ?
                    <div className='popup' style={fontRegular}>
                        <div className='popup_inner' style={fontRegular}>
                            <div className="popup_header" style={fontBold}>
                                <img src={getCloseIconPath()} className="close-icon" onClick={hideAAInfoFunc} alt="close" />
                                <h5>{aaInfoTitle}</h5>
                            </div>
                            <AAInstructions />
                        </div>
                    </div> : null
            }
        </div>
    );
};

export default withRouter(AAHome);
