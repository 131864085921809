import React, { useState, useEffect, useRef } from "react";
import { UserAction, UserCancelAction } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { databaseRef } from '../../utils/firebase';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontBold, fontMedium, otpInputStyle, errorMsgStyle, bottomDivStyle, fontRegular, inputStyle } from "../../utils/AppStyle";
import { otpTimeoutTitle, otpTimeoutCode, otpTimeoutDesc, otpError, inputHint, btnCancel, btnSubmit, mobileDeviceDesc, hardTokenDesc, otpTypeMobileDevice, otpTypeHardToken, statusCodeReportGenerated, statusCodeUserInteractionCompleted, statusTypeFailure, statusTypeError, statusCodeCategoryProcessing } from "../../utils/Strings";
import { getOtpIconPath, getUserActionIconsRequired, getUserActionBtnStyle, userActionBtnStyleText, getUserActionOtpTitle, getUserActionOtpDesc, getOtpStyle, otpStyleDigits, otpStyleBox, getOtpTimerStyle, otpTimerStyleClock, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { screen_names, saveEvent, setScreenName, field_names, saveFieldErrorEvent } from "../../utils/Analytics";

const ErrorComponent = ({ setShowCancel }) => {
    let history = useHistory();
    const isError = sessionStorage.getItem('error');
    const bankName = sessionStorage.getItem('bank_name');
    const txn_msg = sessionStorage.getItem('response_msg');
    const otpType = sessionStorage.getItem('otp_type');
    var otpSeconds = sessionStorage.getItem("otp_timer");
    //const [userOtp, setUserOtp] = useState('');
    const [seconds, setSeconds] = useState(otpSeconds);
    const [timerDisplay, setTimerDisplay] = useState("");
    const [isOtpError, setIsOtpError] = useState(isError);
    const [errMsg, setErrMsg] = useState(txn_msg);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [cardMsg, setCardMsg] = useState(getUserActionOtpDesc());
    const [otpStyle, setOtpStyle] = useState(getOtpStyle());

    const [numberOfDigits, setNoOfDigits] = useState(6);
    const [userOtp, setUserOtp] = useState("");
    const [digitOtp, setDigitOtp] = useState(new Array(numberOfDigits).fill(""));
    const [scName, setScName] = useState(screen_names.otp);

    // Create refs for input fields to manage focus
    const inputRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    // Function to handle input changes
    const handleInputChange = (e, index) => {
        const value = e.target.value;

        if (!/^[0-9]*$/.test(value)) {
            return;
        }

        // Update the OTP digit at the specified index
        const newOtp = [...digitOtp];
        newOtp[index] = value;
        setDigitOtp(newOtp);

        if (index == 0) {
            saveAnalyticsEvent("field_entered", "otp");
        }

        // Move focus to the next input field
        if (index < 5 && value !== '') {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleBackspaceAndEnter = (e, index) => {
        if (e.key === "Backspace" && !e.target.value && index > 0) {
            inputRefs[index - 1].current.focus()
        }
        if (e.key === "Enter" && e.target.value && index < numberOfDigits - 1) {
            inputRefs[index + 1].current.focus()
        }
    }

    useEffect(() => {
        if ((!isNullOrEmpty(otpSeconds)) && otpSeconds != 0) {
            const interval = setInterval(() => {
                setSeconds(seconds => (seconds > 0) ? (seconds - 1) : 0);
                if (seconds === 0) {
                    sessionStorage.setItem('response_code', otpTimeoutCode);
                    sessionStorage.setItem('response_title', otpTimeoutTitle);
                    sessionStorage.setItem('response_msg', otpTimeoutDesc);
                    clearInterval(interval);
                    setScreenName(screen_names.screen3);
                    history.push("/error");
                }
                else {
                    let mins = 0;
                    let secs = 0;

                    if (seconds > 59) {
                        mins = Math.floor(seconds / 60);
                        secs = (seconds - Math.floor(seconds / 60) * 60);
                    }
                    else {
                        secs = seconds;
                    }
                    setTimerDisplay(((mins > 9) ? mins : ("0" + mins)) + ":" + ((secs > 9) ? secs : ("0" + secs)))
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [seconds]);

    useEffect(() => {
        saveAnalyticsEvent("shown", "");

        setShowCancel("false");
        setOTPMsg(otpType);
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }


    const setOTPMsg = (type) => {

        if (type == otpTypeMobileDevice) {
            setCardMsg(mobileDeviceDesc);
        }
        else if (type == otpTypeHardToken) {
            setCardMsg(hardTokenDesc);
        }
        else {
            setCardMsg(getUserActionOtpDesc());
        }
    }

    const isEmptyOtp = (val) => {
        for (var i = 0; i < 6; i++) {
            if (val[i] == '') {
                return true;
            }
        }
        return false;
    }

    /* OTP Submit */
    const handleSubmit = () => {
        if (!isSubmitted) {
            saveAnalyticsEvent("submit_clicked", "");
            if (((otpStyle == otpStyleDigits) && isEmptyOtp(digitOtp)) || ((otpStyle == otpStyleBox) && isNullOrEmpty(userOtp))) {
                setIsOtpError(true);
                setErrMsg(otpError);
                saveFieldErrorEvent(scName, field_names.otp, otpError);
                return;
            }
            setSubmitted(true);

            setScreenName(scName);
            //UserAction('otp', userOtp, history);

            let txnId = sessionStorage.getItem("txn_id");
            const txnRef = databaseRef.child(txnId).child("txn_data");

            txnRef.once("value", function (snapshot) {
                let txnData = [];
                let datas = JSON.stringify(snapshot);
                txnData.push(JSON.parse(datas));
                let txnObj = txnData[0];

                sessionStorage.setItem('response_title', txnObj.txn_status.title);
                if ((txnObj.txn_status.code == statusCodeUserInteractionCompleted) || (txnObj.txn_status.code == statusCodeCategoryProcessing)) {
                    sessionStorage.setItem('loader_msg', txnObj.txn_status.ui_msg);
                    txnRef.off();
                    history.push("/process")
                }
                else if (txnObj.txn_status.code === statusCodeReportGenerated) { //Changing only msg when status as UserInteractionCompleted
                    sessionStorage.setItem('report_url', txnObj.report_url);
                    txnRef.off();
                    handleNextScreen();
                }
                else if ((txnObj.txn_status.type === statusTypeFailure) || (txnObj.txn_status.type === statusTypeError)) {  // Redirecting to error page when status as Failure or Error
                    txnRef.off();
                    history.push("/error");
                }
                else {
                    sessionStorage.setItem('firebase_status_code', "");
                    txnRef.off();
                    UserAction('otp', (otpStyle == otpStyleDigits) ? digitOtp.join('') : userOtp, history);
                }
            });
        }
    }

    /* Redirecting to next screen based on return url and shw_rpt variable */
    const handleNextScreen = () => {
        console.log("handleNextScreen");
        let txnId = sessionStorage.getItem("txn_id");
        /* Getting the client returnurl from the firebase */
        let returnUrl = sessionStorage.getItem('return_url');

        if (!isNullOrEmpty(returnUrl)) {
            openNextScreen();
        }
        else {
            const clientRef = databaseRef.child(txnId);
            var returnUrlObj = clientRef.child("client_data");
            returnUrlObj.on('value', snap => {
                if (snap.val() != null)
                    returnUrl = snap.val().return_url;
                sessionStorage.setItem('return_url', returnUrl);
                openNextScreen();
            });
        }
    }

    const openNextScreen = () => {
        const returnUrl = sessionStorage.getItem('return_url');
        const showReport = sessionStorage.getItem("shw_rpt");

        const isIOS = (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.DigitapBs);
        const isAndroid = (window.DigitapBs);
        const isWeb = (!isNullOrEmpty(returnUrl));

        if ((isAndroid || isIOS || isWeb) && (showReport == "0")) {
            sessionStorage.setItem('status_code', statusCodeUserInteractionCompleted);
            sessionStorage.setItem('status', true);
            history.push("/txncomplete");
        }
        else {
            history.push("/success");
        }
    }

    /* Cancel Pressed */
    const handleCancel = () => {
        if (!isCancelled) {
            setCancelled(true);
            saveAnalyticsEvent("cancel_clicked", "");

            setScreenName(scName);
            UserCancelAction('otp', history);
        }
    }

    /* Handle Enter key */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleOtp = (event) => {
        setUserOtp(event.target.value.replace(/\D/, '').substring(0, 10));
    }

    const handleFocus = (index) => {
        if (index == 0) {
            saveAnalyticsEvent("field_selected", field_names.otp);
        }
    }

    const handleFocus1 = (e) => {
        saveAnalyticsEvent('field_selected', field_names.otp);

    }

    const handleBlur1 = (e) => {
        if (userOtp.trim().length > 0) {
            saveAnalyticsEvent('field_entered', field_names.otp);
        }

    }

    return (
        <div>
            <div className="action-card">
                <div>
                    {
                        (getUserActionIconsRequired() == "true") ? <img className="user-action-icon" src={getOtpIconPath()} /> : <></>
                    }
                    <div className="card-action-title" style={fontBold}>{getUserActionOtpTitle()}</div>
                    <div className="card-action-msg" style={fontRegular}>
                        {
                            (!isNullOrEmpty(cardMsg)) ? cardMsg.replace("{bank}", bankName) : "Enter OTP"
                        }
                    </div>
                    <br />
                    <div>
                        {
                            (otpStyle == otpStyleDigits) ?

                                <div className="otp-input-container">
                                    {digitOtp.map((digit, index) => (
                                        <input className="otp"
                                            style={otpInputStyle}
                                            key={index}
                                            type="text"
                                            value={digit}
                                            onChange={(e) => handleInputChange(e, index)}
                                            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                                            onKeyDown={index === 5 ? handleKeyDown : null} // Handle Enter key press on the last digit
                                            onFocus={(e) => handleFocus(index)}
                                            ref={inputRefs[index]}
                                            maxLength="1"
                                            inputMode="numeric"
                                            autoComplete="off" // Disable autofill
                                            autoCorrect="off"
                                            spellCheck="false"
                                        />
                                    ))}
                                </div>
                                : <input className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" style={inputStyle} placeholder={inputHint} value={userOtp} name='setOtp' onKeyDown={(e) => handleKeyDown(e)} onChange={event => handleOtp(event)} onFocus={handleFocus1} onBlur={handleBlur1} />
                        }
                    </div>
                    {
                        (seconds > 0) ?
                            (getOtpTimerStyle() == otpTimerStyleClock) ?
                                <div className="otpTimeLeftStyle">
                                    <span style={fontRegular}>Time Remaining </span>
                                    <span style={fontMedium}>
                                        {timerDisplay}
                                    </span>
                                </div>
                                :
                                <span className="otpTimeLeftStyle" style={fontMedium}>{seconds} seconds Left</span> : null
                    }
                    <br />
                    <span style={{ clear: 'both' }}></span>
                    {
                        (isOtpError === true) ?
                            <span className="error-msg" style={errorMsgStyle}>{errMsg}</span> : null
                    }
                    <br /><br />
                    {
                        (getUserActionBtnStyle() == userActionBtnStyleText) ?
                            <div className="bottomTextDiv" style={bottomDivStyle}>
                                <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                                <div className="cancel-btn-div">
                                    <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                </div>
                            </div>
                            :
                            <div style={{ width: '100%' }}>
                                <button className="error-button" onClick={handleCancel} style={secondaryButtonTheme}>{btnCancel}</button>
                                <button className="primary-button" onClick={handleSubmit} style={primaryButtonTheme}>{btnSubmit}</button>
                            </div>}
                </div>
            </div>
        </div>
    );
};

export default withRouter(ErrorComponent);