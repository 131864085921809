import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { internalErrorCode, internalErrorTitle, permissionRequired } from "../../utils/Strings";
import { fontMedium } from "../../utils/AppStyle";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { generateGmailApiUrl } from "../../utils/EmailUtil";
import { emailSubmit } from "../../utils/NetworkUtil";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle, getProcessingDesc, getProcessingTitle } from "../../Config";


window.postAccessToken = function (accessToken) {
    sessionStorage.setItem("android_access_token", accessToken);
    window.location.reload();
};

window.postError = function (error) {
    sessionStorage.setItem("android_error", error);
    window.location.reload();
};

const TokenComponent = ({ setShowCancel }) => {

    let history = useHistory();

    useEffect(() => {
        setShowCancel("false");
        let url_string = window.location.href;
        url_string = url_string.replace('#', '?');
        let url = new URL(url_string);
        let params = url.searchParams;
        if (!isNullOrEmpty(params)) {
            let token = params.get("access_token", "");
            let error = params.get("error", "");
            postAccessToken(token);
        }
        else {
            if ((window.DigitapBs) && (typeof window.DigitapBs.openMailAuthFlow === 'function')) {
                let androidAccessToken = sessionStorage.getItem("android_access_token");
                let androidError = sessionStorage.getItem("android_error");
                if (!isNullOrEmpty(androidAccessToken)) {
                    postAccessToken(androidAccessToken);
                }
                else if (!isNullOrEmpty(androidError)) {
                    postError(androidError);
                }
                else {
                    window.DigitapBs.openMailAuthFlow();
                }
            }
            else {
                redirectToGoogleUrl();
            }
        }
    }, []);

    const postAccessToken = (token) => {
        if (!isNullOrEmpty(token)) {
            sessionStorage.setItem("access_token", token);
            sendAccessToken(token);
        }
        else {
            sessionStorage.setItem('response_code', internalErrorCode);
            sessionStorage.setItem('response_title', internalErrorTitle);
            sessionStorage.setItem('response_msg', permissionRequired);
            history.push("/error");
        }
    }

    const postError = (error) => {
        sessionStorage.setItem('response_code', internalErrorCode);
        sessionStorage.setItem('response_title', internalErrorTitle);
        sessionStorage.setItem('response_msg', error);
        history.push("/error");
    }

    const sendAccessToken = () => {

        const emailSubmitCb = (res) => {
            history.push("/process");
        }

        sessionStorage.setItem("android_access_token", "");
        sessionStorage.setItem("android_error", "");

        emailSubmit(history, emailSubmitCb);
    }


    const redirectToGoogleUrl = () => {
        window.location.href = generateGmailApiUrl();
    }

    return (
        <div>
            <div className="normal-card">
            <Loader loaderStyle={getLoaderStyle()} />
                <br /><br />
                <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                <div className="card-msg" style={fontMedium}>
                    {getProcessingDesc()}
                </div>
            </div>
        </div>
    );
};

export default withRouter(TokenComponent);