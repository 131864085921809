import React, { useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { dispContent, hideContent, primaryButtonTheme, secondaryButtonTheme, fontMedium, fontBold, fontRegular, errorMsgStyle, inputStyle } from '../../utils/AppStyle';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { UserActionRetry, UserCancelAction } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";

import { userActionRequired, btnSubmit, passwordDesc, btnCancel, passwordError, inputHintPassword } from "../../utils/Strings";


const PasswordComponent = () => {
    let history = useHistory();
    const isError = sessionStorage.getItem('error');
    const txn_msg = sessionStorage.getItem('response_msg');
    const bankName = sessionStorage.getItem('bank_name');
    const [type, setType] = useState('password');
    const [eyeOnDisp, setEyeOnDisp] = useState(hideContent);
    const [eyeOffDisp, setEyeOffDisp] = useState(dispContent);
    const [eyeDivDisp, setEyeDivDisp] = useState(hideContent);
    const [userPassword, setUserPassword] = useState('');
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);

    const [isPasswordError, setPasswordError] = useState(isError);
    const [errMsg, setErrMsg] = useState(txn_msg);

    let passwordHints = [];

    const handleClick = () => {
        setEyeOnDisp(eyeOnDisp === hideContent ? dispContent : hideContent);
        setEyeOffDisp(eyeOffDisp === dispContent ? hideContent : dispContent);
        setType(type === 'text' ? 'password' : 'text');
    }

    const handleChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setPassword') {
            setUserPassword(value.substring(0, 45));
        }
        if (value.length > 0) {
            setEyeDivDisp(dispContent);
        }
        else {
            setEyeDivDisp(hideContent);
        }
    }

    /* Question submit */
    const handleSubmit = () => {
        if (!isSubmitted) {
            if (isNullOrEmpty(userPassword)) {
                setPasswordError("true");
                setErrMsg(passwordError);
                return;
            }
            setSubmitted(true);
            UserActionRetry('file_password', userPassword, isError, history);
        }
    }

    /* Cancel Pressed */
    const handleCancel = () => {
        if (!isCancelled) {
            setCancelled(true);
            UserCancelAction('file_password', history);
        }
    }

    /* Handle enter key */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    <div className="card-action-title" style={fontBold}>{userActionRequired}</div>
                    <div className="card-action-msg" style={fontRegular}>
                        Your {bankName} {passwordDesc}
                    </div>
                    <br />
                    <div>
                        <input type={type} className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" style={inputStyle} placeholder={inputHintPassword} value={userPassword} name='setPassword' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} required />
                        <div className="float-right-eye-icon" style={eyeDivDisp}>
                            <FaEyeSlash style={eyeOffDisp} onClick={handleClick}></FaEyeSlash>
                            <FaEye style={eyeOnDisp} onClick={handleClick}></FaEye>
                        </div>
                    </div>
                    <br />
                    {
                        (passwordHints.length != 0) ?
                            <div style={{ paddingTop: '10px' }}>
                                <span style={fontBold} className='password_hint_title'>Password Hint:</span>
                                {passwordHints.map((item, index) => {
                                    return (
                                        <div>
                                            <span style={fontRegular} className="password_hint_desc">{item}</span>
                                            {(index != passwordHints.length - 1) ? <div className="password_hint_or"><span style={fontBold}>OR</span></div> : null}
                                        </div>
                                    )
                                })}
                            </div>
                            : null}
                    {
                        (isPasswordError == "true") ?
                            <span className="error-msg" style={errorMsgStyle}>{errMsg}</span> : null
                    }
                    <br /><br />
                    <div style={{ width: '100%' }}>
                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(PasswordComponent);