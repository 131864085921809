import { retryCount, URL_INSTITUTION_LIST, URL_TXN_COMPLETE, URL_UPLOAD_STMT, URL_CANCEL_MFA, URL_USER_ACTION, URL_VERIFY_INSTITUTION_CUSTOMER, URL_COMPLETE_UPLOAD, URL_EMAIL_SUBMIT, URL_AGGREGATOR_LIST, URL_SUBMIT_AA_INFO, URL_GET_AA_LIST, URL_INITIATE_AA_CONSENT, URL_CHECK_FP_STATUS, URL_SUBMIT_CHOICE, URL_DECRYPT_AA_REDIRECTION, URL_VIEW_REPORT, URL_ANALYTICS, URL_DELETE_STMT, URL_STATUS_CHECK } from '../Config';
import { tokenExpiredMsg, internalErrorCode, internalErrorTitle, statusCodeStatementDateRange, maintenanceStatusCode, tokenInvalidStatusCode, destinationEmailStatement, networkErrorTitle, networkErrorCode, emailTypeGmailId, aaUpiIdHint } from './Strings';
import axios from 'axios';

import { generate, EncodeValue, isNullOrEmpty } from './AppUtil';

let txnId = sessionStorage.getItem('txn_id');

export const VerifyCredential = (userName, pswdText, institutionId, bankType, txnId, history, cb) => {

    var pkstring = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCjgej1vHevzJKUrlIoWlRaG+DpCuuwrACZ3ND50UEU4kHMutBxRFj3XUjJ79iX7f6xr7meRH7YZe36eV/SMWzMu2/HnshClFzvGgFdVFMasClVFYrAsskmmhXp5bJvSdxqtocD1IosFdfB1+8eF18DqgbqoLnR1r6w2dBfb5Dn4wIDAQAB";
    var pkstr = '-----BEGIN PUBLIC KEY-----' + pkstring + '-----END PUBLIC KEY-----';
    var publicKey = window.forge.pki.publicKeyFromPem(pkstr);

    var uname = generate(userName, publicKey);
    var pwd = generate(pswdText, publicKey);

    const data = {
        username: uname,
        password: pwd,
        institution_id: EncodeValue(institutionId),
        txn_id: EncodeValue(txnId),
        institution_subtype: bankType
    }

    let url = URL_VERIFY_INSTITUTION_CUSTOMER;

    postApiCall1(data, url, history, cb);
}

export const saveAnalyticsToApi = (data, cb) => {
    console.log("BankStatementEvents:saveAnalyticsToApi");

    let url = URL_ANALYTICS;

    postApiCall2(data, url, cb);
}

export const submictChoice = (destination, institutionId, history, cb) => {

    const data = {
        destination: destination,
        institution_id: institutionId,
        txn_id: txnId
    }

    let url = URL_SUBMIT_CHOICE;

    postApiCall1(data, url, history, cb);
}

export const initiateAAConsent = (aaId, vua, txnId, history, cb) => {
    let institutionId = sessionStorage.getItem('institution_id');
    const data = {
        aa_id: aaId,
        vua: vua,
        txn_id: EncodeValue(txnId),
        institution_id: institutionId,
    }

    let url = URL_INITIATE_AA_CONSENT;

    postApiCall1(data, url, history, cb);
}

export const UserAction = (txnType, txnText, history) => {
    UserActionRetry(txnType, txnText, "false", history);
}

export const UserActionRetry = (txnType, txnText, password_retry, history) => {
    txnId = sessionStorage.getItem('txn_id');

    let data = {
        txn_id: txnId,
        txn_type: txnType,
        txn_text: txnText
    }

    if (password_retry == "true") {
        data.password_retry = password_retry;
    }

    let url = URL_USER_ACTION;

    const cb = (response) => {
        history.push("/process");
    }

    postApiCall1(data, url, history, cb);
}

export const UserCancelAction = (txnType, history) => {
    txnId = sessionStorage.getItem('txn_id');
    let destination = sessionStorage.getItem('destination');

    const data = {
        txn_id: txnId,
        type: txnType
    }

    let url = URL_CANCEL_MFA;

    const cb = (response) => {
        if (destination == destinationEmailStatement) {
            history.push("/emailstmthome");
        }
        else {
            history.push("/netbankinghome");
        }
    }

    postApiCall1(data, url, history, cb);
}

export const PDFUploadFinish = (history, finishCb) => {

    txnId = sessionStorage.getItem('txn_id');

    const data = {
        txn_id: txnId,
        sender: 'frontend'
    }

    let url = URL_COMPLETE_UPLOAD;

    const cb = (res) => {
        if (res.data.status == "error") {
            sessionStorage.setItem('response_code', res.data.code);
            sessionStorage.setItem('response_title', res.data.title);
            sessionStorage.setItem('response_msg', res.data.msg);
            if (res.data.code == statusCodeStatementDateRange) {
                sessionStorage.setItem('show_table', "true");
                let tableData = { "tableHeader": "Missing Dates", "colHeaders": ["From", "To"], "body": [] };
                let missingDates = res.data.missing_dates;
                for (var i = 0; i < missingDates.length; i++) {
                    let arr = [];
                    arr.push(missingDates[i].start_date);
                    arr.push(missingDates[i].end_date);
                    tableData.body.push(arr);
                }
                sessionStorage.setItem("table_data", JSON.stringify(tableData));
            }
            else {
                sessionStorage.setItem('show_table', "false");
            }
            history.push("/error");
        }
        else {
            sessionStorage.setItem('show_table', "false");
            finishCb();
        }
    }

    postApiCall(data, url, history, cb);
}

export const getInstitutionList = (history, cb) => {
    txnId = sessionStorage.getItem('txn_id');
    let destination = sessionStorage.getItem('destination');

    let url = URL_INSTITUTION_LIST;

    let data = {
        txn_id: txnId,
        destination: destination
    }

    postApiCall1(data, url, history, cb);
}

export const getAAList = (history, cb) => {
    txnId = sessionStorage.getItem('txn_id');

    let url = URL_GET_AA_LIST;

    let data = {
        txn_id: txnId
    }

    postApiCall1(data, url, history, cb);
}

export const uploadUiStmt = (data, history, cb) => {

    let url = URL_UPLOAD_STMT;
    postApiCall(data, url, history, cb);
}

export const emailSubmit = (history, cb) => {

    txnId = sessionStorage.getItem('txn_id');
    let institutionId = sessionStorage.getItem('institution_id');
    let accessToken = sessionStorage.getItem('access_token');

    const data = {
        txn_id: txnId,
        institution_id: institutionId,
        email_type: emailTypeGmailId,
        email_access_token: accessToken,
    }

    let url = URL_EMAIL_SUBMIT;
    postApiCall1(data, url, history, cb);
}

export const checkFipStatus = (ecres, resdate, fi, history, cb) => {

    txnId = sessionStorage.getItem('txn_id');

    const data = {
        txn_id: txnId,
        ecres: ecres,
        resdate: resdate,
        fi: fi
    }

    let url = URL_DECRYPT_AA_REDIRECTION;
    postApiCall2(data, url, cb);
}

export const txnComplete = (data, history, cb) => {
    let url = URL_TXN_COMPLETE;
    postApiCall(data, url, history, cb);
}

export const downloadReport = (data, history, cb) => {
    let url = URL_VIEW_REPORT;
    postApiCall3(data, url, history, cb);
}

export const handleErrorStatus = (response, history) => {
    sessionStorage.setItem('response_code', response.data.code);
    sessionStorage.setItem('response_title', response.data.title);
    sessionStorage.setItem('response_msg', response.data.msg);
    history.push('/error');
}

export const handleCatchBlock = (error, retryC, cb, history) => {
    let errMsg = (error.message);
    if (errMsg.indexOf(maintenanceStatusCode) !== -1) {
        history.push("/maintenance");
    }
    else {
        if (errMsg.indexOf(tokenInvalidStatusCode) !== -1) {
            sessionStorage.setItem('token_valid', false);
            sessionStorage.setItem('response_msg', tokenExpiredMsg);
        }
        else {
            sessionStorage.setItem('response_msg', error.message);
        }
        if (errMsg.indexOf(networkErrorTitle) !== -1) {
            if (retryC > 0) {
                cb(retryC - 1);
            }
            else {
                sessionStorage.setItem('response_code', networkErrorCode);
                sessionStorage.setItem('response_title', networkErrorTitle);
                sessionStorage.setItem('ui_error_desc', JSON.stringify(error));
                sessionStorage.setItem('show_try_again', true);
                history.push("/error");
            }
        }
        else {
            sessionStorage.setItem('response_code', internalErrorCode);
            sessionStorage.setItem('response_title', internalErrorTitle);
            sessionStorage.setItem('ui_error_desc', JSON.stringify(error));
            history.push("/error");
        }
    }
}

export const postApiCall = (data, url, history, cb) => {

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    const networkCall = (retryC) => {

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: headers
        }).then((res) => {
            cb(res);
        })
            .catch((error) => {
                handleCatchBlock(error, retryC, networkCall, history);
            });
    }
    networkCall(retryCount);
}

export const postApiCall1 = (data, url, history, cb) => {

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    const networkCall = (retryC) => {

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: headers
        }).then((res) => {
            sessionStorage.setItem("submitted", false);
            if (res.data.status === "error") {
                handleErrorStatus(res, history);
            }
            else {
                cb(res);
            }
        })
            .catch((error) => {
                handleCatchBlock(error, retryC, networkCall, history);
            });
    }
    networkCall(retryCount);
}

export const postApiCall2 = (data, url, cb) => {

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    axios({
        method: 'post',
        url: url,
        data: data,
        headers: headers
    }).then((res) => {
        cb(res);
    })
        .catch((error) => {
            cb(error);
        });
}

export const postApiCall3 = (data, url, history, cb) => {

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    const axi1 = axios.create({
        headers: headers,
        responseType: 'blob'
    })

    const networkCall = (retryC) => {

        axi1.interceptors.response.use(function (response) {
            if (response.status == 200) {
                if (isNullOrEmpty(response.headers['content-disposition'])) {
                    var resp = { "data": { "status": "error", "code": "MaxReportPullAllowedError", "msg": "The max allowed retrieve report count is exceeded.", "title": "Error" } }
                    return resp;
                }
            }
            return response;
        }, function (error) {
            return Promise.reject(error);
        });

        axi1.post(url, data).then((res) => {
            console.log(res);
            cb(res);
        })
            .catch((error) => {
                console.log(error);
                handleCatchBlock(error, retryC, networkCall, history);
            });
    }
    networkCall(retryCount);
}

export const postApiCall4 = (data, url, history, cb, extraData) => {

    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    const networkCall = (retryC) => {

        axios({
            method: 'post',
            url: url,
            data: data,
            headers: headers
        }).then((res) => {
            res.extra_data = extraData;
            cb(res);
        })
            .catch((error) => {
                handleCatchBlock(error, retryC, networkCall, history);
            });
    }
    networkCall(retryCount);
}

export const getApiCall = (url, history, cb) => {
    let headers = {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + sessionStorage.getItem('token')
    }

    const networkCall = (retryC) => {
        axios({
            method: 'get',
            url: url,
            headers: headers
        }).then((response) => {
            if ((response.data.status === "error")) {
                handleErrorStatus(response, history);
            }
            else {
                cb(response);
            }
        })
            .catch((error) => {
                handleCatchBlock(error, retryC, networkCall, history);
            });
    }
    networkCall(retryCount);
}
