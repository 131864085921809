import { frontendUrl, projectClientId } from '../Config';

export const generateGmailApiUrl = () => {

    let scope1 = "https://www.googleapis.com/auth/gmail.readonly";
    let scope2 = "https://www.googleapis.com/auth/userinfo.email";
    let scope3 = "https://www.googleapis.com/auth/userinfo.profile";
    let scope4 = "openid";
    let scope = scope2 + " " + scope3 + " " + scope4 + " " + scope1;
    //let state = "anyvalue";
    let prompt = "select_account";
    let redirect_uri = frontendUrl + "/accesstoken";
    let client_id = projectClientId;

    let url = "https://accounts.google.com/o/oauth2/v2/auth?response_type=token";
    url = url + "&scope=" + scope;
    //url = url + "&state=" + state;
    url = url + "&redirect_uri=" + redirect_uri;
    url = url + "&client_id=" + client_id;
    url = url + "&prompt=" + prompt;

    return url;
}

export const revokeAccess = (accessToken, cb) => {
    // Google's OAuth 2.0 endpoint for revoking access tokens.
    /* var revokeTokenEndpoint = 'https://oauth2.googleapis.com/revoke';

    // Create <form> element to use to POST data to the OAuth 2.0 endpoint.
    var form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('id','revokeForm');
    form.setAttribute('action', revokeTokenEndpoint);

    // Add access token to the form so it is set as value of 'token' parameter.
    // This corresponds to the sample curl request, where the URL is:
    //      https://oauth2.googleapis.com/revoke?token={token}
    var tokenField = document.createElement('input');
    tokenField.setAttribute('type', 'hidden');
    tokenField.setAttribute('name', 'token');
    tokenField.setAttribute('value', accessToken);
    form.appendChild(tokenField);

    // Add form to page and submit it to actually revoke the token.
    document.body.appendChild(form);
    form.submit(); */

    sendData({ token: accessToken }, 'https://oauth2.googleapis.com/revoke', cb);
}


function sendData(data, url, cb) {

    const XHR = new XMLHttpRequest();

    let urlEncodedData = "",
        urlEncodedDataPairs = [],
        name;

    // Turn the data object into an array of URL-encoded key/value pairs.
    for (name in data) {
        urlEncodedDataPairs.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }

    // Combine the pairs into a single string and replace all %-encoded spaces to
    // the '+' character; matches the behavior of browser form submissions.
    urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

    // Define what happens on successful data submission
    XHR.addEventListener('load', function (event) {
        cb();
    });

    // Define what happens in case of error
    XHR.addEventListener('error', function (event) {
        cb();
    });

    // Set up our request
    XHR.open('POST', url);

    // Add the required HTTP header for form data POST requests
    XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    // Finally, send our data.
    XHR.send(urlEncodedData);
}