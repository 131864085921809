import React, { useState, useEffect } from "react";
import { databaseRef } from '../../utils/firebase';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { fontBold, fontRegular } from "../../utils/AppStyle";
import { isNull, isNullOrEmpty } from "../../utils/AppUtil";
import { sessionTimeoutTitle, sessionTimeoutCode, sessionTimeoutDesc, statusCodeUserInteractionCompleted, statusCodeReportGenerated, statusTypeFailure, statusTypeError, stmtProcessingDesc, statusCodeEmailDownloadComplete, destinationEmailStatement, destinationPdfUpload, statusCodeUIAccountRecheckError, kotakInstitutionId, destinationNetbanking, statusCodeTxnDateRange, statusCodeTxnPerMonthRange, statusCodeTxnPerMonthError, statusCodeInvalidPdfType, destinationAccountAggregator, statusCodeTxnTimeout, statusCodeCategoryTimeout, statusCodeAAFINotificationTimeout } from "../../utils/Strings";
import { Loader } from "../../utils/Loader";
import { getAnalyticsEnabled, getAnalyticsCallbackRequired, getLoaderStyle, getNBRedirection, getProcessingDesc, getProcessingTitle } from "../../Config";
import { screen_names, saveEvent, saveProcessingEvent } from "../../utils/Analytics";

const ProcessComponent = ({ setShowCancel }) => {

  let history = useHistory();
  let txnData = [];

  let destination = sessionStorage.getItem('destination');
  let institutionId = sessionStorage.getItem('institution_id');

  const [loaderData, setLoaderData] = useState((destination == destinationPdfUpload) ? stmtProcessingDesc : getProcessingDesc());
  const [seconds, setSeconds] = useState((destination == destinationAccountAggregator) ? 1200 : 600);
  // Changing session timeout value to 10 minutes for all banks
  //const [seconds, setSeconds] = useState(600);

  useEffect(() => {
    setShowCancel("false");

    const interval = setInterval(() => {
      if (seconds === 0) {
        sessionStorage.setItem('response_code', sessionTimeoutCode);
        sessionStorage.setItem('response_title', sessionTimeoutTitle);
        sessionStorage.setItem('response_msg', sessionTimeoutDesc);
        clearInterval(interval);
        history.push("/error");     //Redirecting to error page when session timeout
      }
      setSeconds(seconds => (seconds > 0) ? (seconds - 1) : 0);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);


  useEffect(() => {

    if (!isNullOrEmpty(sessionStorage.getItem('loader_msg'))) {
      setLoaderData(sessionStorage.getItem('loader_msg'));
    }

    saveProcessingEvent(screen_names.processing, getProcessingTitle(), loaderData);

    let txnId = sessionStorage.getItem("txn_id");
    const txnRef = databaseRef.child(txnId).child("txn_data");

    txnRef.on("value", function (snapshot) {
      txnData = [];
      let datas = JSON.stringify(snapshot);
      txnData.push(JSON.parse(datas));

      handleRedirection(txnData[0]);
    });

    const handleRedirection = (txnObj) => {
      sessionStorage.setItem('response_title', txnObj.txn_status.title);
      sessionStorage.setItem('response_msg', txnObj.txn_status.ui_msg);
      sessionStorage.setItem('response_code', txnObj.txn_status.code);

      if ((txnObj.txn_status.type === statusTypeFailure) || (txnObj.txn_status.type === statusTypeError)) {
        sessionStorage.setItem('error', true);
      }
      else {
        sessionStorage.setItem('error', false);
      }
      if (txnObj.captcha_required === true) {  //Redirecting captcha page when captcha_required true
        sessionStorage.setItem('captcha_url', txnObj.captcha_url);
        sessionStorage.setItem('captcha_type', txnObj.captcha_type);
        txnRef.off();
        history.push("/captcha");
      }
      else if (txnObj.otp_required === true) {    //Redirecting otp page when otp_required true
        sessionStorage.setItem('otp_timer', txnObj.otp_timer);
        sessionStorage.setItem('otp_type', txnObj.otp_type);
        txnRef.off();
        history.push("/otp");
      }
      else if (txnObj.question_required === true) {   //Redirecting question page when question_required true
        sessionStorage.setItem('question', txnObj.question);
        txnRef.off();
        history.push("/question");
      }
      else if (txnObj.password_required === true) {       //Redirecting password page when password_required true
        sessionStorage.setItem('password_hint', txnObj.password_hint);
        txnRef.off();
        history.push("/password");
      }
      else if (txnObj.account_num_required === true) {  //Redirecting account page when account_num_required true
        sessionStorage.setItem('account_num_data', txnObj.account_num_data);
        txnRef.off();
        history.push("/account");
      }
      else if (txnObj.txn_status.code === statusCodeUserInteractionCompleted) { //Changing only msg when status as UserInteractionCompleted
        setLoaderData(sessionStorage.getItem('response_msg'));
        saveProcessingEvent(screen_names.user_interaction_completed, getProcessingTitle(), sessionStorage.getItem('response_msg'));

        sessionStorage.setItem('show_try_again', false);
        if (((destination == destinationNetbanking) && (getNBRedirection() == "true")) || ((!isNullOrEmpty(sessionStorage.getItem('user_redirection'))) && (sessionStorage.getItem('user_redirection') == '1'))) {
          txnRef.off();
          sessionStorage.setItem('status', 'in-progress');
          sessionStorage.setItem('token_valid', false);
          sessionStorage.setItem('is_processing', '1');
          history.push("/txncomplete");
        }
      }
      else if ((txnObj.txn_status.code === statusCodeTxnTimeout) || (txnObj.txn_status.code === statusCodeCategoryTimeout) || (txnObj.txn_status.code === statusCodeAAFINotificationTimeout)) {
        txnRef.off();
        sessionStorage.setItem('status', 'false');
        sessionStorage.setItem('token_valid', false);
        sessionStorage.setItem('is_processing', '0');
        history.push("/txncomplete");
      }
      else if (txnObj.txn_status.code === statusCodeEmailDownloadComplete) {
        setLoaderData(sessionStorage.getItem('response_msg'));
      }
      else if ((txnObj.txn_status.type === statusTypeFailure) || (txnObj.txn_status.type === statusTypeError)) {  // Redirecting to error page when status as Failure or Error
        let tryAgainEnabled = txnObj.txn_status.try_again_enabled;
        txnRef.off();
        let errReportGen = sessionStorage.getItem("err_report_gen");
        if (((!isNullOrEmpty(sessionStorage.getItem('user_redirection'))) && (sessionStorage.getItem('user_redirection') == '2')) || ((errReportGen == "2") && (destination == destinationPdfUpload) && ((txnObj.txn_status.code != statusCodeTxnDateRange) && (txnObj.txn_status.code != statusCodeTxnPerMonthRange) && (txnObj.txn_status.code != statusCodeTxnPerMonthError) && (txnObj.txn_status.code != statusCodeInvalidPdfType)))) {
          sessionStorage.setItem('status_code', sessionStorage.getItem('response_code'));
          sessionStorage.setItem('status', false);
          sessionStorage.setItem('is_processing', '0');
          history.push("/txncomplete");
        }
        else {
          if (!isNull(tryAgainEnabled) && ((tryAgainEnabled == "0"))) {
            sessionStorage.setItem('show_try_again', false);
          }
          history.push("/error");
        }
      }
      else if (txnObj.txn_status.code === statusCodeReportGenerated) {  // Redirecting to success page when status as ReportGenerated
        sessionStorage.setItem('report_url', txnObj.report_url);
        txnRef.off();
        handleNextScreen();
      }
      else if (txnObj.txn_status.code === statusCodeUIAccountRecheckError) {  // Redirecting to success page when status as ReportGenerated
        sessionStorage.setItem('report_url', txnObj.report_url);
        txnRef.off();
        history.push("/info");
      }
    }
  }, []);


  /* Redirecting to next screen based on return url and shw_rpt variable */
  const handleNextScreen = () => {

    let txnId = sessionStorage.getItem("txn_id");
    /* Getting the client returnurl from the firebase */
    let returnUrl = sessionStorage.getItem('return_url');

    if (!isNullOrEmpty(returnUrl)) {
      openNextScreen();
    }
    else {
      const clientRef = databaseRef.child(txnId);
      var returnUrlObj = clientRef.child("client_data");
      returnUrlObj.on('value', snap => {
        if (snap.val() != null)
          returnUrl = snap.val().return_url;
        sessionStorage.setItem('return_url', returnUrl);
        openNextScreen();
      });
    }
  }

  const openNextScreen = () => {

    const returnUrl = sessionStorage.getItem('return_url');
    const showReport = sessionStorage.getItem("shw_rpt");

    const isIOS = (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.DigitapBs);
    const isAndroid = (window.DigitapBs);
    const isWeb = (!isNullOrEmpty(returnUrl));
    const isIframe = (window.self !== window.top);

    if ((isAndroid || isIOS || isWeb || isIframe) && (showReport == "0")) {
      sessionStorage.setItem('status_code', statusCodeUserInteractionCompleted);
      sessionStorage.setItem('status', true);
      sessionStorage.setItem('is_processing', '0');
      history.push("/txncomplete");
    }
    else {
      history.push("/success");
    }
  }

  return (
    <div>
      <div className="normal-card">
        <Loader loaderStyle={getLoaderStyle()} />
        <br /><br />
        <div className="card-title" style={fontBold}>{getProcessingTitle()}</div><br />
        <div className="card-msg" style={fontRegular}>
          {loaderData}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ProcessComponent);