import PropTypes from "prop-types";
import React, { Component } from "react";
import { getLoaderColor1, getLoaderColor2 } from "../Config";
import "../loader.css";

import styled, { keyframes } from 'styled-components'

const loaderTheme = {
  background: getLoaderColor1()
}

const rotate = keyframes`
  from {
    transform :rotate(0deg);
  }
  to {
    transform :rotate(360deg);
  }
`
const Spinner = styled.div`
  width:22px;
  height:22px;
  margin:auto;
  border: 2px solid ${props => props.loaderColor1};
  border-top: 2px solid ${props => props.loaderColor2};
  border-radius: 50%;
  animation-name: ${rotate};
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-delay: 0.1s;
  animation-play-state: running;
  animation-iteration-count: infinite;
`


export class SmallLoader extends Component {
  state = {};

  render() {
    const { loaderStyle } = this.props;
    return (
      <div>
        {
          (loaderStyle == "circle") ?

            <Spinner loaderColor1={getLoaderColor1()} loaderColor2={getLoaderColor2()} />

            :

            <div>
              <div>
                <div className="sc-bdVaJa bViQzk" style={loaderTheme}></div>
                <div className="sc-bwzfXH bLLmrZ" style={loaderTheme}></div>
                <div className="sc-htpNat ebGjrP" style={loaderTheme}></div>
              </div>
            </div>
        }
      </div>
    );
  }
}

SmallLoader.propTypes = {
  loaderStyle: PropTypes.node
};