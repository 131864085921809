import React, { useState, useEffect } from "react";

import Terms from "../include/Terms";
//import { GAEvent } from '../../utils/ganalytcs';
import { secondaryButtonTheme, primaryButtonTheme, fontRegular, fontBold, fontMedium, errorMsgStyle, inputStyle } from '../../utils/AppStyle';
import { isNullOrEmpty } from "../../utils/AppUtil";
import { initiateAAConsent } from "../../utils/NetworkUtil";

import { getAaUpiIdIconPath, getCloseIconPath, getHomePrimaryBtnText, getProcessingDesc, getProcessingTitle, getTCText, getAnalyticsEnabled, getAnalyticsCallbackRequired } from '../../Config';

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { aaUpiIdHint1NewUser, aaUpiIdHintNewUser, termsTitle, statusCodeUserCancelled, btnCancel } from "../../utils/Strings";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle } from "../../Config";
import { field_names, saveEvent, saveFieldErrorEvent, screen_names, setScreenName } from "../../utils/Analytics";

const AAMobile = ({ setShowCancel }) => {
    let history = useHistory();
    const [showTc, setShowTc] = useState(false);
    const [aaMobile, setAAMobile] = useState("");
    const [aaMobileErrMsg, setAaMobileErrMsg] = useState("");
    const [aaMobileEditable, setAAMobileEditable] = useState("0");
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [checked, setChecked] = useState(true);
    const [isCheckBoxError, setCheckBoxError] = useState(false);
    const [isAaMobileError, setAaMobileError] = useState(false);
    const [scName, setScName] = useState(screen_names.aa_mobile);

    useEffect(() => {
        setShowCancel("false");
        setAAMobile(sessionStorage.getItem('aa_mobile'))
        setAAMobileEditable(sessionStorage.getItem('aa_mobile_editable'))

        saveAnalyticsEvent("shown", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    /* When clicked on Submit */
    const handleSubmit = () => {

        saveAnalyticsEvent('continue_clicked', "");
        //GAEvent("User","button clicked","Submit");

        if (!isSubmitted) {
            if (isNullOrEmpty(aaMobile)) {
                setAaMobileError(true)
                setAaMobileErrMsg("Please enter your mobile number");
                saveFieldErrorEvent(scName, field_names.aa_mobile, "Please enter your mobile number");
            }
            else if ((/^\d{10}$/.test(aaMobile) == false)) {
                setAaMobileError(true)
                setAaMobileErrMsg("Please enter valid mobile number");
                saveFieldErrorEvent(scName, field_names.aa_mobile, "Please enter valid mobile number");
            }
            else if (!checked) {
                setCheckBoxError(true);
            }
            else {
                setSubmitted(true);
                handleNext();
            }
        }
    }

    const handleCheckBoxClick = () => {
        setCheckBoxError(false);
        setChecked(!checked);
    }

    const handleMobileChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setAaMobile') {
            setAAMobile(value);
        }
    }

    const handleFocus = (e) => {
        let nt = e.target.name;
        if (nt === 'setAaMobile') {
            saveAnalyticsEvent('field_selected', field_names.aa_mobile);
        }
    }

    const handleBlur = (e) => {
        let nt = e.target.name;
        if (nt === 'setAaMobile') {
            if (aaMobile.trim().length > 0) {
                saveAnalyticsEvent('field_entered', field_names.aa_mobile);
            }
        }
    }

    const handleNext = () => {
        var txnId = sessionStorage.getItem("txn_id");
        var vua = sessionStorage.getItem('aa_vua');
        initiateAAConsent("", aaMobile + "@" + vua, txnId, history, handleNextCb);
    }

    const handleNextCb = (response) => {
        setScreenName(scName);
        window.location.href = response.data.data.redirection_url;
    }

    /* Showing Terms and conditions popup */
    const showTcFunc = () => {
        setShowTc(true);
    }

    /* Hiding Terms and conditions popup */
    const hideTcFunc = () => {
        setShowTc(false);
    }

    /* When cancel button clicked */
    const handleCancel = () => {

        if (!isCancelled) {
            setCancelled(true);
            saveAnalyticsEvent('cancel_clicked', "");

            sessionStorage.setItem('status_code', statusCodeUserCancelled);

            sessionStorage.setItem('status', false);
            setScreenName(scName);
            history.push("/txncomplete");
        }
    }

    return (
        <div>

            {
                (isSubmitted === true) ?
                    <div className="normal-card">
                        <Loader loaderStyle={getLoaderStyle()} />
                        <br /><br />
                        <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                        <div className="card-msg" style={fontRegular}>
                            {getProcessingDesc()}
                        </div>
                    </div>

                    :

                    <div className="action-card">
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div className="card-action-title" style={fontBold}>Account Aggregator</div>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ paddingTop: '20px' }}>
                                        <div>
                                            <img src={getAaUpiIdIconPath()} className="homeIcon" alt="aaupiid" />

                                            <span className="homeTextFieldTitle" style={fontMedium}>

                                                {
                                                    aaUpiIdHintNewUser
                                                }

                                            </span>

                                        </div>
                                        <div className={(isAaMobileError === true) ? "input-div-err-style" : "input-div-style"}>
                                            <input type="text" className={(aaMobileEditable == "0") ? "input-disabled-style" : "input-style"} autoComplete="off" autoCorrect="off" spellCheck="false" name='setAaMobile' placeholder={aaUpiIdHint1NewUser} style={inputStyle} value={aaMobile} onChange={handleMobileChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                        </div>
                                        {
                                            (isAaMobileError === true) ?
                                                <span className="error-msg" style={errorMsgStyle}>{aaMobileErrMsg}</span> : null
                                        }

                                    </div>
                                    <div style={{ paddingTop: '20px' }}>
                                        <input type="checkbox" style={{ verticalAlign: "middle" }} onChange={handleCheckBoxClick} checked={checked} /><span className="termsStyle" style={fontMedium}> I agree that above mobile number is linked to the bank account </span>
                                    </div>
                                    {
                                        (isCheckBoxError === true) ?
                                            <span className="error-msg" style={errorMsgStyle}>Please check the Mobile number checkbox</span> : null
                                    }
                                    <div style={{ paddingTop: '10px' }}>
                                        <span className="termsStyle" style={fontMedium}>{getTCText()} </span><a onClick={showTcFunc} className="tcStyle" style={fontMedium}>T & C</a>
                                    </div>
                                    <div style={{ marginTop: "30px" }}>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{getHomePrimaryBtnText()}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {
                (showTc === true) ?
                    <div className='popup' style={fontRegular}>
                        <div className='popup_inner' style={fontRegular}>
                            <div className="popup_header" style={fontBold}>
                                <img src={getCloseIconPath()} className="close-icon" onClick={hideTcFunc} alt="close" />
                                <h5>{termsTitle}</h5>
                            </div>
                            <Terms />
                        </div>
                    </div> : null
            }
        </div>
    );
};

export default withRouter(AAMobile);
