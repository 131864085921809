import React, { useState, useEffect } from "react";
import { UserAction, UserCancelAction } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontBold, fontMedium, inputStyle, errorMsgStyle, bottomDivStyle, fontRegular } from "../../utils/AppStyle";
import { inputHint, captchaError, expressionError, captchaTypeMathematical, btnCancel, btnSubmit } from "../../utils/Strings";
import { getCaptchaIconPath, getUserActionIconsRequired, getUserActionBtnStyle, userActionBtnStyleText, getUserActionExpressionTitle, getUserActionExpressionDesc, getUserActionCaptchaTitle, getUserActionCaptchaDesc, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { screen_names, saveEvent, setScreenName, saveFieldErrorEvent, field_names } from "../../utils/Analytics";

const CaptchaComponent = ({ setShowCancel }) => {
    let history = useHistory();
    const isError = sessionStorage.getItem('error');
    const txn_msg = sessionStorage.getItem('response_msg');
    var captchaUrl = sessionStorage.getItem("captcha_url");
    var captchaType = sessionStorage.getItem("captcha_type");
    const [userCaptcha, setUserCaptcha] = useState('');
    const [isCaptchaError, setIsCaptchaError] = useState(isError);
    const [errMsg, setErrorMsg] = useState(txn_msg);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [scName, setScName] = useState(screen_names.captcha);

    useEffect(() => {
        setShowCancel("false");

        saveAnalyticsEvent("shown", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    /* Captcha Submit */
    const handleSubmit = () => {
        if (!isSubmitted) {
            saveAnalyticsEvent("submit_clicked", "");
            if (isNullOrEmpty(userCaptcha)) {
                setIsCaptchaError(true);

                (captchaType === captchaTypeMathematical) ?
                    setErrorMsg(expressionError) : setErrorMsg(captchaError)

                saveFieldErrorEvent(scName, field_names.captcha, errMsg);
                return;
            }
            setSubmitted(true);

            setScreenName(scName);
            UserAction('captcha', userCaptcha, history);
        }
    }

    /* Cancel pressed */
    const handleCancel = () => {
        if (!isCancelled) {
            setCancelled(true);
            saveAnalyticsEvent("cancel_clicked", "");
            setScreenName(scName);
            UserCancelAction('captcha', history);
        }
    }

    /* Handle enter key */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleCaptcha = (event) => {
        (captchaType === captchaTypeMathematical) ?
            setUserCaptcha(event.target.value.replace(/\D/, '').substring(0, 32)) : setUserCaptcha(event.target.value.replace(/[`~'",.<>\{\}\[\]\\\/]/gi, '').substring(0, 32))
    }

    const handleFocus = () => {
        saveAnalyticsEvent("field_selected", field_names.captcha);
    }

    const handleBlur = () => {
        if (userCaptcha.trim().length > 0) {
            saveAnalyticsEvent("field_entered", field_names.captcha);
        }
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    {
                        (getUserActionIconsRequired() == "true") ? <img className="user-action-icon" src={getCaptchaIconPath()} /> : <></>
                    }

                    {
                        (captchaType === captchaTypeMathematical) ?
                            <div className="card-action-title" style={fontBold}>{getUserActionExpressionTitle()}</div> : <div className="card-action-title" style={fontBold}>{getUserActionCaptchaTitle()}</div>
                    }

                    {
                        (captchaType === captchaTypeMathematical) ?
                            <div className="card-action-msg" style={fontRegular}>{getUserActionExpressionDesc()}</div> : <div className="card-action-msg" style={fontRegular}>{getUserActionCaptchaDesc()}</div>
                    }
                    <br />
                    <div style={{ width: "100%", alignItems: "center", textAlign: "center" }}>
                        <img src={captchaUrl} className="captchaImageStyle" alt="Captcha" /><br /><br />
                    </div>
                    <div>
                        <input className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" placeholder={inputHint} style={inputStyle} value={userCaptcha} name='setCaptcha' onKeyDown={(e) => handleKeyDown(e)} onChange={event => handleCaptcha(event)} onFocus={handleFocus} onBlur={handleBlur} />
                    </div>
                    {
                        (isCaptchaError === true) ?
                            <span className="error-msg" style={errorMsgStyle}>{errMsg}</span> : null
                    }
                    <br /><br />
                    {
                        (getUserActionBtnStyle() == userActionBtnStyleText) ?
                            <div className="bottomTextDiv" style={bottomDivStyle}>
                                <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                                <div className="cancel-btn-div">
                                    <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                </div>
                            </div>
                            :
                            <div style={{ width: '100%' }}>
                                <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                            </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default withRouter(CaptchaComponent);