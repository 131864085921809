import React, { useState, useEffect } from "react";
import { getSuccessIconPath } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontMedium } from "../../utils/AppStyle";
import { Radio } from "../../utils/Radio";
import { statusCodeUserInteractionCompleted, btnTakeMeback, btnViewReport, btnFileType, fileTypeJson, fileTypeXlsx } from "../../utils/Strings";
import { downloadReport } from "../../utils/NetworkUtil";

const SuccessComponent = ({ setShowCancel }) => {
    let history = useHistory();

    const successText = sessionStorage.getItem('response_msg');

    const txnId = sessionStorage.getItem('txn_id');
    const sessionToken = sessionStorage.getItem('token');
    const showReport = sessionStorage.getItem("shw_rpt");

    //const [reportType, setReportType] = useState("type4");
    const [fileType, setFileType] = useState("json");

    useEffect(() => {
        setShowCancel("false");
    }, []);

    /* When clicked on TakemeBack button */
    const handleTakeMeBack = () => {
        sessionStorage.setItem('status_code', statusCodeUserInteractionCompleted);
        sessionStorage.setItem('status', true);
        history.push("/txncomplete");
    }

    const handleReportTypeSelect = (value) => {
        //setReportType(value);
        setFileType('json');
    }

    const handleFileTypeSelect = (value) => {
        setFileType(value);
    }

    const downloadReportCb = (res) => {
        if (res.data.status === "error") {
            sessionStorage.setItem('response_code', res.data.code);
            sessionStorage.setItem('response_title', res.data.title);
            sessionStorage.setItem('response_msg', res.data.msg);
            history.push("/error");
        }
        else {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(new Blob([res.data]));
            const contentDisposition = res.headers['content-disposition'];
            const contentType = res.headers['content-type'];
            let fileName = `report-${txnId}.${fileType}`;
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            a.setAttribute("download", fileName);
            a.click();
        }
    }

    /* When clicked on ViewReport */
    const handleViewReport = () => {
        const data = {
            txn_id: txnId,
            token: sessionToken,
            report_type: fileType,
            //report_subtype: reportType,
        }

        downloadReport(data, history, downloadReportCb)
    }
    return (
        <div>
            <div className="normal-card">
                <div style={{ marginTop: '30px' }}>
                    <img src={getSuccessIconPath()} className="respImgStyle" alt="success" />
                    <br /><br />
                    <div className="card-title" style={fontMedium}>{successText}</div><br />
                    <div className="card-msg" style={fontMedium}>
                        <br />
                        <br />
                        {
                            (showReport === '1') ?
                                // <div>
                                //     <div>
                                //         <div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "20px" }}>
                                //             <span className="homeTextFieldTitle" style={fontMedium}>{btnreportType}</span>
                                //         </div>
                                //         <div style={{ display: "inline-block", verticalAlign: "middle" }}>

                                //             <div>
                                //                 <Radio
                                //                     value="type3"
                                //                     selected={reportType}
                                //                     text={btnType3Report}
                                //                     onChange={handleReportTypeSelect}
                                //                     border="false"
                                //                 />
                                //                 <Radio
                                //                     value="type4"
                                //                     selected={reportType}
                                //                     text={btnType4Report}
                                //                     onChange={handleReportTypeSelect}
                                //                     border="false"
                                //                 />
                                //             </div>
                                //         </div>
                                //     </div>


                                <div>
                                    <div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "20px" }}>
                                        <span className="homeTextFieldTitle" style={fontMedium}>{btnFileType}</span>
                                    </div>
                                    <div style={{ display: "inline-block", verticalAlign: "middle" }}>

                                        <div>
                                            <Radio
                                                value="json"
                                                selected={fileType}
                                                text={fileTypeJson}
                                                onChange={handleFileTypeSelect}
                                                border="false"
                                            />
                                            <Radio
                                                value="xlsx"
                                                selected={fileType}
                                                text={fileTypeXlsx}
                                                onChange={handleFileTypeSelect}
                                                border="false"
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <br /><br /><br /><br /><br />
                    <div className="bottomDiv">
                        <button variant="outline-error" className={(showReport === "1") ? "full-error-button" : "full-primary-button"} style={(showReport === "1") ? secondaryButtonTheme : primaryButtonTheme} onClick={handleTakeMeBack}>{btnTakeMeback}</button>
                        {
                            (showReport === '1') ? <button className='full-primary-button' style={primaryButtonTheme} onClick={handleViewReport}>{btnViewReport}</button> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(SuccessComponent);
