import React, { useEffect } from "react";
import { destinationEmailStatement } from '../../utils/Strings';
import { databaseRef } from '../../utils/firebase';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { revokeAccess } from "../../utils/EmailUtil";
import { txnComplete } from "../../utils/NetworkUtil";
import { Loader } from "../../utils/Loader";
import { getAnalyticsEnabled, getAnalyticsCallbackRequired, getLoaderStyle } from "../../Config";
import { saveEvent } from "../../utils/Analytics";

const TxnComplete = ({ setShowCancel }) => {
  let history = useHistory();
  const returnUrl = sessionStorage.getItem('return_url');
  const txnId = sessionStorage.getItem('txn_id');
  const requestId = sessionStorage.getItem('request_id');
  const statusCode = sessionStorage.getItem('status_code');
  const uiErrorDesc = sessionStorage.getItem('ui_error_desc');
  const status = sessionStorage.getItem('status');
  const isProcessing = sessionStorage.getItem('is_processing');

  let tokenValid = sessionStorage.getItem('token_valid');

  const getFormattedReturnUrl = (returnUrl) => {
    var count = (returnUrl.match(/%s/g) || []).length;

    if (count === 4) {
      returnUrl = returnUrl.replace("%s", requestId);
      returnUrl = returnUrl.replace("%s", txnId);
      if (status == "in-progress") {                  // This thing doing for only KBNBFC, as because they are assuming status as true only even though if isProcessing key as 1
        returnUrl = returnUrl.replace("%s", 'true');
      }
      else {
        returnUrl = returnUrl.replace("%s", status);
      }
      returnUrl = returnUrl.replace("%s", isProcessing); //isProcessing
    }
    else if (count === 3) {
      returnUrl = returnUrl.replace("%s", requestId);
      returnUrl = returnUrl.replace("%s", txnId);
      returnUrl = returnUrl.replace("%s", status);
    }
    else if (count === 2) {
      returnUrl = returnUrl.replace("%s", txnId);
      returnUrl = returnUrl.replace("%s", status);
    }
    return returnUrl;
  }

  const exitFromFlow = () => {
    let destination = sessionStorage.getItem("destination");
    let accesstoken = sessionStorage.getItem("access_token");
    if ((destination == destinationEmailStatement) && (!isNullOrEmpty(accesstoken))) {
      revokeAccess(accesstoken, exit);
    }
    else {
      exit();
    }
  }

  const exit = () => {

    if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {

      let analyticsObj = {
        action: 'redirect_url_received',
        status: status
      }

      saveEvent(analyticsObj);
    }

    /* Checking Android JSInterface method present or not */
    if (window.DigitapBs) {
      window.DigitapBs.onFinish(requestId, txnId, status);
      sessionStorage.clear()
      localStorage.clear()
    }
    /* Checking iOS Message handler present or not */
    else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.DigitapBs) {
      window.webkit.messageHandlers.DigitapBs.postMessage({
        "request_id": requestId,
        "txn_id": txnId,
        "status": status
      });
      sessionStorage.clear()
      localStorage.clear()
    }
    else {
      /* Checking returnUrl fetched already from Firebase */
      if (!isNullOrEmpty(returnUrl)) {
        let url = getFormattedReturnUrl(returnUrl);
        window.location.href = url;      //Redirecting to the return url page
        sessionStorage.clear()
        localStorage.clear()
      }
      else {
        /* Getting returnUrl value from Firebase */
        if (!isNullOrEmpty(txnId)) {
          var clientRef = databaseRef.child(txnId);
          var returnUrlObj = clientRef.child("client_data");
          var rUrl = "";
          returnUrlObj.on('value', snap => {
            if (snap.val() != null)
              rUrl = snap.val().return_url;

            sessionStorage.setItem('return_url', rUrl);

            if (!isNullOrEmpty(rUrl)) {
              let url = getFormattedReturnUrl(rUrl);
              window.location.href = url;     //Redirecting to the return url page
            }
            else {
              checkIframeAndRedirect(); // Checking client loaded in iframe or not
              //history.push("/wrongurl");   //Redirecting to PageNotFound page
            }
          });
        }
        else {
          history.push("/wrongurl");
        }
      }
    }
  }

  const checkIframeAndRedirect = () => {
    const isIframe = (window.self !== window.top);
    if (isIframe) {
      window.parent.postMessage({ "request_id": requestId, "txn_id": txnId, "status": status }, '*');
    }
    else {
      history.push("/wrongurl");
    }
  }

  useEffect(() => {
    setShowCancel("false");
    /* Checking Token Valid or not */
    if (tokenValid === "false") {
      exitFromFlow();   //Exit from the digitap flow
    }
    else {
      /* txncomplete api call */
      const data = {
        txn_id: txnId,
        txn_status_code: statusCode,
        ui_error_desc: uiErrorDesc,
        sender: "frontend"
      }

      txnComplete(data, history, exitFromFlow);

    }
  }, []);
  return (
    <div>
      <div className="normal-card">
        <Loader loaderStyle={getLoaderStyle()} />
      </div>
    </div>
  );
};

export default withRouter(TxnComplete);
