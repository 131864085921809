import React, { useState, useEffect } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";

import Terms from "../include/Terms";
import { Radio } from "../../utils/Radio";
//import { GAEvent } from '../../utils/ganalytcs';
import { dispContent, hideContent, secondaryButtonTheme, primaryButtonTheme, textColorTheme, fontRegular, fontBold, fontMedium, errorMsgStyle, inputStyle, centerContent, bottomDivStyle, divBgStyle, eyeOffStyle, eyeOnStyle, backgroundTheme, popupStyle } from '../../utils/AppStyle';
import { isNullOrEmpty } from "../../utils/AppUtil";
import { VerifyCredential, getInstitutionList } from "../../utils/NetworkUtil";

import { getBankIconPath, getDropDownIconPath, getLockIconpath, getUserNameIconPath, getPasswordIconPath, getCloseIconPath, axisBankInstitutionId, getTCRequired, getLockIconBgColor, getHomeInputStyle, getUserActionBtnStyle, userActionBtnStyleText, getNetbankingHomeInfoTitleText, getNetbankingHomeInfoDescText, homeInputStyleIcon, getHomePrimaryBtnText, getTCText, getBankLogo, getAnalyticsEnabled, getAnalyticsCallbackRequired } from '../../Config';

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { userNameErr, passwordErr, userNameHint, passwordHint, selectBankHint, selectedBankHint, bankErr, termsTitle, statusCodeUserCancelled, btnCancel, bankTypeHint, bankTypeErr } from "../../utils/Strings";
import { PopUp } from "../../utils/PopUp";
import ReactHtmlParser from 'react-html-parser';
import { field_names, saveEvent, saveFieldErrorEvent, screen_names, setScreenName } from "../../utils/Analytics";

const NetbankingHome = ({ setShowCancel }) => {
    let history = useHistory();
    const [type, setType] = useState('password');
    const [eyeOnDisp, setEyeOnDisp] = useState(eyeOffStyle);
    const [eyeOffDisp, setEyeOffDisp] = useState(eyeOnStyle);
    const [eyeDivDisp, setEyeDivDisp] = useState(hideContent);
    const [userNameText, setUserNameText] = useState(userNameHint);
    const [passwordText, setPasswordText] = useState(passwordHint);
    const [userNameErrMsg, setUserNameErrMsg] = useState(userNameErr);
    const [passwordErrMsg, setPasswordErrMsg] = useState(passwordErr);
    const [bankHint, setBankHint] = useState(selectBankHint);
    const [showTc, setShowTc] = useState(false);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isUserNameError, setUserNameError] = useState(false);
    const [isBankError, setBankError] = useState(false);
    const [isBankTypeError, setBankTypeError] = useState(false);
    const [isPasswordError, setPasswordError] = useState(false);
    const [institutionId, setBankId] = useState(-1);
    const [institutionIdGiven, setInstitutionIdGiven] = useState(sessionStorage.getItem('institution_id_given'));
    const [bankList, setBankList] = useState([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [note, setNote] = useState("");
    const [msgText, setMsgText] = useState("");
    const [userNameRegex, setUserNameRegex] = useState("");
    const [nbApplicable, setNbApplicable] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [bankName, setBankName] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [bankType, setBankType] = useState("");
    const [axisOmniDisplay, setAxisOmniDisplay] = useState(false);
    const [scName, setScName] = useState(screen_names.nb_home);

    useEffect(() => {

        setShowCancel("false");
        let txnId = '';
        let token = '';
        let institutionId = '';
        token = sessionStorage.getItem('token');
        txnId = sessionStorage.getItem('txn_id');
        institutionId = sessionStorage.getItem('institution_id');

        if (institutionIdGiven == "true") {
            setBankHint(selectedBankHint);
        }
        else {
            setBankHint(selectBankHint);
        }

        /* Getting the bank list for dropdown */

        const institutionListCb = (response) => {
            let bankData = [];
            let resData = response.data.data;
            for (let i = 0; i < resData.length; i++) {
                bankData.push({
                    "id": resData[i].id,
                    "name": resData[i].name,
                    "username_text": resData[i].username_text,
                    "password_text": (resData[i].password_text != null) ? resData[i].password_text : "Password",
                    "display_name": resData[i].display_name,
                    "msg_text": resData[i].msg_text,
                    "is_nb_applicable": resData[i].is_nb_applicable,
                    "username_regex": resData[i].username_regex,
                    "code": resData[i].code
                });
            }
            setBankList(bankData);

            /* Selecting the bank automatically based on the institution id in the URL */
            bankData.findIndex((item) => {
                if (item.id == institutionId) {
                    setBankId(institutionId);
                    setBankType("")
                    if (institutionId == axisBankInstitutionId) {
                        setBankType("axis_bank_omni")
                    }
                    sessionStorage.setItem("bank_name", item.name);
                    sessionStorage.setItem("bank_code", item.code);
                    setUserNameText(item.username_text);
                    setPasswordText(item.password_text);
                    setNote(item.display_name);
                    setMsgText(item.msg_text);
                    setNbApplicable(item.is_nb_applicable);
                    setBankName(item.name);
                    setBankCode(item.code);
                    setUserNameRegex(item.username_regex);
                }
            })

            saveAnalyticsEvent("shown", "", "");
        }

        getInstitutionList(history, institutionListCb);

    }, []);

    /* When Choose the bank, Username and password text changing based on the selected bank */
    const handleBankSelect = (event) => {
        let institution_id = event.target.value;
        setBankId(institution_id);
        setUserName("")
        setPassword("")
        setUserNameError(false)
        setPasswordError(false)
        setBankType("")
        if (institution_id == axisBankInstitutionId) {
            setBankType("axis_bank_omni")
        }
        if (institution_id != -1) {
            setBankError(false);
            bankList.findIndex((item) => {
                if (item.id == institution_id) {
                    sessionStorage.setItem('institution_id', institution_id);
                    sessionStorage.setItem("bank_name", item.name);
                    sessionStorage.setItem("bank_code", item.code);
                    setUserNameText(item.username_text);
                    setPasswordText(item.password_text);
                    setBankName(item.name);
                    setBankCode(item.code);
                    setNote(item.display_name);
                    setMsgText(item.msg_text);
                    setNbApplicable(item.is_nb_applicable);
                    setUserNameRegex(item.username_regex);
                }
            })
            saveAnalyticsEvent('field_selected', field_names.bank, "");
        }
        else {
            setNote("");
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setUserName') {
            setUserName(value);
        }
        else if (nt === 'setPassword') {
            setPassword(value);
            if (value.length > 0) {
                setEyeDivDisp(dispContent);
            }
            else {
                setEyeDivDisp(hideContent);
            }
        }
    }

    const handleFocus = (e) => {
        let nt = e.target.name;
        if (nt === 'setUserName') {
            saveAnalyticsEvent('field_selected', field_names.username, "");
        }
        else if (nt === 'setPassword') {
            saveAnalyticsEvent('field_selected', field_names.password, "");
        }
    }

    const handleBlur = (e) => {
        let nt = e.target.name;
        if (nt === 'setUserName') {
            if (userName.trim().length > 0) {
                saveAnalyticsEvent('field_entered', field_names.username, "");
            }
        }
        else if (nt === 'setPassword') {
            if (password.trim().length > 0) {
                saveAnalyticsEvent('field_entered', field_names.password, "");
            }
        }
    }

    /* When pressed enter automatically Submit */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    /* When clicked on Submit */
    const handleSubmit = () => {

        //GAEvent("User","button clicked","Submit");

        if (!isSubmitted) {
            saveAnalyticsEvent('continue_clicked', "", "");
            var valid = true;
            /* Validations */
            if (isNullOrEmpty(userName)) {
                setUserNameError(true)
                setUserNameErrMsg("Please enter your " + userNameText);
                saveFieldErrorEvent(scName, field_names.username, "Please enter your " + userNameText);
                valid = false;
            }
            else if ((userNameRegex != undefined) && (userNameRegex != "")) {

                var userNameValid = true;
                try {
                    userNameValid = new RegExp(userNameRegex).test(userName);
                }
                catch (err) {
                    console.log("error message:" + err.message);
                }
                if (userNameValid == false) {
                    setUserNameError(true)
                    setUserNameErrMsg("Please enter valid " + userNameText);
                    saveFieldErrorEvent(scName, field_names.username, "Please enter valid " + userNameText);
                    valid = false;
                }
            }
            else {
                setUserNameError(false)
            }
            if (isNullOrEmpty(password)) {
                setPasswordError(true)
                setPasswordErrMsg("Please enter your " + passwordText);
                saveFieldErrorEvent(scName, field_names.password, "Please enter your " + passwordText);
                valid = false;
            }
            else {
                setPasswordError(false)
            }
            if (institutionId == -1) {
                setBankError(true);
                saveFieldErrorEvent(scName, field_names.bank, bankErr);
                valid = false;
            }
            else {
                setBankError(false)
            }
            if ((axisOmniDisplay) && (institutionId == axisBankInstitutionId) && (bankType == "")) {
                setBankTypeError(true)
                valid = false;
            }
            else {
                setBankTypeError(false)
            }
            if (valid) {
                if ((!isNullOrEmpty(msgText)) && (nbApplicable == true)) {
                    setShowPopup(true);
                }
                else {
                    handleNext();
                }
            }
        }
    }

    const saveAnalyticsEvent = (action, fieldType, toggleType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }
            if (!isNullOrEmpty(toggleType)) {
                analyticsObj.type = toggleType
            }

            saveEvent(analyticsObj);
        }
    }

    const handleNext = () => {
        var txnId = sessionStorage.getItem("txnId");
        saveAnalyticsEvent('continue_clicked', "", "");
        setSubmitted(true);
        setUserNameError(false)
        setPasswordError(false)
        setBankError(false)
        VerifyCredential(userName, password, institutionId, bankType, txnId, history, handleNextCb);
    }


    const handleNextCb = (response) => {
        setScreenName(scName);
        history.push("/process");
    }

    /* Showing Terms and conditions popup */
    const showTcFunc = () => {
        setShowTc(true);
    }

    /* Hiding Terms and conditions popup */
    const hideTcFunc = () => {
        setShowTc(false);
    }

    /* Eye iocn on and off */
    const handleClick = () => {
        setEyeOnDisp(eyeOnDisp === eyeOffStyle ? eyeOnStyle : eyeOffStyle);
        setEyeOffDisp(eyeOffDisp === eyeOnStyle ? eyeOffStyle : eyeOnStyle);
        setType(type === 'text' ? 'password' : 'text');
        saveAnalyticsEvent('password_toggle_clicked', "", (type === 'text') ? 'hide' : 'show');
    }

    /* When cancel button clicked */
    const handleCancel = () => {

        if (!isCancelled) {
            setCancelled(true);

            saveAnalyticsEvent('cancel_clicked', "", "");

            sessionStorage.setItem('status_code', statusCodeUserCancelled);

            sessionStorage.setItem('status', false);

            setScreenName(scName);
            history.push("/txncomplete");
        }
    }

    const handleBankType = (value) => {
        setBankType(value);
        setBankTypeError(false);
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    <div style={{ display: 'flex', borderRadius: '10px' }}>
                        <div className="homeLockIconDiv" style={{ backgroundColor: getLockIconBgColor(), alignItems: 'center', verticalAlign: 'middle' }}>
                            <img src={getLockIconpath()} className="homeLockIcon" alt="lock" />
                        </div>
                        <div className="homeInfo" style={divBgStyle}>
                            <h2 className="infoTitleStyle" style={textColorTheme}>{getNetbankingHomeInfoTitleText()} </h2>
                            <p className="infoTextStyle" style={fontRegular}>{getNetbankingHomeInfoDescText()}</p>
                        </div>
                    </div>
                    <div style={{ paddingTop: '15px' }}>
                        <div>
                            <div>
                                {
                                    (institutionIdGiven === "true") ?
                                        <div style={divBgStyle} className="bank-name-div">
                                            <img className="bank-logo" src={getBankLogo(bankCode)} />
                                            <span className="bank-name" style={fontMedium}>{bankName}</span>
                                        </div>
                                        :
                                        (getHomeInputStyle() == homeInputStyleIcon) ?
                                            <div>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                                    <span className="homeTextFieldTitle" style={(isBankError == true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                </div>
                                                <div className={(isBankError == true) ? "input-div-err-style" : "input-div-style"}>
                                                    <select className="input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                        <option value="-1">Select bank</option>
                                                        {bankList.map((item) => {
                                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                                        })}
                                                    </select>
                                                    <div className="float-right-icon">
                                                        <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                    </div>
                                                </div>
                                            </div> :
                                            <div>
                                                <div style={{ marginBottom: '10px' }}>
                                                    <span className="homeTextFieldTitle" style={(isBankError == true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                </div>
                                                <div>
                                                    <div style={{ position: 'relative' }}>
                                                        <select className="action-input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                            <option value="-1">Select bank</option>
                                                            {bankList.map((item) => {
                                                                return <option key={item.id} value={item.id}>{item.name}</option>
                                                            })}
                                                        </select>

                                                        <div className="float-right-icon">
                                                            <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                }
                                {
                                    ((!isNullOrEmpty(note)) && (bankName != note)) ?
                                        <div>
                                            <span className="institution_note_heading" style={fontBold}>Note : </span>
                                            <span className="institution_note" style={fontBold}>{note}</span>
                                        </div>
                                        : null
                                }
                                {
                                    (isBankError == true) ?
                                        <span className="error-msg" style={errorMsgStyle}>{bankErr}</span> : null
                                }
                            </div>
                        </div>
                        {
                            ((institutionId == axisBankInstitutionId) && (axisOmniDisplay)) ?
                                <div style={{ paddingTop: '15px' }}>
                                    <div>
                                        <img src={getBankIconPath()} className="homeIcon" alt="bank" />

                                        <span className="homeTextFieldTitle" style={fontMedium}>{(isBankTypeError == true) ? bankTypeHint : fontMedium}</span>

                                    </div>
                                    <div style={{ marginBottom: "-10px" }}>
                                        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                                            <Radio
                                                value="axis_bank"
                                                selected={bankType}
                                                text="Axis"
                                                onChange={handleBankType}
                                            />
                                        </div>
                                        <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                                            <Radio
                                                value="axis_bank_omni"
                                                selected={bankType}
                                                text="Axis Omni"
                                                onChange={handleBankType}
                                            />
                                        </div>
                                    </div>
                                    {
                                        (isBankTypeError === true) ?
                                            <span className="error-msg" style={errorMsgStyle}>{bankTypeErr}</span> : null
                                    }
                                </div> : null
                        }
                        <div>
                            <br />
                            <div className="card-action-title" style={fontBold}>Enter Net Banking Details</div>
                            <div style={{ paddingTop: '15px' }}>
                                {
                                    (getHomeInputStyle() == homeInputStyleIcon) ?

                                        <div>
                                            <div>
                                                <img src={getUserNameIconPath()} className="homeIcon" alt="username" />
                                                <span className="homeTextFieldTitle" style={(isUserNameError == true) ? errorMsgStyle : fontMedium}>{userNameText}</span>

                                            </div>
                                            <div className={(isUserNameError == true) ? "input-div-err-style" : "input-div-style"}>
                                                <input className="input-style" autoComplete="off" autoCorrect="off" spellCheck="false" placeholder={userNameText} style={inputStyle} value={userName} name='setUserName' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span className="homeTextFieldTitle" style={(isUserNameError == true) ? errorMsgStyle : fontMedium}>{userNameText}</span>
                                            </div>
                                            <div>
                                                <input type="text" className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" style={inputStyle} placeholder={userNameText} value={userName} name='setUserName' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                            </div>
                                        </div>
                                }
                                {
                                    (isUserNameError == true) ?
                                        <span className="error-msg" style={errorMsgStyle}>{userNameErrMsg}</span> : null
                                }
                            </div>
                            <div style={{ paddingTop: '15px' }}>
                                {
                                    (getHomeInputStyle() == homeInputStyleIcon) ?
                                        <div>
                                            <div>
                                                <img src={getPasswordIconPath()} className="homeIcon" alt="password" />

                                                <span className="homeTextFieldTitle" style={(isPasswordError === true) ? errorMsgStyle : fontMedium}>{passwordText}</span>

                                            </div>
                                            <div className={(isPasswordError == true) ? "input-div-err-style" : "input-div-style"}>
                                                <input className="input-style" autoComplete="off" autoCorrect="off" spellCheck="false" type={type} placeholder={passwordText} style={inputStyle} value={password} name='setPassword' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                                <div className="float-right-icon" style={eyeDivDisp}>
                                                    <FaEyeSlash style={eyeOffDisp} onClick={handleClick}></FaEyeSlash>
                                                    <FaEye style={eyeOnDisp} onClick={handleClick}></FaEye>
                                                </div>
                                            </div>
                                        </div> :
                                        <div>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span className="homeTextFieldTitle" style={(isPasswordError === true) ? errorMsgStyle : fontMedium}>{passwordText}</span>
                                            </div>
                                            <div>
                                                <div style={{ position: 'relative' }}>
                                                    <input className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" type={type} placeholder={passwordText} style={inputStyle} value={password} name='setPassword' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                                    <div className="float-right-icon" style={eyeDivDisp}>
                                                        <FaEyeSlash style={eyeOffDisp} onClick={handleClick}></FaEyeSlash>
                                                        <FaEye style={eyeOnDisp} onClick={handleClick}></FaEye>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    (isPasswordError == true) ?
                                        <span className="error-msg" style={errorMsgStyle}>{passwordErrMsg}</span> : null
                                }
                            </div>
                            {
                                (getTCRequired()) ?

                                    <div style={{ paddingTop: '15px' }}>
                                        <span className="termsStyle" style={fontMedium}>{getTCText()} </span><span onClick={showTcFunc} className="tcStyle" style={fontMedium}>T & C</span>
                                    </div>
                                    : <></>
                            }
                            <br />
                            {
                                (getUserActionBtnStyle() == userActionBtnStyleText) ?
                                    <div className="bottomTextDiv" style={bottomDivStyle}>
                                        <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{getHomePrimaryBtnText()}</button>
                                        <div className="cancel-btn-div">
                                            <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{getHomePrimaryBtnText()}</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                (showTc === true) ?
                    <div className='popup' style={fontRegular}>
                        <div className='popup_inner' style={popupStyle}>
                            <div className="popup_header" style={popupStyle}>
                                <img src={getCloseIconPath()} className="close-icon" onClick={hideTcFunc} alt="close" />
                                <h5 style={fontBold}>{termsTitle}</h5>
                            </div>
                            <Terms />
                        </div>
                    </div> : null
            }
            {
                (showPopup == true) ?
                    <PopUp
                        onPrimaryBtnClick={handleNext}
                        primaryBtnText="Ok"
                        titleText="Note"
                        bodyText={ReactHtmlParser(msgText)} /> : null}
        </div>
    );
};

export default withRouter(NetbankingHome);