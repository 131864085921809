// Tooltip.js
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const TooltipText = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  width: 250px; /* Adjust as needed */
  background-color: white;
  color: ${props => props.textColor || '#f5a623'};
  text-align: center;
  border-radius: 5px;
  border: 1px solid ${props => props.textColor || '#f5a623'};
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 30px; /* Positioning the tooltip below the icon */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.9;
  transition: opacity 0.3s;

  ::after {
    content: '';
    position: absolute;
    top: -5px; /* Arrow at the top */
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
`;

const MessageTooltip = ({ children, text, type }) => {
  const [show, setShow] = useState(true);

  return (
    <TooltipContainer
      onClick={() => setShow(!show)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {children}
      <TooltipText show={show} textColor={(type=="error") ? "#E41D1D" : "#FCA004"}>{text}</TooltipText>
    </TooltipContainer>
  );
};

export default MessageTooltip;
