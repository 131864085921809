import React, { useState, useEffect } from "react";

import { fontMedium } from '../../utils/AppStyle';
import { isNullOrEmpty, toFirstCapital } from "../../utils/AppUtil";
import { initiateAAConsent } from "../../utils/NetworkUtil";

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { aaBackPressedErrorCode } from "../../utils/Strings";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle } from "../../Config";
import { saveProcessingEvent, screen_names } from "../../utils/Analytics";

const AAProcessing = ({ setShowCancel }) => {
    let history = useHistory();
    const [aaMobile, setAAMobile] = useState(sessionStorage.getItem('aa_mobile'));
    const [vua, setVua] = useState(sessionStorage.getItem('aa_vua'));

    useEffect(() => {
        console.log("properRedirection:" + sessionStorage.getItem('proper_redirection'));
        if (sessionStorage.getItem('proper_redirection') == "true") {
            setShowCancel("false");
            saveProcessingEvent(screen_names.processing, "", (!isNullOrEmpty(vua)) ? ("We are checking your registration status with " + toFirstCapital(vua) + " Account Aggregator (RBI Licensed). Please do not close or go back.") : "");
            handleNext();
        }
        else {
            sessionStorage.setItem('status_code', aaBackPressedErrorCode);
            sessionStorage.setItem('status', false);
            history.push("/txncomplete");
        }
    }, []);

    const handleNext = () => {
        var txnId = sessionStorage.getItem("txn_id");
        initiateAAConsent(aaMobile, aaMobile + "@" + vua, txnId, history, handleNextCb);
    }

    const handleNextCb = (response) => {
        window.location.href = response.data.data.redirection_url;
        sessionStorage.setItem("proper_redirection", "false");
    }

    return (
        <div>


            <div className="normal-card">
                <Loader loaderStyle={getLoaderStyle()} />
                <br /><br />
                {
                    (!isNullOrEmpty(vua)) ? <div className="card-msg" style={fontMedium}>We are checking your registration status with {toFirstCapital(vua)} Account Aggregator (RBI Licensed). Please do not close or go back.</div> : null
                }
                <br />
                {/* <div className="card-msg" style={fontMedium}>
                        {processingDesc}
                    </div> */}
            </div>

        </div>
    );
};

export default withRouter(AAProcessing);
