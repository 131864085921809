export const tokenExpiredMsg = "Session Expired";
export const userNameErr = 'Please enter your User Name';
export const aaUpiIdErr = 'Please enter your AA ID';
export const passwordErr = 'Please enter your Password';
export const bankErr = 'Please choose the bank';
export const bankTypeErr = 'Please choose the bank type';
export const aaErr = 'Please choose the Account Aggregator';
export const accountErr = 'Please choose the account';
export const destinationErr = 'Please choose any option';
export const userNameHint = 'User Name';
export const aaUpiIdHint = 'AA ID';
export const aaUpiIdHintNewUser = 'Mobile Number';
export const aaUpiIdHint1 = 'AA ID prefix, e.g. "7893024244"';
export const aaUpiIdHint1NewUser = 'Mobile No, e.g. "7893024244"';
export const passwordHint = 'Password';
export const selectBankHint = 'Select a bank';
export const selectedBankHint = 'Selected bank';
export const selectAAHint = 'Select Account Aggregator';
export const selectedAAHint = 'Selected Account Aggregator';
export const bankTypeHint = 'Choose bank type';
export const inputHint = 'Response';
export const inputHintPassword = "Enter Password to Unlock Statements";
export const userActionRequired = 'User Action required!';
export const passwordDesc = 'e-mail statements are password protected. Please provide the password.';
export const pdfPasswordDesc = 'Your statement is password protected. Please provide the password.';
export const tokenDesc = 'Please enter Token';
export const hardTokenDesc = 'Please enter Secure Code from your Token related to';
export const mobileDeviceDesc = 'Please enter Secure Code from your Mobile Device related to';

export const otpTypeMobileDevice = "Mobile device";
export const otpTypeHardToken = "Hard token";


export const maintenanceTitle = "Site is currently under Maintenance.";
export const maintenanceDesc = "We are busy updating the software for you. We expect to be back shortly. Please retry again in a few minutes. We regret the inconvenience.";
export const pageNotFoundTitle = "Page Not Found";
export const pageNotFoundDesc = "Requested Page Not Found";
export const sessionTimeoutCode = "SessionTimeOut";
export const sessionTimeoutTitle = "Session Timeout";
export const sessionTimeoutDesc = "Timeout. Please Try Again";
export const internalErrorCode = "UIInternalError";
export const internalErrorTitle = "Internal Error";

export const networkErrorCode = "NetworkError";
export const networkErrorTitle = "Network Error";
export const aaBackPressedErrorCode = "AABackPressError";

export const stmtProcessingDesc = "All months statements accepted and uploaded successfully.\nPlease wait while we are analysing your transactions.";
export const otpTimeoutCode = "OTPTimeout";
export const otpTimeoutTitle = "OTP Timeout";
export const otpTimeoutDesc = "OTP Not entered with in the time given by bank";
export const otpError = "Please enter valid otp";
export const quesError = "Please enter question";
export const passwordError = "Please enter password";
export const captchaError = "Please enter captcha";
export const expressionError = "Please enter expression value";
export const pdfSupportError = "Supports only pdf";
export const pdfSizeMaxError = "Please upload a file smaller than 10 MB. Also, please ensure to upload ONLY original PDF Statements received from bank and NOT Scanned PDF Statements.";
export const pdfSizeMinError = "Please upload a file with size greater than 5 bytes.";
export const pdfChooseError = "Choose File";

export const stmtSubmitNoFileError = "Atleast one file should be processed to continue further";
export const stmtCancelError = `If you click on 'Yes', then you cannot further upload statements.

 If you click on 'No', then you should upload at least one correct bank statement.`;
export const stmtSubmitError = `If you click on 'Yes', the pdfs with errors won't be processed further.

 If you click on 'No', you can either delete these files or re-upload correct statements.`;
export const maxAllowedFilesError = "Max number of statements allowed : ";

export const pdfLocationError = "Unable to access the file, please choose the file from different storage location. (If you are accessing file from Google Drive, please download it to the internal storage and upload)";

export const statusCodeUserCancelled = 'UserCancelled';
export const statusCodeUserInteractionCompleted = 'UserInteractionCompleted';
export const statusCodeTxnAbort = "TxnAbort";
export const statusCodeReportGenerated = "ReportGenerated";
export const statusCodeUIAccountRecheckError = "UIAccountRecheckError";
export const statusCodeNoFilePassword = "NoFilePassword";
export const statusCodeInvalidPassword = "InvalidFilePassword";
export const statusCodeStatementDateRange = "StatementDateRange";
export const statusCodeEmailDownloadComplete = "EmailDownloadComplete";
export const statusCodeCategoryProcessing = 'CategoryProcessing';
export const statusCodeTxnDateRange = 'TxnDateRange';
export const statusCodeTxnPerMonthRange = 'TxnPerMonthRange';
export const statusCodeTxnPerMonthError = 'TxnPerMonthError';
export const statusCodeInvalidPdfType = 'InvalidPdfType';
export const statusCodeTxnTimeout = "TransactionTimeout";
export const statusCodeCategoryTimeout = "CategoryTimeout";
export const statusCodeAAFINotificationTimeout = "AAFINotificationTimeout";

export const statusTypeFailure = "Failure";
export const statusTypeError = 'Error';

export const captchaTypeMathematical = "mathematical";


export const errMsg1 = "This generated URL has been expired or invalid!! Please generate again and proceed.";
export const errMsg2 = "This generated URL has been used!! Please generate again and proceed.";
export const errMsg3 = "Transaction is under process. Please try after sometime.";
export const errMsg4 = "Some error. Please try after some time";

export const termsTitle = 'Terms and Conditions';

export const aaInfoTitle = 'About AA';

export const btnNext = "Next";
export const btnFinish = "Finish";
export const btnSubmit = "Submit";
export const btnCancel = "Cancel";
export const btnContinue = "Continue";
export const btnTryAgain = "Try Again";
export const btnTryAnother = "Try Another";
export const btnUploadAnother = "Upload Another";
export const btnTakeMeback = "Take me Back";
export const btnRetry = "Re-try";
export const btnUpload = "Upload";
export const btnreportType = "Report Type";
export const btnFileType = "File Type";
export const fileTypeJson = "JSON";
export const fileTypeXlsx = "XLSX";
export const btnViewReport = "View Report";
export const btnRawReport = "Raw";
export const btnType3Report = "Type3";
export const btnType4Report = "Type4";
export const btnConfirm = "Confirm";
export const btnYes = "Yes";
export const btnNo = "No";

export const btnNetBanking = "Verify via Net Banking";
export const btnPdfUpload = "Upload Bank Statements PDF";
export const btnEmailStatement = "Verify via Email Login";
export const btnAccountAggregator = "Verify via Account Aggregator";

export const inputHintPasswordUnlock = "Enter Password to Unlock Pdf";
export const processUploading = "Uploading";
export const listOfFilesTitle = "Uploaded Statements Summary";

export const destinationChoice = "choice";
export const destinationNetbanking = "netbanking";
export const destinationPdfUpload = "statementupload";
export const destinationEmailStatement = "emailstatement";
export const destinationAccountAggregator = "accountaggregator";

export const emailTypeGmailId = "1";
export const emailTypeGmailText = "Gmail";

export const maintenanceStatusCode = "503";
export const tokenInvalidStatusCode = "403";

export const permissionRequired = "Permission required to continue further";

export const kotakInstitutionId = "5";
