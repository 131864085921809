import React, { useState, useEffect } from "react";
import { UserAction, UserCancelAction } from "../../utils/NetworkUtil";
import { isNullOrEmpty, toAllCapital, toCamelCase } from "../../utils/AppUtil";
import { Radio } from "../../utils/Radio";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontBold, fontMedium, fontRegular, bottomDivStyle, errorMsgStyle, lineStyle } from "../../utils/AppStyle";
import { accountErr, btnCancel, btnSubmit } from "../../utils/Strings";
import { getAccountIconPath, getUserActionIconsRequired, getUserActionBtnStyle, userActionBtnStyleText, getUserActionAccountTitle, getUserActionAccountnDesc, getMultiAccountStyle, multiAccountStyle2, getBankLogo, getScreenSecondaryTextColor, multiAccountStyle1, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { Radio1 } from "../../utils/Radio1";
import { screen_names, saveEvent, setScreenName, saveFieldErrorEvent, field_names } from "../../utils/Analytics";

function SubDivContent(item) {
    return (
        <div id={item.acc_num_info.id}>
            <div>
                <img className="bank-logo" src={getBankLogo(sessionStorage.getItem("bank_code"))} />
                <span className="bank-name" style={fontMedium}>
                    {!isNullOrEmpty(sessionStorage.getItem("bank_name")) ?
                        toAllCapital(sessionStorage.getItem("bank_name")) : ""
                    }
                </span>
            </div>
            <hr style={lineStyle} />
            <div>
                <span className="account-info" style={fontRegular}>{item.acc_num_info.account_type} a/c</span> : <span className="account-info" style={fontBold}>{item.acc_num_title}</span>
            </div>
            <div>
                <span className="account-info" style={fontRegular}>User's Name</span> : <span className="account-info" style={fontBold}>{item.acc_num_info.customer_name}</span>
            </div>
        </div>
    )
}

const AccountComponent = ({ setShowCancel }) => {
    let history = useHistory();

    //let accountDataFirebase = sessionStorage.getItem("account_num_data");//[{"acc_num_title": "202012349835 - Jangareddygudem","acc_num_info": {"account_number": "202012349835","branch_name" : "Jangareddygudem","customer_name": "TOKALA SAI KIRAN SAI KIRAN","balance": "20000.0","account_type": ""}},{"acc_num_title": "212112349835 - Bangalore","acc_num_info": {"account_number": "212112349835","branch_name": "Bangalore","customer_name": "KIRAN","balance": "0.0","account_type": "current"}}]';

    //let accountList = JSON.parse(accountDataFirebase);

    /* let accountList = [
        {
            'acc_num_title': '202012349835 - Jangareddygudem',
            'acc_num_info': {
                'account_number': '202012349835',
                'branch_name': 'Jangareddygudem',
                'customer_name': 'TOKALA SAI KIRAN SAI KIRAN',
                'balance': '20000.0',
                'account_type': ''
            }
        },
        {
            'acc_num_title': '212112349835 - Bangalore',
            'acc_num_info': {
                'account_number': '212112349835',
                'branch_name': 'Bangalore',
                'customer_name': 'KIRAN',
                'balance': '0.0',
                'account_type': 'current'
            }
        }
    ]; */

    const [userAccount, setUserAccount] = useState('');
    const [isAccountError, setAccountError] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [scName, setScName] = useState(screen_names.account);

    useEffect(() => {
        setShowCancel("false");
        setAccountList(JSON.parse(sessionStorage.getItem("account_num_data")));
        saveAnalyticsEvent("shown", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName,
                bank_account_count: (!isNullOrEmpty(accountList)) ? accountList.length : 0
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    /* Account number submit */
    const handleSubmit = () => {
        if (!isSubmitted) {
            saveAnalyticsEvent("submit_clicked", "");
            if (isNullOrEmpty(userAccount)) {
                setAccountError(true);
                saveFieldErrorEvent(scName, field_names.account, accountErr);
                return;
            }
            setSubmitted(true);
            setScreenName(scName);
            UserAction('account', userAccount + "", history);
        }
    }

    /* Cancel Pressed */
    const handleCancel = () => {
        if (!isCancelled) {
            setCancelled(true);
            saveAnalyticsEvent("cancel_clicked");
            setScreenName(scName);

            UserCancelAction('account', history);
        }
    }

    const handleChange = (value) => {
        //let value = e.target.value;
        setUserAccount(value);
        saveAnalyticsEvent("field_selected", field_names.account);
        setAccountError(false);
        if (getMultiAccountStyle() == multiAccountStyle1) {
            for (var i = 0; i < accountList.length; i++) {
                document.getElementById(accountList[i].acc_num_info.id).style.display = "none";
            }
            document.getElementById(value).style.display = "block";
        }
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    {
                        (getUserActionIconsRequired() == "true") ? <img className="user-action-icon" src={getAccountIconPath()} /> : <></>
                    }
                    <div className="card-action-title" style={fontBold}>{getUserActionAccountTitle()}</div>
                    <div className="card-action-msg" style={fontRegular}>
                        {getUserActionAccountnDesc()}
                    </div>
                    {
                        (!isNullOrEmpty(accountList)) ?

                            <div style={{ marginTop: '15px', maxHeight: "350px", overflow: "auto" }}>
                                {accountList.map((item) => {
                                    return (
                                        <div style={{ marginTop: '10px' }}>

                                            {/* <input type="radio" name="accountchoose" onChange={handleChange} value={item.acc_num_info.account_num} /> <span style={fontBold}>{item.acc_num_title}</span>
                                     */}

                                            {
                                                (getMultiAccountStyle() == multiAccountStyle2) ?
                                                    <div>
                                                        <Radio1
                                                            value={item.acc_num_info.id}
                                                            selected={userAccount}
                                                            text={<SubDivContent {...item} />}
                                                            border="true"
                                                            onChange={handleChange}
                                                            color={getScreenSecondaryTextColor()}
                                                        />
                                                        {/* <div id={item.acc_num_info.id} style={{ display: "none" }}>
                                                            <div className="bank-name-div">
                                                                <img className="bank-logo" src={getBankLogo(bankCode)} />
                                                                <span className="bank-name" style={fontMedium}>{bankName}</span>
                                                            </div>
                                                            <div>
                                                                <span className="account-info" style={fontRegular}>{item.acc_num_info.account_type} a/c</span> : <span className="account-info" style={fontMedium}>{item.acc_num_title}</span>
                                                            </div>
                                                            <div>
                                                                <span className="account-info" style={fontRegular}>User's Name</span> : <span className="account-info" style={fontMedium}>{item.acc_num_info.name}</span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    :

                                                    <div>
                                                        <Radio
                                                            value={item.acc_num_info.id}
                                                            selected={userAccount}
                                                            text={item.acc_num_title}
                                                            onChange={handleChange}
                                                        />
                                                        <div id={item.acc_num_info.id} style={{ display: "none" }}>
                                                            {
                                                                Object.keys(item.acc_num_info).map((d, key) => {
                                                                    return (

                                                                        (!isNullOrEmpty(item.acc_num_info[d]) && (d != "id")) ?
                                                                            <div style={{ marginLeft: '30px' }}>
                                                                                <p className="account-sub-heading" style={fontBold}>{toCamelCase(d)}</p> : <span className="account-info" style={fontRegular}>{item.acc_num_info[d]}</span>
                                                                            </div> : null

                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>)
                                })}
                            </div> : <></>}
                    {
                        (isAccountError === true) ?
                            <span className="error-msg" style={errorMsgStyle}>{accountErr}</span> : null
                    }
                    <br /><br />
                    {
                        (getUserActionBtnStyle() == userActionBtnStyleText) ?
                            <div className="bottomTextDiv" style={bottomDivStyle}>
                                <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button><br />
                                <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                            </div>
                            :
                            <div style={{ width: '100%' }}>
                                <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                            </div>}
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountComponent);