import React, { useState, useEffect } from "react";
import { getErrorIconPath } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { databaseRef } from '../../utils/firebase';
import { secondaryButtonTheme, primaryButtonTheme, fontMedium } from "../../utils/AppStyle";
import { btnContinue, btnTryAgain, statusCodeUserInteractionCompleted } from "../../utils/Strings";
import { isNullOrEmpty } from "../../utils/AppUtil";

const InfoComponent = ({ setShowCancel }) => {
  let history = useHistory();

  let infoTitle1 = sessionStorage.getItem('response_title');
  let infoMsg1 = sessionStorage.getItem('response_msg');

  let tokenValid = sessionStorage.getItem('token_valid');

  const [showButtons, setShowButtons] = useState(true);

  const [showTryAgain, setShowTryAgain] = useState(sessionStorage.getItem('show_try_again'));

  const [infoMsg, setInfoMsg] = useState(infoMsg1);

  const [infoTitle, setInfoTitle] = useState(infoTitle1);

  useEffect(() => {

    setShowCancel("false");

    if (tokenValid === "false") {
      setShowTryAgain("false");
    }
  }, []);

  const handleRetry = () => {
    history.push("/")
  }

  const handleNextScreen = () => {

    let txnId = sessionStorage.getItem("txn_id");
    /* Getting the client returnurl from the firebase */
    let returnUrl = sessionStorage.getItem('return_url');

    if (!isNullOrEmpty(returnUrl)) {
      openNextScreen();
    }
    else {
      const clientRef = databaseRef.child(txnId);
      var returnUrlObj = clientRef.child("client_data");
      returnUrlObj.on('value', snap => {
        if (snap.val() != null)
          returnUrl = snap.val().return_url;
        sessionStorage.setItem('return_url', returnUrl);
        openNextScreen();
      });
    }
  }

  const openNextScreen = () => {

    const returnUrl = sessionStorage.getItem('return_url');
    const showReport = sessionStorage.getItem("shw_rpt");

    const isIOS = (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.DigitapBs);
    const isAndroid = (window.DigitapBs);
    const isWeb = (!isNullOrEmpty(returnUrl));

    if ((isAndroid || isIOS || isWeb) && (showReport == "0")) {
      sessionStorage.setItem('status_code', statusCodeUserInteractionCompleted);
      sessionStorage.setItem('status', true);
      history.push("/txncomplete");
    }
    else {
      history.push("/success");
    }
  }

  return (
    <div>
      <div className="normal-card">
        <div style={{ marginTop: '30px' }}>
          <img src={getErrorIconPath()} className="respImgStyle" alt="error" />
          <br /><br />
          <div className="card-title" style={fontMedium}>{infoTitle}</div><br />
          <div className="card-msg" style={fontMedium}>
            {infoMsg}
          </div>
          <br />
          <br />
          <br />
          <br />
          {
            (showButtons === true) ?
              <div className='bottomDiv'>

                <button className='full-error-button' style={secondaryButtonTheme} onClick={handleRetry}>{btnTryAgain}</button>
                <button className="full-primary-button" style={primaryButtonTheme} onClick={handleNextScreen}>{btnContinue}</button>
              </div> :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default withRouter(InfoComponent);
