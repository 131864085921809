import React, { useState, useEffect } from "react";

import { isNullOrEmpty } from "../../utils/AppUtil";
import { Radio } from "../../utils/Radio";

import { getBankIconPath, getDropDownIconPath, getHomeInputStyle, getLoaderStyle, getProcessingDesc, getProcessingTitle, getUserActionBtnStyle, userActionBtnStyleText, getAnalyticsEnabled, getAnalyticsCallbackRequired } from '../../Config';

import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { selectBankHint, bankErr, btnAccountAggregator, btnEmailStatement, selectedBankHint, btnCancel, statusCodeUserCancelled } from "../../utils/Strings";

import { btnNetBanking, btnPdfUpload, btnContinue, destinationErr } from "../../utils/Strings";
import { primaryButtonTheme, fontMedium, fontBold, inputStyle, errorMsgStyle, secondaryButtonTheme, bottomDivStyle, fontRegular } from '../../utils/AppStyle';
import { destinationAccountAggregator, destinationEmailStatement, destinationNetbanking, destinationPdfUpload } from "../../utils/Strings";
import { getInstitutionList } from "../../utils/NetworkUtil";
import { Loader } from "../../utils/Loader";
import { field_names, saveEvent, saveFieldErrorEvent, screen_names, setScreenName } from "../../utils/Analytics";

const ChoiceHome = () => {
    let history = useHistory();
    const [isBankError, setBankError] = useState(false);
    const [bankHint, setBankHint] = useState(selectBankHint);
    const [bankList, setBankList] = useState([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);

    const [showDestination, setShowDestination] = useState(false)
    const [destination, setDestination] = useState("");
    const [destinationCount, setDestinationCount] = useState(0);

    const [nbEnabled, setNbEnabled] = useState(false);
    const [pdfEnabled, setPdfEnabled] = useState(false);
    const [aaEnabled, setAAEnabled] = useState(false);
    const [emailEnabled, setEmailEnabled] = useState(false);

    const [bankName, setBankName] = useState("");

    const [isDestinationError, setDestinationError] = useState(false);
    const [institutionId, setBankId] = useState(sessionStorage.getItem('institution_id'));
    const [institutionIdGiven, setInstitutionIdGiven] = useState(sessionStorage.getItem('institution_id_given'));
    const [scName, setScName] = useState(screen_names.choice_home);

    useEffect(() => {

        if (institutionIdGiven == "true") {
            setBankHint(selectedBankHint);
        }
        else {
            setBankHint(selectBankHint);
        }

        getInstitutionList(history, ICCallback);

    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    const ICCallback = (response) => {
        let bankData = [];
        let resData = response.data.data;
        for (let i = 0; i < resData.length; i++) {
            bankData.push({
                "id": resData[i].id,
                "name": resData[i].name,
                "netbanking_enabled": resData[i].netbanking_enabled,
                "pdf_enabled": resData[i].pdf_enabled,
                "aa_enabled": resData[i].aa_enabled,
                "email_enabled": resData[i].email_enabled
            });
        }
        setBankList(bankData);

        /* Selecting the bank automatically based on the institution id in the URL */
        bankData.findIndex((item) => {
            if (item.id == institutionId) {
                setBankId(institutionId);
                setBankName(item.name);
                sessionStorage.setItem("bank_name", item.name);
                handleDestination(item);
            }
        })

        saveAnalyticsEvent("shown", "");
    }

    const handleChange = (value) => {
        setDestination(value);
        setDestinationError(false);
        saveAnalyticsEvent('field_selected', field_names.destination);
    }

    const handleDestination = (item) => {
        var cou = 0;
        setShowDestination(true);
        setNbEnabled(item.netbanking_enabled);
        setPdfEnabled(item.pdf_enabled);
        setAAEnabled(item.aa_enabled);
        setEmailEnabled(item.email_enabled);
        if (item.netbanking_enabled) {
            cou = cou + 1;
        }
        if (item.pdf_enabled) {
            cou = cou + 1;
        }
        if (item.aa_enabled) {
            cou = cou + 1;
        }
        if (item.email_enabled) {
            cou = cou + 1;
        }
        setDestinationCount(cou);
    }

    /* When Choose the bank, Username and password text changing based on the selected bank */
    const handleBankSelect = (event) => {
        let institution_id = event.target.value;
        setBankId(institution_id);
        setDestination("");
        setDestinationError(false);
        setBankError(false);
        setShowDestination(false);
        if (institution_id !== -1) {
            bankList.findIndex((item) => {
                if (item.id == institution_id) {
                    sessionStorage.setItem("bank_name", item.name);
                    handleDestination(item);
                }
            })
            saveAnalyticsEvent('field_selected', field_names.bank);
        }
    }

    /* When clicked on Submit */
    const handleSubmit = () => {

        if (!isSubmitted) {

            saveAnalyticsEvent('continue_clicked', "");
            /* Checking all required values entered or not */
            if ((institutionId != -1) && (!isNullOrEmpty(destination))) {
                setSubmitted(true);
                setBankError(false);
                sessionStorage.setItem("institution_id_given", "true");
                sessionStorage.setItem('institution_id', institutionId);
                sessionStorage.setItem("destination", destination);
                //submictChoice(history, destination, institutionId, submitChoiceCb);
                submitChoiceCb();
            }
            else {
                if (institutionId == -1) {
                    setBankError(true)
                    saveFieldErrorEvent(scName, field_names.bank, bankErr);
                }
                else {
                    setBankError(false);

                    if (isNullOrEmpty(destination)) {
                        setDestinationError(true)
                        saveFieldErrorEvent(scName, field_names.destination, destinationErr);
                    }
                    else {
                        setDestinationError(false)
                    }
                }
            }
        }
    }

    const submitChoiceCb = () => {
        setScreenName(scName);
        if (destination == destinationPdfUpload) {
            sessionStorage.setItem('show_try_again', false);
            sessionStorage.setItem("show_finish", false);
            sessionStorage.setItem("show_view_files", false);
            history.push("/stmtuploadhome");
        }
        else if (destination == destinationEmailStatement) {
            sessionStorage.setItem('show_try_again', true);
            history.push("/emailstmthome");
        }
        else if (destination == destinationAccountAggregator) {
            sessionStorage.setItem('show_try_again', false);
            let aaMobile = sessionStorage.getItem("aa_mobile");
            let aaVua = sessionStorage.getItem("aa_vua");
            let aaMobileEditable = sessionStorage.getItem("aa_mobile_editable");
            if (!isNullOrEmpty(aaMobileEditable) && (aaMobileEditable == "2") && (!isNullOrEmpty(aaVua))) {
                history.push("/aaprocessing");
            }
            else if (!isNullOrEmpty(aaMobile)) {
                history.push("/aamobile");
            }
            else {
                history.push("/aahome");
            }
        }
        else {
            sessionStorage.setItem('show_try_again', true);
            history.push("/netbankinghome");
        }
    }

    /* When cancel button clicked */
    const handleCancel = () => {

        if (!isCancelled) {
            setCancelled(true);

            saveAnalyticsEvent('cancel_clicked', "");

            sessionStorage.setItem('status_code', statusCodeUserCancelled);

            sessionStorage.setItem('status', false);
            setScreenName(scName);
            history.push("/txncomplete");
        }
    }

    return (
        <div>
            <div className="action-card">
                {
                    (isSubmitted === true) ?
                        <div style={{ textAlign: 'center' }}>
                            <Loader loaderStyle={getLoaderStyle()} />
                            <br /><br />
                            <div>
                                <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                                <div className="card-msg" style={fontMedium}>
                                    {getProcessingDesc()}
                                </div>
                            </div>
                        </div>
                        :

                        <div>
                            <div className="card-action-title" style={fontBold}>Verify Income</div>
                            <div className="card-action-msg" style={fontRegular}>
                                Provide your bank details (Netbanking / Statements / Account Aggregator) to verify your income.
                            </div>
                            <div style={{ paddingTop: '20px' }}>
                                <div>
                                    <div>
                                        {
                                            (institutionIdGiven === "true") ? <div style={{ textAlign: 'center' }}><span style={fontMedium}>{bankName}</span></div>
                                                :
                                                (getHomeInputStyle() == "icon") ?
                                                    <div>
                                                        <div>
                                                            <img src={getBankIconPath()} className="homeIcon" alt="bank" />
                                                            <span className="homeTextFieldTitle" style={(isBankError == true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                        </div>
                                                        <div className={(isBankError == true) ? "input-div-err-style" : "input-div-style"}>
                                                            <select className="input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                                <option value="-1">Select bank</option>
                                                                {bankList.map((item) => {
                                                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                                                })}
                                                            </select>
                                                            <div className="float-right-icon">
                                                                <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    <div>
                                                        <div style={{ marginBottom: '10px' }}>
                                                            <span className="homeTextFieldTitle" style={(isBankError == true) ? errorMsgStyle : fontMedium}>{bankHint}</span>
                                                        </div>
                                                        <div>
                                                            <div style={{ position: 'relative' }}>
                                                                <select className="action-input-style" style={inputStyle} value={institutionId} name='setUserName' onChange={handleBankSelect} required>
                                                                    <option value="-1">Select bank</option>
                                                                    {bankList.map((item) => {
                                                                        return <option key={item.id} value={item.id}>{item.name}</option>
                                                                    })}
                                                                </select>

                                                                <div className="float-right-icon">
                                                                    <img src={getDropDownIconPath()} className="homeDropdownIcon" alt="dropdown" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                        }
                                        {
                                            (isBankError == true) ?
                                                <span className="error-msg" style={errorMsgStyle}>{bankErr}</span> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <br />
                            {
                                (showDestination == true) ?
                                    <div style={{ marginTop: '20px' }}>
                                        <div>
                                            {
                                                (destinationCount > 1) ?
                                                    <span className="destinationTitle" style={fontMedium}>Select an option to continue</span> : null
                                            }

                                        </div>
                                        <div>
                                            {
                                                (nbEnabled == true) ?
                                                    <Radio
                                                        value={destinationNetbanking}
                                                        selected={destination}
                                                        text={btnNetBanking}
                                                        onChange={handleChange}
                                                        border="true"
                                                    />
                                                    : null
                                            }
                                            {
                                                (pdfEnabled == true) ?
                                                    <Radio
                                                        value={destinationPdfUpload}
                                                        selected={destination}
                                                        text={btnPdfUpload}
                                                        onChange={handleChange}
                                                        border="true"
                                                    /> : null
                                            }
                                            {
                                                (emailEnabled == true) ?
                                                    <Radio
                                                        value={destinationEmailStatement}
                                                        selected={destination}
                                                        text={btnEmailStatement}
                                                        onChange={handleChange}
                                                        border="true"
                                                    /> : null
                                            }
                                            {
                                                (aaEnabled == true) ?
                                                    <Radio
                                                        value={destinationAccountAggregator}
                                                        selected={destination}
                                                        text={btnAccountAggregator}
                                                        onChange={handleChange}
                                                        border="true"
                                                    /> : null
                                            }
                                        </div>
                                        {
                                            (isDestinationError === true) ?
                                                <span className="error-msg" style={errorMsgStyle}>{destinationErr}</span> : null
                                        }
                                    </div> : null}
                            <br /><br /><br />
                            {
                                (getUserActionBtnStyle() == userActionBtnStyleText) ?
                                    <div className="bottomTextDiv" style={bottomDivStyle}>
                                        <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnContinue}</button>
                                        <div className="cancel-btn-div">
                                            <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnContinue}</button>
                                    </div>
                            }
                        </div>}
            </div>
        </div>
    );
};

export default withRouter(ChoiceHome);