import React, { useEffect } from "react";
import { getMaintenanceImagePath } from '../../Config';
import { withRouter } from "react-router";
import { maintenanceTitle, maintenanceDesc } from "../../utils/Strings";
import { fontMedium } from "../../utils/AppStyle";

const MaintenancePage = ({ setShowCancel }) => {

    useEffect(() => {
        setShowCancel("false");
    }, []);

    return (
        <div>
            <div className="normal-card">
                <div style={{ marginTop: '30px' }}>
                    <div className="card-title" style={fontMedium}>{maintenanceTitle}</div><br />
                    <img src={getMaintenanceImagePath()} className="maintenanceImgStyle" />
                    <br /><br />
                    <div className="card-msg" style={fontMedium}>
                        {maintenanceDesc}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(MaintenancePage);
