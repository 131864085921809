import React, { useState, useEffect } from "react";
import { UserAction, UserCancelAction } from "../../utils/NetworkUtil";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { secondaryButtonTheme, primaryButtonTheme, fontBold, fontMedium, inputStyle, errorMsgStyle, bottomDivStyle, fontRegular } from "../../utils/AppStyle";
import { quesError, inputHint, btnCancel, btnSubmit } from "../../utils/Strings";
import { getQuestionIconPath, getUserActionIconsRequired, getUserActionBtnStyle, userActionBtnStyleText, getUserActionQuestionTitle, getUserActionQuestionDesc, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { screen_names, saveEvent, setScreenName, saveFieldErrorEvent, field_names } from "../../utils/Analytics";

const QuestionComponent = ({ setShowCancel }) => {
    let history = useHistory();

    const bankName = sessionStorage.getItem('bank_name');

    const isError = sessionStorage.getItem('error');
    const txn_msg = sessionStorage.getItem('response_msg');
    var userQuestion = sessionStorage.getItem("question");
    const [userAnswer, setUserAnswer] = useState('');
    const [isQuesError, setIsQuesError] = useState(isError);
    const [errMsg, setErrMsg] = useState(txn_msg);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isCancelled, setCancelled] = useState(false);
    const [scName, setScName] = useState(screen_names.question);

    useEffect(() => {
        setShowCancel("false");

        saveAnalyticsEvent("shown", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: scName
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    /* Question submit */
    const handleSubmit = () => {
        if (!isSubmitted) {
            saveAnalyticsEvent("submit_clicked", "");
            if (isNullOrEmpty(userAnswer)) {
                setIsQuesError(true);
                setErrMsg(quesError);
                saveFieldErrorEvent(scName, field_names.question, quesError);
                return;
            }
            setSubmitted(true);
            setScreenName(scName);
            UserAction('question', userAnswer, history);
        }
    }

    /* Cancel Pressed */
    const handleCancel = () => {
        if (!isCancelled) {
            setCancelled(true);
            saveAnalyticsEvent("cancel_clicked", "");
            setScreenName(scName);
            UserCancelAction('question', history);
        }
    }

    const handleChange = (e) => {
        let value = e.target.value;
        setUserAnswer(value);
    }

    /* Handle Enter key */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handleFocus = () => {
        saveAnalyticsEvent("field_selected", field_names.question);
    }

    const handleBlur = () => {
        if (userAnswer.trim().length > 0) {
            saveAnalyticsEvent("field_entered", field_names.question);
        }
    }

    return (
        <div>
            <div className="action-card">
                <div>
                    {
                        (getUserActionIconsRequired() == "true") ? <img className="user-action-icon" src={getQuestionIconPath()} /> : <></>
                    }
                    <div className="card-action-title" style={fontBold}>{getUserActionQuestionTitle()}</div>
                    <div className="card-action-msg" style={fontRegular}>
                        {bankName} {getUserActionQuestionDesc()} {userQuestion}
                    </div>
                    <br />
                    <div>
                        <input type="password" className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" style={inputStyle} placeholder={inputHint} value={userAnswer} name='setUserAnswer' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />
                    </div>
                    {
                        (isQuesError === true) ?
                            <span className="error-msg" style={errorMsgStyle}>{errMsg}</span> : null
                    }
                    <br /><br />
                    {
                        (getUserActionBtnStyle() == userActionBtnStyleText) ?
                            <div className="bottomTextDiv" style={bottomDivStyle}>
                                <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                                <div className="cancel-btn-div">
                                    <span className="cancel-btn-text" style={fontMedium} onClick={handleCancel}>{btnCancel}</span>
                                </div>
                            </div>
                            :
                            <div style={{ width: '100%' }}>
                                <button className="error-button" style={secondaryButtonTheme} onClick={handleCancel}>{btnCancel}</button>
                                <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                            </div>}
                </div>
            </div>
        </div>
    );
};

export default withRouter(QuestionComponent);