import React from 'react';
import JSZip from 'jszip';

// Basic MIME type lookup function
const getMimeType = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    const mimeTypes = {
        'pdf': 'application/pdf',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'png': 'image/png',
        'gif': 'image/gif',
        'bmp': 'image/bmp',
        'txt': 'text/plain',
        'html': 'text/html',
        'zip': 'application/zip',
        // add more types as needed
    };
    return mimeTypes[extension] || 'application/octet-stream';
};

export default class InputFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
        };
    }

    handleChange = async (e) => {
        let files = Array.from(e.target.files);
        let allFiles = [];

        this.props.onSelect();

        // Helper function to process a single file
        const processFile = (file) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    let fileInfo = {
                        name: file.name,
                        type: file.type || getMimeType(file.name),
                        size: file.size,
                        base64: reader.result,
                        file: file,
                    };
                    resolve(fileInfo);
                    reader = null;
                };
                reader.onerror = (error) => {
                    reject(error);
                    reader = null;
                };
            });
        };

        // Helper function to process a zip file recursively
        const processZip = async (file) => {
            try {
                const zip = new JSZip();
                const zipContent = await zip.loadAsync(file);
                const zipFiles = [];

                const processZipEntry = async (zipEntry, relativePath) => {
                    const fileName = zipEntry.name.split('/').pop(); // Extract the file name
                    if (!zipEntry.dir && !relativePath.startsWith('__MACOSX/') && !fileName.startsWith('.')) {
                        const blob = await zipEntry.async('blob');
                        const fileObj = new File([blob], fileName, { type: getMimeType(fileName) });
                        const fileInfo = await processFile(fileObj);
                        zipFiles.push(fileInfo);
                    }
                };

                await Promise.all(
                    Object.keys(zipContent.files).map(async (relativePath) => {
                        const zipEntry = zipContent.files[relativePath];
                        await processZipEntry(zipEntry, relativePath);
                    })
                );

                return zipFiles;
            } catch (error) {
                console.error('Error processing ZIP file:', error);
                return [];
            }
        };

        try {
            for (let file of files) {
                const isZip = (file.type === "application/zip") || (file.type === "application/x-zip-compressed");
                if (!isZip) {
                    const fileInfo = await processFile(file);
                    allFiles.push(fileInfo);
                } else {
                    const zipFiles = await processZip(file);
                    allFiles = allFiles.concat(zipFiles);
                }
            }

            this.props.onDone(allFiles);
        } catch (error) {
            console.error('Error processing files:', error);
            this.props.onDone([]);
        }
    };

    render() {
        return (
            <input
                type="file"
                id={this.props.id}
                accept={this.props.accept}
                onChange={this.handleChange}
                multiple={this.props.multiple}
            />
        );
    }
}

InputFile.defaultProps = {
    multiple: false,
};