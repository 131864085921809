import React, { useEffect, useState } from "react";
import { getLogoHeight, getLogoPath, getLogoRequired } from '../../Config';
import { statusCodeUserCancelled } from "../../utils/Strings";
import { useHistory } from "react-router-dom";
import { textColorTheme } from "../../utils/AppStyle";


const Header = ({ showCancel }) => {

  let history = useHistory();

  const [reloaded, setReloaded] = useState("false");

  useEffect(() => {
    setReloaded(sessionStorage.getItem('reloaded'));
  }, []);

  const handleCancel = () => {
    sessionStorage.setItem('status_code', statusCodeUserCancelled);
    sessionStorage.setItem('status', false);
    history.push("/txncomplete");
  }

  return (
    <div className="top-bar">

      {(getLogoRequired() == "true") && (reloaded == "true") ?
        <img
          src={getLogoPath()}
          width="auto"
          style={{ maxWidth: '200px' }}
          height={getLogoHeight()}
          className="header-logo"
          alt="logo"
        /> : null
      }

      {(showCancel == "true") ?
        <span className='cancel-text' style={textColorTheme} onClick={handleCancel}>Cancel</span>
        : null
      }
    </div>
  );
};

export default Header;