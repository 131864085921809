import React, { useEffect, useState } from "react";
import { getErrorIconPath, getSuccessIconPath } from '../../Config';
import { withRouter } from "react-router";
import { fontMedium } from "../../utils/AppStyle";

const PageNotFound = ({ setShowCancel }) => {

    const status = sessionStorage.getItem('status');
    const [cardTitle, setCardTitle] = useState((status == "true") ? "Your account successfully analyzed" : "The transaction is failed or cancelled due to some error. Please try relaunching the URL, if that doesn't work, please contact the team.");
    // const [cardDesc, setCardDesc] = useState("");
    // const [seconds, setSeconds] = useState(5);
    useEffect(() => {
        setShowCancel("false");
    }, []);

    // useEffect(() => {

    //     const interval = setInterval(() => {
    //         if (seconds === 0) {
    //             window.close();
    //             clearInterval(interval);
    //         }
    //         setSeconds(seconds => (seconds > 0) ? (seconds - 1) : 0);
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, [seconds]);

    return (
        <div>
            <div className="normal-card">
                <div style={{ marginTop: '30px' }}>
                    <img src={(status == "true") ? getSuccessIconPath() : getErrorIconPath()} className="respImgStyle" alt="error" />
                    <br /><br />
                    <div className="card-title" style={fontMedium}>{cardTitle}</div><br />
                    {/* <div className="card-msg" style={fontMedium}>
                        Window will be closed in {seconds} seconds
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default withRouter(PageNotFound);
