import React from "react";
import { getFooterLogo } from "../../Config";
import { fontRegular } from "../../utils/AppStyle";

const Footer = () => {

  return (

    <div className="footer-bar">
      <div>
        <span className="footer-powered-by" style={fontRegular}>Powered by </span>
        <img style={{ marginLeft: '5px', height: '15px', width: 'auto' }} src={getFooterLogo()} />
      </div>
    </div>

  );
};

export default Footer;