import React, { useState, useEffect } from "react";
import { Collapse } from "reactstrap";

function CollapsiblePanel({ children, ...props }) {
    const { show_title, hide_title, collapse } = props;
    const [isCollapse, setIsCollapse] = useState(collapse);
    const [icon, setIcon] = useState("fa fa-chevron-down");
    const [title, setTitle] = useState(hide_title);
    const toggle = () => {
        setIsCollapse(!isCollapse);
        setTitle(state => {
            return state === "View Files"
                ? "Hide Files"
                : "View Files";
        });
    };

    const animate = collapse => {
        setIsCollapse(collapse);
        setTitle(state => {
            return state === "View Files"
                ? "Hide Files"
                : "View Files";
        });
    };

    useEffect(() => {
        animate(!collapse);
    }, [collapse]);

    return (
        <div className="coll-panel">
            <a href="#" className="files-toggle"
                onClick={() => toggle()}
            >
                {title}
            </a>
            <Collapse className="border text-left" isOpen={isCollapse}>
                {children}
            </Collapse>
        </div>
    );
}

CollapsiblePanel.defaultProps = {
    children: "Add node as a child",
    show_tile: "Collapsible Panel",
    hide_title: "Expandable Panel",
    collapse: true
};

export default CollapsiblePanel;
