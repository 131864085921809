import React, { useState, useEffect } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { dispContent, hideContent, primaryButtonTheme, secondaryButtonTheme, fontMedium, fontRegular, fontBold, bottomDivStyle, errorMsgStyle, inputStyle } from '../../utils/AppStyle';
import { getPdfIconPath, getErrorIconPath, getUserActionBtnStyle, userActionBtnStyleText, getProcessingDesc, getProcessingTitle } from '../../Config';
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { PopUp } from "../../utils/PopUp";
import { userActionRequired, btnTryAnother, btnSubmit, inputHintPasswordUnlock, destinationPdfUpload, statusCodeInvalidPassword, btnYes, btnNo, pdfPasswordDesc } from "../../utils/Strings";
import { uploadUiStmt } from "../../utils/NetworkUtil";
import { Loader } from "../../utils/Loader";
import { getLoaderStyle, getAnalyticsEnabled, getAnalyticsCallbackRequired } from "../../Config";
import { field_names, saveEvent, saveFieldErrorEvent, saveProcessingEvent, screen_names, setScreenName } from "../../utils/Analytics";

const PdfPassword = () => {
    let history = useHistory();
    const [type, setType] = useState('password');
    const [eyeOnDisp, setEyeOnDisp] = useState(hideContent);
    const [eyeOffDisp, setEyeOffDisp] = useState(dispContent);
    const [eyeDivDisp, setEyeDivDisp] = useState(hideContent);
    const [password, setPassword] = useState('');

    const [showError, setShowError] = useState(false)

    const [errorTitle, setErrorTitle] = useState("Error")

    const [fileName, setFileName] = useState("Bankfile.pdf");

    const [isPasswordError, setPasswordError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("Enter Password")
    const [showProcess, setShowProcess] = useState(false);
    const [showPassword, setShowPassword] = useState(true);
    const [showPopup, setShowPopup] = useState(false);

    let txnId = sessionStorage.getItem('txn_id');
    let institutionId = sessionStorage.getItem('institution_id');
    let statementId = sessionStorage.getItem('statement_id');

    let confirmDesc = "Are you sure you want to upload another PDF, without entering the password for the current one? If yes, then current uploaded pdf will be discarded.";


    useEffect(() => {
        setFileName(sessionStorage.getItem("file_name"));

        saveAnalyticsEvent("shown", "");
    }, []);

    const saveAnalyticsEvent = (action, fieldType) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: screen_names.pdf_password
            }
            if (!isNullOrEmpty(fieldType)) {
                analyticsObj.field_type = fieldType
            }

            saveEvent(analyticsObj);
        }
    }

    const saveErrorEvent = (action, errorTitle, errorMsg) => {
        if ((getAnalyticsEnabled() == "1") || (getAnalyticsCallbackRequired() == "true")) {
            let analyticsObj = {
                action: action,
                screen_name: screen_names.pdf_upload_error,
                error_title: errorTitle,
                error_msg: errorMsg
            }

            saveEvent(analyticsObj);
        }
    }

    const handleClick = () => {
        setEyeOnDisp(eyeOnDisp === hideContent ? dispContent : hideContent);
        setEyeOffDisp(eyeOffDisp === dispContent ? hideContent : dispContent);
        setType(type === 'text' ? 'password' : 'text');
    }

    const handleChange = (e) => {
        let value = e.target.value;
        let nt = e.target.name;
        if (nt === 'setPassword') {
            setPassword(value.substring(0, 45));
        }
        if (value.length > 0) {
            setEyeDivDisp(dispContent);
        }
        else {
            setEyeDivDisp(hideContent);
        }
    }

    const handleFocus = (e) => {
        let nt = e.target.name;
        if (nt === 'setPassword') {
            saveAnalyticsEvent('field_selected', field_names.password);
        }
    }

    const handleBlur = (e) => {
        let nt = e.target.name;
        if (nt === 'setPassword') {
            if (password.trim().length > 0) {
                saveAnalyticsEvent('field_entered', field_names.password);
            }
        }
    }

    const sendPassword = () => {

        setShowPassword(false);
        setShowProcess(true);
        saveProcessingEvent(screen_names.pdf_upload_loading, getProcessingTitle(), getProcessingDesc());

        const data = {
            txn_id: txnId,
            file_password_b16: btoa(password),
            statement_id: statementId,
            execution_type: "password_submit",
            destination: destinationPdfUpload,
            institution_id: institutionId
        }

        const uploadUiStmtCb = (res) => {
            setShowProcess(false);
            if (res.data.status == "success") {
                sessionStorage.setItem("account_info", JSON.stringify(res.data));
                //sessionStorage.setItem("uploaded_file_list", JSON.stringify(res.data.filenames));
                history.push("/pdflist");
            }
            else {
                if (res.data.code == statusCodeInvalidPassword) {
                    setShowPassword(true);
                    setErrorMsg(res.data.msg);
                    setPasswordError(true);
                }
                else {
                    setShowError(true);
                    setErrorTitle(res.data.title);
                    setErrorMsg(res.data.msg);
                    saveErrorEvent("shown", res.data.title, res.data.msg);
                }
            }
        }

        uploadUiStmt(data, history, uploadUiStmtCb);
    }

    const handleSubmit = () => {
        saveAnalyticsEvent("submit_clicked", "");
        if ((!isNullOrEmpty(password))) {
            setScreenName(screen_names.pdf_password);
            sendPassword();
        }
        else {
            setPasswordError(true);
            setErrorMsg("Enter Password");
            saveFieldErrorEvent(screen_names.pdf_password, field_names.password, "Enter Password");
        }
    }

    const handleAnotherStatement = () => {
        saveAnalyticsEvent("try_another_clicked", "")
        //history.push("/pdfupload");
        setShowPopup(true);
    }

    const handleTryAgain = () => {
        saveAnalyticsEvent("try_another_clicked", "");
        setScreenName(screen_names.pdf_upload_error);
        history.push("/pdfupload");
    }

    /* Handle enter key */
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            handleSubmit()
        }
    }

    const handlePopupClose = () => {
        setShowPopup(false);
    }

    const handleYes = () => {
        saveAnalyticsEvent("yes_clicked", "");
        setScreenName(screen_names.pdf_password);
        handlePopupClose();
        history.push("/pdfupload");
    }

    const handleNo = () => {
        saveAnalyticsEvent("no_clicked", "");
        handlePopupClose();
    }

    return (
        <div>
            <div className="action-card">
                {
                    (showProcess == true) ?
                        <div style={{ textAlign: 'center' }}>
                            <Loader loaderStyle={getLoaderStyle()} />
                            <br /><br />
                            <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
                            <div className="card-msg" style={fontRegular}>
                                {getProcessingDesc()}
                            </div>
                        </div>
                        : null}

                {
                    (showError == true) ?
                        <div style={{ textAlign: 'center' }}>
                            <div style={{ marginTop: '30px' }}>
                                <img src={getErrorIconPath()} className="respImgStyle" />
                                <br /><br />
                                <div className="card-title" style={fontMedium}>{errorTitle}</div><br />
                                <div className="card-msg" style={fontRegular}>
                                    {errorMsg}
                                </div>
                            </div>
                            <div className='bottomDiv' style={{ marginTop: '50px' }}>
                                <button className="full-primary-button" style={primaryButtonTheme} onClick={handleTryAgain}>{btnTryAnother}</button>
                            </div>
                        </div> : null}

                {
                    (showPassword == true) ?
                        <div>
                            <div className="card-action-title" style={fontBold}>{userActionRequired}</div>
                            <div className="card-action-msg" style={fontRegular}>
                                {pdfPasswordDesc}
                            </div>
                            <div style={{ marginTop: '30px', width: "100%" }}>
                                <img src={getPdfIconPath()} style={{ verticalAlign: "middle", width: "20%" }} className="pdf-icon" />
                                <div style={{ display: "inline-block", verticalAlign: "middle", width: '75%' }}>

                                    <span className="file-name" style={fontRegular}>{fileName}</span>
                                </div>
                            </div>
                            <div style={{ paddingTop: '10px' }}>
                                <div style={{ position: 'relative' }}>
                                    <input type={type} className="action-input-style" autoComplete="off" autoCorrect="off" spellCheck="false" style={inputStyle} placeholder={inputHintPasswordUnlock} value={password} name='setPassword' onKeyDown={(e) => handleKeyDown(e)} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} required />
                                    <div className="float-right-icon" style={eyeDivDisp}>
                                        <FaEyeSlash style={eyeOffDisp} onClick={handleClick}></FaEyeSlash>
                                        <FaEye style={eyeOnDisp} onClick={handleClick}></FaEye>
                                    </div>
                                </div>
                                {
                                    (isPasswordError === true) ?
                                        <div style={{ paddingTop: '20px' }}>
                                            <span className="error-msg" style={errorMsgStyle}>{errorMsg}</span></div> : null
                                }
                            </div>
                            <br /><br />
                            {
                                (getUserActionBtnStyle() == userActionBtnStyleText) ?
                                    <div className="bottomTextDiv" style={bottomDivStyle}>
                                        <button className='full-primary-button' style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                                        <div className="cancel-btn-div">
                                            <span className="cancel-btn-text" style={fontMedium} onClick={handleAnotherStatement}>{btnTryAnother}</span>
                                        </div>
                                    </div>
                                    :
                                    <div style={{ width: '100%' }}>
                                        <button className="error-button" style={secondaryButtonTheme} onClick={handleAnotherStatement}>{btnTryAnother}</button>
                                        <button className="primary-button" style={primaryButtonTheme} onClick={handleSubmit}>{btnSubmit}</button>
                                    </div>
                            }

                        </div> : null}

                {
                    (showPopup == true) ?
                        <PopUp
                            close={handlePopupClose}
                            onPrimaryBtnClick={handleNo}
                            onSecondaryBtnClick={handleYes}
                            primaryBtnText={btnNo}
                            secondaryBtnText={btnYes}
                            bodyText={confirmDesc} /> : null}
            </div>
        </div>
    );
};

export default withRouter(PdfPassword);
