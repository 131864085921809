import React from 'react';
import Select from 'react-select';

const CustomDropdown = ({ options, title, onChange }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '40px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the dropdown menu appears above other elements
    }),
    option: (provided, { data }) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
    }),
  };

  const formatOptionLabel = ({ logo, label }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img src={logo} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
      {label}
    </div>
  );

  return (
    <Select
      options={options}
      styles={customStyles}
      formatOptionLabel={formatOptionLabel}
      placeholder={title}
      onChange={onChange}
    />
  );
};

export default CustomDropdown;
