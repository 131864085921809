import React from "react";

const Terms = () => {
    return (
        <div className="popup_content">
            <h6>Last updated: 20-Feb-2020</h6>
            <p>
                Please read these Terms and Conditions ("Terms", "Terms and Conditions" or “Agreement”) carefully before using the
            <a href="https://www.digitap.ai" target="_blank"> https://www.digitap.ai</a> or https://sdk.digitap.ai (which will be together referred to as the "Platform" henceforth) operated by Digitap.AI Enterprise Solutions Private Limited ("us", "we", “Digitap”, “Company” or "our").
        </p>
            <p>Digitap provides Software and services for paperless, hassle-free documentation. Software and services include but are not limited to, generation, facilitation, management and distribution of electronic signatures, storage, sharing, digitization, validation and verification of documents based on the user’s identity (the ”Services”).</p>
            <p>The Individual or Company that the Individual is representing, intending to use or using our Services, whether in individual capacity or representing a registered legal entity, is hereinafter referred to as “Customer”, “User”, “You”.</p>
            <p>The user using the Platform and the services made available on the Platform or on Applications, Tools, Plugins, Extensions, APIs published on the Platform and available for distribution on registered, recognized and official 3rd party platforms, is subject to these Terms.</p>
            <p>By accessing or using the Platform, You agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Platform. </p>
            <p>These Terms apply to all visitors, users and others who access or use the Platform.</p>
            <strong>Registration</strong>
            <p>As part of providing Services to enterprises, Digitap authenticates the user through OTP mechanism and also creates a user account. You will need to set a password for that account and then login. If you are registering through a third party platform, such as Facebook, then you will provide us permission to access certain information from your profile such as First name, Last name, email address etc. for the purpose of creating an account with Digitap.</p>
            <p>You will adhere to the following norms during registration:</p>
            <ol type="1">
                <li>lYou certify that all information provided for creating an account, now or in the future, is accurate</li>
                <li> You will not use user-names that are determined to be obscene, offensive, racist or vulgar</li>
                <li> You will not create accounts on behalf of others</li>
                <li> You will have only one active account with Digitap</li>
                <li>You will not register for an account if you are under 16 years of age</li>
                <li>You will be responsible for any activity that happens through your account and you solely are liable for losses to Digitap or any third party arising out of or in relation to your activity on the Platform</li>
                <li> You are responsible for maintaining the security of your password</li>
                <li>You will notify Digitap of any breach of security or unauthorized use of your account on the Platform</li>
                <li>Digitap is not liable for any unauthorized access of your account and you solely are liable for losses arising out of any such unauthorized access</li>
                <li>You will not perform any activity on the Platform, without explicit written consent from us, that will result in a commercial gain to yourself or any third party</li>
                <li>Digitap reserves the right to reclaim any user-name if we believe that some other business or individual is holding the legal right or trademark on that user-name. </li>
            </ol>
            <strong>License</strong>
            <p>Digitap hereby grants you a limited, non-exclusive, non-assignable and non-transferable license to access the Platform provided and expressly conditioned upon your agreement that all such access and use shall be governed by all terms and conditions set forth in this Agreement.</p>
            <strong>Content</strong>
            <p>
                Digitap doesn’t have any license for or doesn’t claim ownership rights pertaining to any documents, files submitted by you to its platform for using its Services (“Content”). These Terms do not grant us any licenses or rights to your Content except for the limited rights needed for us to provide the Services to you. Limited rights include but are not limited to copying, storing, extracting data, verifying, sharing Content for purposes of research and development, gain or otherwise, but in complete accordance with Digitap’s Privacy Policy and/or applicable laws of the land, and with your full consent.
        </p>
            <p>
                You will be able to delete the Content uploaded by you on the Platform. Once deleted, it will not be available on the Platform. It will be stored in back-up copies for reasonable amount of time before it is permanently purged.
        </p>
            <strong>Safety</strong>
            <ol type="1">
                <li>You will refrain from using the Platform for your own commercial gain or for the commercial gains of any other third party without written permission from us.</li>
                <li> You will not access the Platform using automated means (such as harvesting bots, robots etc.)</li>
                <li> You will not upload viruses or any other malicious code</li>
                <li>You will not login to an account belonging to someone else or solicit account related information of others</li>
                <li>You will not collect any information from users on the Platform</li>
                <li> You will not use the Platform for promoting anything that is considered to be unlawful, misleading, malicious or discriminatory</li>
                <li> You will not indulge in anything that will result in hampering of availability of any service rendered by the Platform to the users</li>
                <li> You will not encourage or facilitate any violations of this statement or our policies </li>
            </ol>
            <strong>Intellectual Property Rights</strong>
            <p>The User acknowledges and agrees that all the rights, title and interests in the Platform and/or the Services and all the Intellectual Property rights therein or related thereto are solely and absolutely owned by Digitap or its licensor and shall continue to vest with Digitap or its licensor during and after the term of this Agreement.</p>
            <p>Nothing in this Agreement provides any right, title and interests of license, assignment or ownership in the Platform and/or the Services or the Intellectual Property rights therein or associated therewith, and the Company reserves all rights in relation thereto not expressly granted to the User under these Terms.</p>
            <p>The User hereby undertakes that the User shall not apply, either by himself/ herself/itself or through any third party, for any copyright, trademark or any Intellectual Property for any aspect of the Intellectual Property rights relating to the Platform and/or the Services, whether or not such Intellectual Property has been provided under this Agreement or developed by the Company for the purposes of this Agreement.</p>
            <p>The User agrees not to (i) copy, modify, create any derivative work of, or include in any other products or any portion thereof, or (ii) either directly or indirectly decompile, disassemble, decipher, reverse engineer, re-engineer or otherwise attempt to derive Source Code or the underlying ideas, algorithms, structure or organization from the Company and/or the Platform or directly or indirectly permit any employee/personnel of the User to decompile, disassemble, decipher, reverse engineer, re-engineer or otherwise attempt to derive Source Code or the underlying ideas, algorithms, structure or organization from the Company and/or the Platform.</p>
            <p>The Company may procure third party components such as software, solutions, etc., (“Third Party Software”), to develop the Platform and/or the Services. In this regard, in the event the Company procures any Third Party Software in relation to the development of the Platform, all title, ownership and rights to such Third Party Software shall continue to vest either with such third party or with the Company. Consequently, the User may only have a restricted right to use the Third Party Software in relation to the Platform and/or the Services as contemplated under these Terms and the User shall have no further right, title or interest in such Third Party Software other than as specifically contemplated under these Terms.</p>
            <p>The User acknowledges that during the term of this Agreement and use of the Platform and Services, the Third Party Software shall continue to be owned solely and exclusively by such relevant third parties or the Company, as specified above in this Agreement.</p>
            <p>The User agrees not to represent the Company, the Platfomr and/or the Services to be his/her/its own product or services or brand or Intellectual Property and technology.</p>
            <p>In addition to the foregoing, the User shall always identify and respect the intellectual property rights of third parties over any subject matter and shall not use them in any manner during the Transactions, unless specifically authorized by the holder/s of such intellectual property rights.</p>
            <strong>Availability of service</strong>
            <p>You will be responsible for providing and maintaining the equipment needed to connect to the Platform and cost pertaining to that will be borne entirely by you.</p>
            <p>Digitap will attempt to provide services on the Platform without interruption. However, Digitap shall not be responsible or liable for loss of or interruption in availability of services due to reasons such as electronic or mechanical equipment failures, telephone interconnect problems, defects, weather, strikes, walkouts, fire, acts of God, riots, armed conflicts, acts of war, or other like causes.</p>
            <strong>Liability clauses</strong>
            <p>Digitap will not be liable for any loss or damage to any user or any third party under the following circumstances</p>
            <ol type="1">
                <li>In the event of temporary unavailability of services on the Platform as a result of regular maintenance or changes made to the features provided on the Platform or addressing defects in the Platform</li>
                <li>When Digitap exercises its right to modify or discontinue any or all of the Content, information, software, products, features and services published on the Platform</li>
            </ol>
            <p>In no event shall Digitap be held liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the use of this Platform or with the delay or inability to use this Platform, or for any content, information, software, products, features and services obtained through this Platform, or otherwise arising out of the use of this Platform, whether based on contract, tort, strict liability or otherwise, even if Digitap or any of its Associated Entities has been advised of the possibility of damages.</p>
            <strong>Information</strong>
            <p>Digitap takes certain personal information belonging to the user at the time of registration (or account creation) and also in the due course of the user’s engagement with the Platform. It is our responsibility to keep this information confidential. We will ensure that whatever information is provided to us, it will not be shared with other users on the Platform. Furthermore, Digitap’s Privacy Policy dictates the terms and conditions pertaining to the collection, use and sharing of personal information on the Platform.</p>
            <p>Digitap will take all measures to ensure that data or information provided to us by the user is available at all times. However, in the event of loss of data, Digitap will not be liable for any losses or damages to you or any other third party arising out of such an event.</p>
            <p>You assume all risk of errors and/or omissions in the Platform, including the transmission or translation of information. You assume full responsibility for implementing sufficient procedures and checks to satisfy your requirements for the accuracy and suitability of the Platform, including the information, and for maintaining any means which you may require for the reconstruction of lost data or subsequent manipulations or analyses of the information under the Terms. </p>
            <strong>Third party sites</strong>
            <p>The Platform may contain links to third-party web sites or services that are not owned or controlled by Digitap. Digitap has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that Digitap shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.</p>
            <strong>Conflicting terms</strong>
            <p>If there are any terms from some other documents (whether such documents are prior to or subsequent to this agreement) that are in conflict with the Terms presented in this agreement, then the Terms in this agreement take precedence.</p>
            <strong>Termination</strong>
            <p>If you violate the letter or spirit of the Terms in this agreement, or otherwise create risk or possible legal exposure for us, we can stop providing all or part of services on the Platform to you. We will notify you by email or at the next time you attempt to access your account. You may also delete your account or disable your application at any time. </p>
            <p>In all such cases, these Terms shall terminate. </p>
            <strong>Agreement</strong>
            <p>This Agreement constitutes the entire agreement between the parties, and no other agreement, written or oral, exists between you and Digitap. By using the information on the Platform, you assume full responsibility for any and all gains and losses, financial, emotional or otherwise, experienced, suffered or incurred by you. Digitap does not guarantee the accuracy, completeness or timeliness of, or otherwise endorse in any way, the views, opinions or recommendations expressed in the information, does not give investment advice, and does not advocate the purchase or sale of any security or investment by you or any other individual. The information is not intended to provide tax, legal or investment advice, which you should obtain from your professional advisor prior to making any investment of the type discussed in the information. The information does not constitute a solicitation by the information providers, Digitap, or other of the purchase or sale of securities. </p>
            <strong>Jurisdiction</strong>
            <p>The Terms of this agreement are based on Indian law. Any dispute arising out of or relating to the use of the Platform and the Terms within agreement will be settled in the courts of Bengaluru, India. Use of the Platform is not authorized in any jurisdiction that does not give effect to all the Terms mentioned in this agreement.</p>
            <strong>Indemnification</strong>
            <p>You will indemnify Digitap from any and all claims and losses imposed upon as a result of or related to</p>
            <ol type="1">
                <li>Your access and use of the Platform</li>
                <li>Any non-compliance by user with the Terms hereof</li>
                <li>Any third party actions related to user's receipt and use of the information, whether authorized or unauthorized</li>
            </ol>
            <strong>Changes to these terms</strong>
            <p>These terms shall only be modified in writing signed by Digitap. </p>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion</p>
            {/* 
                    <iframe className="frame_style" src="https://s3.ap-south-1.amazonaws.com/www.digitap.ai/termsConditions.html" frameBorder="no"></iframe>
                     */}
        </div>
    );
};

export default Terms;