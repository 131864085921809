//import ReactGA from "react-ga";  //Google Analytics 
import { env, ENV_STAGE, ENV_PROD, ENV_UAT } from '../Config';

let trackingID;  // tracking id will get from project in analytics website

if (env === ENV_STAGE) {
    trackingID = process.env.REACT_APP_STAGE_TRACKING_ID;
}
else if (env === ENV_PROD) {
    trackingID = process.env.REACT_APP_PROD_TRACKING_ID;
}
else if (env === ENV_UAT) {
    trackingID = process.env.REACT_APP_UAT_TRACKING_ID;
}
else {
    trackingID = process.env.REACT_APP_DEV_TRACKING_ID;
}

//Google Analytics initialization with tracking Id
export const GAInit = () => {
    //ReactGA.initialize(trackingID);
}

//Google Analytics tracking the current screen
export const GAPageView = (name) => {
    //ReactGA.set({ page: name }); // Update the user's current page
    //ReactGA.pageview(name); // Record a pageview for the given page
}

//Google Analytics tracking the event
export const GAEvent = (category, action, label) => {
    // ReactGA.event({
    //     category: category,
    //     action: action,
    //     label: label
    // });
};
