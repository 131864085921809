import React from "react";
//import { components } from "react-select";
import { getBankLogo, getCheckboxCheckedPath, getCheckboxUnCheckedPath } from "../Config";
import { fontMedium } from "./AppStyle";


export const Option = (props) => {
  const { innerProps, isSelected, data } = props;
  const optionStyles = {
    backgroundColor: isSelected ? '#004C8F1A' : 'white',
    color: isSelected ? '#212121' : '#212121',
    // Add any additional styles here
  };
  return (
    <div {...innerProps} className="custom-option" style={optionStyles}>
      {/* <components.Option {...props}> */}
      <img className="bank-logo" src={getBankLogo(data.bankCode)} />
      <span className="select-checkbox-label" style={fontMedium}>{data.label}</span>
      <img style={{ float: 'right' }} src={(isSelected == true) ? getCheckboxCheckedPath() : getCheckboxUnCheckedPath()} className="select-checkbox-style" />
      <input
        type="checkbox"
        style={{ float: 'right', visibility: 'hidden' }}
        checked={isSelected}
        onChange={() => null}
      />{" "}
      {/* </components.Option> */}
    </div>
  );
};