import React, { useEffect, useState } from "react";
//import { loaderTheme, fontMedium } from '../utils/AppStyle';
import { isNullOrEmpty } from "../utils/AppUtil";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { fontMedium } from '../utils/AppStyle';
import { getErrorIconPath } from '../Config';
import { controllerUrl } from "../Config";
import { Loader } from "../utils/Loader";
import { getLoaderStyle } from "../Config";

const RedirectHome = () => {
    let history = useHistory();

    const [showError, setShowError] = useState(false);

    const [errorTitle, setErrorTitle] = useState("Error");
    const [errorMsg, setErrorMsg] = useState("Something Went Wrong")

    useEffect(() => {

        /* Taking the parameters from the url */
        let url_string = window.location.href;
        console.log("url_string:" + url_string);

        let url_params = url_string.split("?")[1];

        if (!isNullOrEmpty(url_params)) {
            redirectToMainUrl(url_params);
        }
        else {
            showErrorUI();
        }

    }, []);


    /* Redirecting to pdfupload page or netbanking page based on the destination parameter in the url */

    const showErrorUI = () => {
        setShowError(true);
    }

    const redirectToMainUrl = (url_params) => {
        let redirectUrl = controllerUrl + "/transact?" + url_params;
        console.log("redirectUrl:" + redirectUrl);
        window.location.href = redirectUrl;
        //window.open(redirectUrl, "_blank");
        //window.open(redirectUrl);
    }

    return (
        <div>
            <div className="normal-card">
                <Loader loaderStyle={getLoaderStyle()} />
            </div>
            {
                (showError == true) ?
                    <div className="normal-card">
                        <img src={getErrorIconPath()} className="respImgStyle" alt="error" />
                        <br /><br />
                        <div className="card-title" style={fontMedium}>{errorTitle}</div><br />
                        <div className="card-msg" style={fontMedium}>
                            {errorMsg}
                        </div>
                    </div> : null
            }

        </div>
    );
};

export default withRouter(RedirectHome);
