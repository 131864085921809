import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "./components/include/Header";
import NetbankingHome from "./components/netbanking/NetbankingHome";
import EmailStatementHome from "./components/emailstatement/EmailStatementHome";
import Home from "./components/Home";
import StmtUploadHome from "./components/stmtupload/StmtUploadHome";
import PdfUpload from "./components/stmtupload/PdfUpload";
import OtpComponent from './components/netbanking/UserActionOtp';
import PasswordComponent from './components/emailstatement/UserActionPassword';
import CaptchaComponent from './components/netbanking/UserActionCaptcha';
import ErrorComponent from './components/common/UserActionError';
import InfoComponent from './components/common/UserActionInfo';
import SuccessComponent from './components/common/UserActionSuccess';
import QuestionComponent from './components/netbanking/UserActionQuestion';
import ProcessComponent from './components/common/UserActionProcess';
import { appBody, centerContent } from './utils/AppStyle';
import PageNotFound from "./components/common/WrongUrl";
import { Route, Switch, withRouter } from "react-router";
import TxnComplete from "./components/common/TxnComplete";
import PdfList from "./components/stmtupload/PdfList";
import PdfPassword from "./components/stmtupload/PdfPassword";
import { GAInit, GAPageView } from "./utils/ganalytcs";
import MaintenanceComponent from "./components/common/Maintenance";
import UserActionAccount from "./components/common/UserActionAccount";
import UserActionToken from "./components/emailstatement/UserActionToken";
import AACallback from "./components/accountaggregator/AACallback";
import AAHome from "./components/accountaggregator/AAHome";
import ChoiceHome from "./components/common/ChoiceHome";
import AAMobile from "./components/accountaggregator/AAMobile";
import AAProcessing from "./components/accountaggregator/AAProcessing";
import RedirectHome from "./components/RedirectHome";
import Footer from "./components/include/Footer";
import RedirectToClient from "./components/common/RedirectToClient";
import { getHeaderRequired, getPoweredByRequired } from "./Config";

const App = () => {

  let history = useHistory();

  let [currentPathname, setCurrentPathName] = useState(null);

  const [showCancel, setShowCancel] = useState("false");

  useEffect(() => {

    GAInit();

    handleHistory();

  }, []);

  const handleHistory = () => {

    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (newLocation.pathname !== currentPathname) {
          currentPathname = newLocation.pathname;
          setCurrentPathName(newLocation.pathname);
          history.push(newLocation.pathname);
        }
        GAPageView(newLocation.pathname);
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }

  return (
    <div style={appBody}>
      {
        (getHeaderRequired() == "true") ?
          <Header showCancel={showCancel} />
          :
          <></>
      }
      <div style={centerContent}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />

          <Route exact path="/bank-data" component={RedirectHome} />

          <Route path="/choicehome">
            <ChoiceHome setShowCancel={setShowCancel} />
          </Route>
          <Route path="/netbankinghome">
            <NetbankingHome setShowCancel={setShowCancel} />
          </Route>
          <Route path="/aahome">
            <AAHome setShowCancel={setShowCancel} />
          </Route>
          <Route path="/stmtuploadhome">
            <StmtUploadHome setShowCancel={setShowCancel} />
          </Route>
          <Route path="/emailstmthome">
            <EmailStatementHome setShowCancel={setShowCancel} />
          </Route>
          <Route path="/aaprocessing">
            <AAProcessing setShowCancel={setShowCancel} />
          </Route>
          <Route path="/aamobile">
            <AAMobile setShowCancel={setShowCancel} />
          </Route>
          <Route path="/pdfupload">
            <PdfUpload setShowCancel={setShowCancel} />
          </Route>
          <Route path="/pdfpassword">
            <PdfPassword setShowCancel={setShowCancel} />
          </Route>
          <Route path="/pdflist">
            <PdfList setShowCancel={setShowCancel} />
          </Route>
          <Route path="/otp">
            <OtpComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/accesstoken">
            <UserActionToken setShowCancel={setShowCancel} />
          </Route>
          <Route path="/aacallback">
            <AACallback setShowCancel={setShowCancel} />
          </Route>
          <Route path="/captcha">
            <CaptchaComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/error">
            <ErrorComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/info">
            <InfoComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/question">
            <QuestionComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/account">
            <UserActionAccount setShowCancel={setShowCancel} />
          </Route>
          <Route path="/password">
            <PasswordComponent setShowCancel={setShowCancel} />
          </Route>

          <Route path="/success">
            <SuccessComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/process">
            <ProcessComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/wrongurl">
            <PageNotFound setShowCancel={setShowCancel} />
          </Route>
          <Route path="/maintenance">
            <MaintenanceComponent setShowCancel={setShowCancel} />
          </Route>
          <Route path="/txncomplete">
            <TxnComplete setShowCancel={setShowCancel} />
          </Route>
          <Route path="/rdc">
            <RedirectToClient setShowCancel={setShowCancel} />
          </Route>

          {/* 

        <Route path="/stmtuploadhome" component={StmtUploadHome} />
        <Route path="/pdfupload" component={PdfUpload} />
        <Route path="/pdfpassword" component={PdfPassword} />
        <Route path="/pdflist" component={PdfList} />
        <Route path="/otp" component={OtpComponent} />
        <Route path="/captcha" component={CaptchaComponent} />
        <Route path="/error" component={ErrorComponent} />
        <Route path="/question" component={QuestionComponent} />
        <Route path="/success" component={SuccessComponent} />
        <Route path="/process" component={ProcessComponent} />
        <Route path="/wrongurl" component={PageNotFound} />
        <Route path="/txncomplete" component={TxnComplete} /> */}
        </Switch>
      </div>
      {(getPoweredByRequired() == "true") ?
        <Footer /> : <></>
      }
    </div>
  );

};
export default withRouter(App);
