import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { statusCodeReportGenerated } from "../../utils/Strings";
import { fontMedium } from "../../utils/AppStyle";
import { isNullOrEmpty } from "../../utils/AppUtil";
import { checkFipStatus } from "../../utils/NetworkUtil";
import { getAARedirection, getProcessingDesc, getProcessingTitle } from "../../Config";
import { databaseRef } from '../../utils/firebase';
import { Loader } from "../../utils/Loader";
import { getLoaderStyle } from "../../Config";

const AACallbackComponent = ({ setShowCancel }) => {

  let history = useHistory();

  useEffect(() => {
    setShowCancel("false");
    let url_string = window.location.href;
    url_string = url_string.replace('#', '?');
    let url = new URL(url_string);
    let params = url.searchParams;
    if (!isNullOrEmpty(params)) {
      sendDataToBackend(params);
    }
    else {
      handleNextScreen();
    }
  }, []);

  const checkStatusCodeAndRedirect = () => {
    let txnId = sessionStorage.getItem("txn_id");
    const txnRef = databaseRef.child(txnId);
    const txnDataRef = txnRef.child("txn_data");

    txnDataRef.on("value", function (snapshot) {
      let datas = JSON.stringify(snapshot);
      var txnData = JSON.parse(datas);

      if (txnData.txn_status.code === statusCodeReportGenerated) { // updating isprocessing value based on the status code
        //setIsProcessing("0")
        sessionStorage.setItem('status', true);
        sessionStorage.setItem('is_processing', '0');
      }
      else {
        sessionStorage.setItem('status', 'in-progress');
        sessionStorage.setItem('is_processing', '1');
      }
      sessionStorage.setItem('token_valid', false);
      history.push("/txncomplete");
      //redirectToClient();
    });
  }

  const sendDataToBackend = (params) => {
    let ecres = params.get("ecres", "");
    let resdate = params.get("resdate", "");
    let fi = params.get("fi", "");
    const aaCb = (res) => {
      handleNextScreen();
    }

    checkFipStatus(ecres, resdate, fi, history, aaCb);
  }

  /* Redirecting to next screen based on return url and shw_rpt variable */
  const handleNextScreen = () => {
    if ((getAARedirection() == "true") || ((!isNullOrEmpty(sessionStorage.getItem('user_redirection'))) && (sessionStorage.getItem('user_redirection') == '1'))) { // Added themeId check
      checkStatusCodeAndRedirect()   // Checking ReportGenerated Status Code and redirecting immediately to return url
    }
    else {
      history.push("/process");
    }
  }

  return (
    <div>
      <div className="normal-card">
        <Loader loaderStyle={getLoaderStyle()} />
        <br /><br />
        <div className="card-title" style={fontMedium}>{getProcessingTitle()}</div><br />
        <div className="card-msg" style={fontMedium}>
          {getProcessingDesc()}
        </div>
      </div>
    </div>
  );
};

export default withRouter(AACallbackComponent);