import PropTypes from "prop-types";
import React, { Component } from "react";
import "../popup.css";
import { primaryButtonTheme, secondaryButtonTheme, fontMedium, fontRegular } from "./AppStyle";
export class PopUp extends Component {
  state = {};

  render() {
    const { primaryBtnText, secondaryBtnText, titleText, bodyText, onPrimaryBtnClick, onSecondaryBtnClick, close } = this.props;
    return (
      <div className="popup_modal">
        <div className="popup_modal_content">
          {
            (close != undefined) ? <span className="close" onClick={close}>&times;    </span> : null
          }
          {
            (titleText != undefined) ?
              <p style={fontMedium} className="popupTitleText">{titleText}</p> : <br/>
          }

          <p style={fontRegular} className="popupBodyText">{bodyText}</p>

          <div style={{ width: '100%', textAlign: "right", position: "relative", bottom: "0px" }}>
            {
              ((secondaryBtnText != undefined) && (secondaryBtnText != "")) ?
                <button className='popup-error-button' style={secondaryButtonTheme} onClick={onSecondaryBtnClick}>{secondaryBtnText}</button> : null
            }
            <button className='popup-primary-button' style={primaryButtonTheme} onClick={onPrimaryBtnClick}>{primaryBtnText}</button>
          </div>

        </div>
      </div>
    );
  }
}

PopUp.propTypes = {
  primaryBtnText: PropTypes.node.isRequired,
  secondaryBtnText: PropTypes.node,
  titleText: PropTypes.node,
  bodyText: PropTypes.node.isRequired,
  onPrimaryBtnClick: PropTypes.func.isRequired,
  onSecondaryBtnClick: PropTypes.func,
  close: PropTypes.func
};
