import firebase from 'firebase';
import { env, ENV_STAGE, ENV_PROD, ENV_UAT, ENV_PROD_BLUE } from '../Config';

let apiKeyValue;
let authDomainValue;
let databaseURLValue;
let projectIdValue;
let storageBucketValue;
let appIdValue;

if (env === ENV_STAGE) {
    apiKeyValue = process.env.REACT_APP_STAGE_API_KEY;
    authDomainValue = process.env.REACT_APP_STAGE_AUTH_DOMAIN;
    databaseURLValue = process.env.REACT_APP_STAGE_DATABASE_URL;
    projectIdValue = process.env.REACT_APP_STAGE_PROJECT_ID;
    storageBucketValue = process.env.REACT_APP_STAGE_STORAGE_BUCKET;
    appIdValue = process.env.REACT_APP_STAGE_APP_ID;
}
else if ((env === ENV_PROD) || (env === ENV_PROD_BLUE)) {
    apiKeyValue = process.env.REACT_APP_PROD_API_KEY;
    authDomainValue = process.env.REACT_APP_PROD_AUTH_DOMAIN;
    databaseURLValue = process.env.REACT_APP_PROD_DATABASE_URL;
    projectIdValue = process.env.REACT_APP_PROD_PROJECT_ID;
    storageBucketValue = process.env.REACT_APP_PROD_STORAGE_BUCKET;
    appIdValue = process.env.REACT_APP_PROD_APP_ID;
}
else if (env === ENV_UAT) {
    apiKeyValue = process.env.REACT_APP_UAT_API_KEY;
    authDomainValue = process.env.REACT_APP_UAT_AUTH_DOMAIN;
    databaseURLValue = process.env.REACT_APP_UAT_DATABASE_URL;
    projectIdValue = process.env.REACT_APP_UAT_PROJECT_ID;
    storageBucketValue = process.env.REACT_APP_UAT_STORAGE_BUCKET;
    appIdValue = process.env.REACT_APP_UAT_APP_ID;
}
else {
    apiKeyValue = process.env.REACT_APP_DEV_API_KEY;
    authDomainValue = process.env.REACT_APP_DEV_AUTH_DOMAIN;
    databaseURLValue = process.env.REACT_APP_DEV_DATABASE_URL;
    projectIdValue = process.env.REACT_APP_DEV_PROJECT_ID;
    storageBucketValue = process.env.REACT_APP_DEV_STORAGE_BUCKET;
    appIdValue = process.env.REACT_APP_DEV_APP_ID;
}

const config = {
    apiKey: apiKeyValue,
    authDomain: authDomainValue,
    databaseURL: databaseURLValue,
    projectId: projectIdValue,
    storageBucket: storageBucketValue,
    appId: appIdValue
}

firebase.initializeApp(config);

firebase.auth().signInAnonymously();

//firebase.analytics();  //firebase analytics
export const databaseRef = firebase.database().ref();

// Get a reference to the storage service, export it for use
export const storageRef = firebase.storage();

// Get a reference to the analytics service, export it for use
//export const analyticsRef = firebase.analytics();

// Get a reference to the remote config service, export it for use
// export const remoteConfigRef = firebase.remoteConfig();

// export const getFirebaseConfigValue = async (param, cb) => {

//     remoteConfigRef.fetchAndActivate()
//   .then(() => {
//     console.log('Fetched remote config');
//     let greeting = remoteConfigRef.getValue('testing_key').asString();
//     console.log("testing_key value:" + greeting);
//   })
//   .catch((err) => {
//     console.log("Failed to fetch remote config", err);
//   });
// };